import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaTools } from 'react-icons/fa';
import { ROUTES } from '../../routes/routes';

const WorkInProgress = () => {
  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center vh-100 bg-light"
    >
      <Row className="text-center">
        <Col>
          <Card className="shadow-lg" style={{ borderRadius: '1rem' }}>
            <Card.Body className="p-5">
              <FaTools size={70} className="text-warning mb-4" />
              <Card.Title className="display-4 mb-3">
                Work in Progress
              </Card.Title>
              <Card.Text className="lead mb-4">
                We are working hard to bring you new features and updates. Stay
                tuned!
              </Card.Text>
              <Button variant="primary" size="lg" href={ROUTES.PROP_OFFERING}>
                Go to Home
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WorkInProgress;
