import { Card, Spinner } from 'react-bootstrap';
import useGetUserWalletTransactionHistory from '../../hooks/useGetUserWalletTransactionHistory';
import { NoTransactions } from '../../assets/image';
import './TransactionHistory.scss';

export const TransactionHistoryComponent = () => {
  const { transactionHistory, isLoading } =
    useGetUserWalletTransactionHistory();

  const transactionTypes = {
    "Account Transfer": "Investment",
    "Interac Money Request": "Interac Transfer"
  }
  return (
    <Card className="p-5">
      <p className="font-bold text-dark">Transaction History</p>
      {transactionHistory && Object.entries(transactionHistory).length > 0 ? (
        <>
          <p className="font-bold text-sm text-gray-500">
            Total Transactions ({Object.entries(transactionHistory).length})
          </p>
          <table className="transactions-table table mt-12">
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {transactionHistory &&
                Object.entries(transactionHistory).map(([key, value]) => (
                  <tr key={key}>
                    <td className='font-bold font-italic'>
                      {' '}
                      {new Date(value.TransactionDateTime).toDateString()}
                    </td>
                    <td>{transactionTypes[value.TransactionType]}</td>
                    <td className={`${parseFloat(value.DebitAmount) > 0? "text-danger" : "text-success"} font-bold`}>
                      {parseFloat(value.DebitAmount) > 0
                        ? `- ${value.Currency} $${value.DebitAmount}`
                        : `+ ${value.Currency} $${value.CreditAmount}`}
                    </td>
                    <td> {value.TransactionStatus}</td>
                    {/*<strong>{key}:</strong> {JSON.stringify(value)}*/}
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      ) : isLoading ? (
        <div className="w-full d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        <div className="text-center py-20">
          <p className="font-bold text-sm text-gray-500">0 Transactions</p>
          <p className="font-bold text-lg text-dark">No transactions Yet?</p>
          <img src={NoTransactions} alt="img" className="w-13 pt-10 pb-5" />
          <p className="text-sm text-gray-500">
            Any future wallet transactions will be reflected in this section.
          </p>
        </div>
      )}
    </Card>
  );
};
