import { Row, Col, Card, CardBody, Image } from 'react-bootstrap';
import {
  BathroomIcon,
  BedroomIcon,
  ParkingArea,
  SqftIcon,
} from '../../assets/image';
import { formatNumber } from '../util';

export function PropertyDescription(props) {
  const { property } = props;

  console.log(property);
  return (
    <Card className="overflow-hidden">
      <CardBody className="p-0">
        <Row className="gx-0 gy-0">
          <Col md={3} xs={6}>
            <Card className="rounded-0 h-full">
              <CardBody className="p-4">
                <div className="hstack gap-4">
                  <Image src={SqftIcon} />
                  <div>
                    <p className="text-xs mb-1">Sqft</p>
                    <h6 className="text-base">
                      {formatNumber(property?.companyId?.totalAreaInSQFT)}
                    </h6>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={3} xs={6}>
            <Card className="rounded-0 h-full">
              <CardBody className="p-4">
                <div className="hstack gap-4">
                  <Image src={BedroomIcon} />
                  <div>
                    <p className="text-xs mb-1">Bedroom</p>
                    <h6 className="text-base">
                      {property?.companyId?.noOfBedroom}
                    </h6>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={3} xs={6}>
            <Card className="rounded-0 h-full">
              <CardBody className="p-4">
                <div className="hstack gap-4">
                  <Image src={BathroomIcon} />
                  <div>
                    <p className="text-xs mb-1">Bathroom</p>
                    <h6 className="text-base">
                      {property?.companyId?.noOfBathroom}
                    </h6>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={3} xs={6}>
            <Card className="rounded-0 h-full">
              <CardBody className="p-4">
                <div className="hstack gap-4">
                  <Image src={ParkingArea} />
                  <div>
                    <p className="text-xs mb-1">Parking Area</p>
                    <h6 className="text-base">
                      {property?.companyId?.noOfCarParking} Cars
                    </h6>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardBody>
      <CardBody>
        <p
          className="text-sm text-dark"
          dangerouslySetInnerHTML={{
            __html: property?.companyId?.description as string,
          }}
        ></p>
      </CardBody>
    </Card>
  );
}
