import { axios } from '../../axios/AxiosConfig';
import { encryptData } from '../../utilities/encryptDecryptData';
import { getLanguageCode } from '../../utilities/localStorageFun';
import { RESET_PASSWORD } from '../apiEndPoints';
import { handleError } from '../../utilities/handleError';
import { REACT_APP_DATABASE_ENCRYPTION } from '../../utilities/envConstants';

export const resetPasswordService = async ({
  password,
  passwordConfirm,
  token,
}: {
  password: string;
  passwordConfirm: string;
  token: string;
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const isEncrypted = REACT_APP_DATABASE_ENCRYPTION;

      const response = await axios.patch(
        `${RESET_PASSWORD}${token}?lng=${getLanguageCode()}`,
        {
          createdAt: Date.now(),
          passwordConfirm: isEncrypted
            ? encryptData(passwordConfirm)
            : passwordConfirm,
          password: isEncrypted ? encryptData(password) : password,
        },
      );
      resolve({ status: response.data.status });
    } catch (error: any) {
      handleError(error);
      reject(error);
    }
  });
};
