import { GET_SUITABILITY_QUESTIONNAIRE_API } from '../apiEndPoints'; // Assuming you have an API endpoint constant defined
import { axios } from '../../axios/AxiosConfig';
import { toast } from 'react-toastify';

export const getSuitabilityQuestionnaireService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_SUITABILITY_QUESTIONNAIRE_API);
      resolve(response.data?.data?.data); // Assuming the response directly contains the questionnaire data
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error);
      reject(error);
    }
  });
};
