import { toast } from 'react-toastify';
import { axios } from '../../axios/AxiosConfig';
import { EQUITY_API } from '../apiEndPoints';
import { Doc } from './types';

export const getPropertyById = async (id: string): Promise<Doc> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${EQUITY_API}${id}`);
      resolve(response.data?.data?.data?.campaignDetail);
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error);
      reject(error);
    }
  });
};
