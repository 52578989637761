import {
  Row,
  Col,
  Card,
  CardBody,
  FloatingLabel,
  Form,
  Badge,
  InputGroup,
} from 'react-bootstrap';
import {
  calculateFinalAmountAfterYear,
  GrowthChart,
} from './components/GrowthChart';
import { formatAmount, formatIRR, formatNumber } from '../util';

function ChartLegend() {
  return (
    <div className="hstack gap-2 flex-wrap justify-content-md-end justify-content-center">
      <p className="text-xs ps-2 border-start border-2 border-primary">
        Initial Amount
      </p>
      <p className="text-xs ps-2 border-start border-2 border-gray-200">
        Return Earned
      </p>
    </div>
  );
}

export function InvestmentCalculator(props) {
  const {
    property,
    initialInvestmentAmount,
    yearsToHold,
    setInitialInvestmentAmount,
    setYearsToHold,
  } = props;

  return (
    <Card>
      <CardBody>
        <Row className="gy-6">
          <Col xs={12} md={4}>
            <h6 className="text-base mb-14 d-none d-md-block">
              Return & Growth
            </h6>
            <div className="floating-input-box">
              <FloatingLabel
                controlId="floatingInput"
                label="Initial Amount (CAD)"
                className="mb-3 floating-input"
              >
                <Form.Control
                  placeholder="Enter Amount"
                  defaultValue={`$${initialInvestmentAmount}`}
                  className="text-base font-bold"
                  onChange={(event) => {
                    const input = event.target.value;
                    if (/^\$[0-9]+/.test(input)) {
                      const val = parseInt(input.substring(1));
                      setInitialInvestmentAmount(val);
                    }
                  }}
                  value={`$${initialInvestmentAmount}`}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Internal Rate of Return"
                className="mb-3 floating-input"
              >
                <Form.Control
                  type="text"
                  value={formatIRR(property?.annualRateOfReturn)}
                  className="text-base font-bold"
                  disabled
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="years-hold"
                className="floating-input"
                label="Years to Hold"
              >
                <Form.Control
                  type="float"
                  placeholder="Years to Hold"
                  defaultValue={yearsToHold}
                  className="text-base font-bold"
                  onChange={(event) => {
                    setYearsToHold(
                      event.target.value ? parseFloat(event.target.value) : 1,
                    );
                  }}
                />
              </FloatingLabel>
            </div>
          </Col>
          <Col md={8}>
            <Row className="mb-md-14 mb-5">
              <Col md={4}>
                <h6 className="text-base">Return Growth Over time</h6>
              </Col>
              <Col md={8} className="d-none d-md-block">
                <ChartLegend />
              </Col>
            </Row>
            <div className="vstack pb-10">
              {/*[TODO]*/}
              <GrowthChart
                initialAmount={initialInvestmentAmount.toString()}
                year={yearsToHold.toString()}
                annualRate={property?.annualRateOfReturn.toString()}
              />

              <ChartLegend />
            </div>
            <div className="position-relative vstack">
              <div className="position-absolute overlap-20 translate-middle top-1/2 start-1/2">
                <Badge pill bg="light" className="text-dark ">
                  After {yearsToHold} Years
                </Badge>
              </div>
              <Row className="gx-0 gy-0">
                <Col xs={6}>
                  <Card className="bg-lavender-light rounded-end-0 rounded-start-3 h-full">
                    <CardBody className="p-4">
                      <h4>{formatAmount(initialInvestmentAmount)}</h4>
                      <p className="text-xs">Initial Investment</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={6}>
                  <Card className="bg-lavender-light rounded-start-0 h-full text-end">
                    <CardBody className="p-4">
                      {/*[TODO]*/}
                      <h4>
                        {formatAmount(
                          calculateFinalAmountAfterYear(
                            initialInvestmentAmount,
                            10,
                            yearsToHold,
                          ),
                        )}
                      </h4>
                      <p className="text-xs">End Returns</p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
