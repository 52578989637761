import { Button } from 'react-bootstrap';

export const RadioButton = ({ label, value, onClick, active }) => (
  <Button
    variant=""
    size="lg"
    className="text-sm text-dark btn border rounded-2 border-2 py-2 px-5 step-button"
    onClick={onClick}
    active={active}
  >
    {label}
  </Button>
);
