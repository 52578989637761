export const faq = [
  {
    question: 'What is PropXchange?',
    answer:
      'PropXchange is a crowdfunding platform where we make real estate ownership accessible to everyone by converting property ownership into digital form.',
  },
  {
    question: 'What are PropUnits?',
    answer:
      "Prop-Units are nothing but Tradeable Digital Assets. It has a face value of $10 once a New Property option is Launched through the platform of PropXchange. It's called NPO (New Prop Offering). For example, if the property is valued at $1,000,000, the issuer through PropXchange with the help of an EMD (Exempt Market Dealer) will issue 100,000 Prop units.",
  },
  {
    question: 'Is PropUnit a Cryptocurrency?',
    answer:
      "No, it's not a Cryptocurrency nor Non Fungible Token (NFT). Neither does it work on Blockchain.",
  },
  {
    question: 'How does it work?',
    answer: `</br>
      <p><strong>Step 1. Fractionalization of Real Estate Assets: </strong>PropXchange allows you to co-own a real estate asset with other investors.</p></br>
      <p><strong>Step 2.Standard Contract:</strong> All users agree to a standard contract which reduces the time, cost and complexity to coordinate shared ownership.</p></br>
      <p><strong>Step 3.Consolidated Property Information:</strong> PropXchange collects all the information related to each property and then displays that information to our users.</p></br>
      <p><strong>Step 4.Third Party Property Managers:</strong> PropXchange arranges for professional property management to administer and maintain each asset.</p></br>
      <p><strong>Step 5.Monthly Income to Owners:</strong> Each property is pre-leased and generates income. After deducting running costs, insurance, and taxes, the net yield is distributed monthly to PropXchange users.</p>
`,
  },
  {
    question:
      'Why is fractionalization required and how is it better than traditional real estate buying?',
    answer: `<p>The traditional real estate journey for most people consists of renting, saving and buying their own home The first barrier of purchasing any property is the own contribution as the Canadian housing market grows, down payments become larger and Price become beyond peoples reach .no easy way to split cost with others and the key problem of becoming landlord is no one really wants to be landlord. Yet statistics show Real Estate ownership is one of the very best ways to create wealth.</p> 
      </br>
      <p>PropXchange makes it easier :</p>
      <ul>
      <li>Bridge the gap between saving and buying .</li>
      <li>Allows investor to purchase shares in properties</li>
      <li>Minimum amount <strong>as low as $10.</strong></li>
      </ul>
      </br>
      <p>Last but not the least it is <strong>easier to Exit</strong> as you don't have to go through the painful process of hiring a realtor, Pay him his professional fees, pay the money to the property evaluator. Just sell your shares ( Prop Units )</p>
      `,
  },
  {
    question: 'How does PropXchange make its revenue?',
    answer:
      '<ol><li><strong>Listing Revenue:</strong> 1-3% for selling property through PropXchange.</li><li><strong>Management Fees:</strong> We charge a fee of 0.5% to the property management company who manages the property on behalf of stakeholders (shareholders).</li></ol>',
  },
  {
    question: 'Does PropXchange charge any fees from our subscribers?',
    answer:
      'No, we don’t charge anything to our subscribers as we are a non-registered crowdfunding platform.',
  },
  {
    question: 'Why invest with us?',
    answer:
      '<ul><li>Proven Team.</li><li>Strategic industry partnership.</li><li>Massive market opportunity.</li><li>We allow you to invest in some of the most sought after grade A properties which are otherwise out of reach for many.</li><li>Hassle-free and convenient. You focus on the work while we, as experts, take care of your real estate investment.</li><li>Expect an ROI (Return on Investment) which only real estate can provide.</li></ul>',
  },
  {
    question: 'How can I add funds to my Virtual Bank Wallet on PropXchange?',
    answer: `<ul><li>Once you have registered, go to the 'Add Funds' option on 'My Account'.</li> 
      <li>Follow a one-time process to link your personal bank account after a Penny Drop Verification Process initiated by our platform. You can add a bank account if it belongs to you and not your friends or family.</li>
      <li> Clicking on 'Add Funds' will take you to a secure payment gateway, where you can choose 'Interac e-transfer/Card payment (debit/credit)/PayPal'. With an API (Application Programming Interface), your wallet will be created.</li>
      <li> Once you have your money in your wallet, you can perform the task of investing into available options on PropXchange.</li> </ul>`,
  },
  {
    question: 'Are the funds that I add to my account safe?',
    answer:
      "Yes, they are.</br> All funds are added to your Virtual Bank Wallet, which is part of a Master Escrow Account maintained with a leading bank and administered by a reputed third-party escrow agent. Your Virtual Bank Wallet also has a unique identification number that identifies you, and the credit balances in your Virtual Bank Wallet will be displayed on your 'My Account' page. You can also raise an online request to withdraw funds from your Virtual Bank Wallet to your verified and linked personal bank account. The refund/transfer request shall be processed within 3 working days.",
  },
  {
    question:
      'Is there any minimum or maximum amount to be held in my Virtual Bank Wallet?',
    answer:
      'The minimum amount you can transfer into your Virtual Bank Wallet at any point in time is $10. There is no maximum limit.',
  },
  {
    question: 'How can I withdraw funds from my Virtual Bank Wallet?',
    answer:
      "You can do so by using the 'Withdraw Funds' option on 'My Account' page. You can request for all the money or some of the money in your Virtual Bank Wallet to be withdrawn. The request received will be processed in 3 working days and transferred only to your primary linked and verified personal bank account. The first account that you verified and linked on the PropXchange platform shall be treated as your primary linked bank account.",
  },
  {
    question: 'Who takes care of the property in which I have invested?',
    answer:
      'Reputed asset managers/operators/master property managers shall take care of the day-to-day operations of the property. A professional experienced team shall be appointed as the Master Property Manager for all property investment opportunities listed on the website.',
  },
  {
    question:
      'Are my investments and my data digitally secure from any hacking or technology breach?',
    answer:
      'PropXchange has deployed a multi-layer security architecture encompassing network-level data storage security as well as application-level security for securing all platform features. All user features are protected with an OTP authentication by verified users. User investment information is encrypted and kept safe from reading/access.',
  },
  {
    question: 'How does the investment work?',
    answer:
      "Based on the investment thesis and portfolio purchase parameters, 'properties' are acquired sequentially through a Limited Liability Partnership (LLP) that acts as a Special Purpose Vehicle (SPV) in which investments are in the form of partnership rights. All properties will be managed by a professional Master Property Manager (MPM) appointed by the SPV.</br> </br><p>By virtue of the said investments, you get the benefit of</p> <ol><li>The periodic rentals that may be generated by the SPV by renting out/operating such properties; and/or</li><li>Long-term capital appreciation in the value of the properties upon the sale of the properties.</li></ol>",
  },
  {
    question: 'What is an SPV?',
    answer:
      'SPV (Special Purpose Vehicle) in the context of the PropXchange platform, is incorporated under the law as an LLP. The SPVs purchase the pre-identified real estate asset and manage them with the help of a professional team.',
  },
  {
    question: 'How are projects qualified and who does it?',
    answer:
      'JLL, a renowned international property consultancy, has analyzed a list of target projects on the basis of parameters like quality, amenities, future price growth potential, and location advantage. These projects have been shortlisted from an exhaustive list of projects that fit the investment thesis. These are developed by the top real estate developers in the city and are the best in the class in terms of quality, amenities, and specifications.',
  },
  {
    question: 'What will happen if PropXchange shuts down their operations?',
    answer:
      'Your funds and investment remain safe. PropXchange is beneficial for issuers as it allows them to easily complete distributions to thousands of different investors. If PropXchange no longer existed, then the issuer would still be contractually obligated to ensure you receive your funds. In that situation, they would write individual cheques to the members who have invested in the properties. The additional fees and cumbersome nature of this process means that your returns may be slightly lower in order to pay off the additional costs of manually completing the distribution.',
  },
  {
    question: 'What are the tax implications on profits?',
    answer:
      'Capital gains taxes are levied on earnings made from the sale of assets in real estate.',
  },
];
