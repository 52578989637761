import { Button, Col, Form, Row, Image } from 'react-bootstrap';
import { useState } from 'react';
import { Footer, Header } from '../../component';
import { Link } from 'react-router-dom';
import { MdOutlineCall, MdOutlineMail } from 'react-icons/md';
import { FaArrowRight } from 'react-icons/fa6';
import { ErrorFill } from '../../assets/image';

export const ContactUs = () => {
  const [userCredentials, setUserCredentials] = useState({
    first_name: '',
    last_name: '',
    email: '',
    feedback: '',
  });
  const [userCredentialsError, setUserCredentialsError] = useState({
    first_name: false,
    email: false,
    last_name: false,
    feedback: false,
  });

  const isValidEmail = (email: string): boolean => {
    // Regular expression for validating an email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const isError = () =>
    userCredentialsError?.email ||
    userCredentialsError?.first_name ||
    userCredentialsError?.last_name ||
    userCredentialsError?.feedback ||
    userCredentials?.email === '' ||
    userCredentials?.first_name === '' ||
    userCredentials?.last_name === '' ||
    userCredentials?.feedback === '';

  return (
    <div>
      <Header />
      <div
        className="py-5 px-5 px-md-14 px-lg-18 pb-18"
        style={{ minHeight: '80vh' }}
      >
        <div
          className="rounded-5 d-flex p-sm-20"
          style={{ height: '100%', backgroundColor: '#DDEEFF' }}
        >
          <Row className="flex-grow-1 gap-sm-20 gap-0">
            <Col className="d-flex p-sm-0 p-10 flex-column align-items-start justify-content-center">
              <h1 className="display-3 mb-3 font-bold">
                <span className="font-light text-nowrap">Need Help? </span>
                <br />
                We're Here.
              </h1>
              <p className="mb-12 mb-sm-20 mb-lg-40 text-dark">
                Reach out to us with any questions or feedback!
              </p>
              <Row className="gap-10 text-nowrap">
                <Col className="hstack gap-2">
                  <div className="d-flex align-items-center justify-content-center">
                    <MdOutlineCall className="text-dark text-xl" />
                  </div>
                  <div>
                    <p className="mb-1 text-sm">Give us a call</p>
                    <p className="text-dark">+1 (236) 971-6188</p>
                  </div>
                </Col>
                <Col className="hstack gap-2">
                  <Link
                    to=""
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineMail className="text-dark text-xl" />
                  </Link>
                  <div>
                    <p className="mb-1 text-sm">Send us an email</p>
                    <p className="text-dark">
                      <u>info@propxchange.ca</u>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="d-flex p-sm-0 px-6 align-items-center justify-content-center">
              <Row
                className="border rounded-4 border-1 border-black bg-white p-10 gap-3 m-2 mb-4"
                style={{ maxWidth: '36rem' }}
              >
                <Col className="p-0" xs={12} md={6}>
                  <Form.Group
                    controlId="floatingInput"
                    className="form-floating mb-1"
                  >
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={userCredentials?.first_name}
                      className={'form-control'}
                      onChange={(event) => {
                        setUserCredentials((prev) => ({
                          ...prev,
                          first_name: event?.target?.value,
                        }));
                      }}
                      onBlur={(event) => {
                        if (
                          !event?.target?.value ||
                          event?.target?.value === ''
                        )
                          setUserCredentialsError((prev) => ({
                            ...prev,
                            first_name: true,
                          }));
                        else
                          setUserCredentialsError((prev) => ({
                            ...prev,
                            first_name: false,
                          }));
                      }}
                    />
                    <Form.Label className="font-lighter">First Name</Form.Label>
                    {userCredentialsError.first_name && (
                      <p className="invalid-color mt-1 d-flex align-item-center">
                        <Image src={ErrorFill} className="mx-2" />
                        Please enter First name
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col className="p-0" xs={12} md>
                  <Form.Group
                    controlId="floatingInput"
                    className="form-floating mb-1"
                  >
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={userCredentials?.last_name}
                      className={'form-control'}
                      onChange={(event) => {
                        setUserCredentials((prev) => ({
                          ...prev,
                          last_name: event?.target?.value,
                        }));
                      }}
                      onBlur={(event) => {
                        if (
                          !event?.target?.value ||
                          event?.target?.value === ''
                        )
                          setUserCredentialsError((prev) => ({
                            ...prev,
                            last_name: true,
                          }));
                        else
                          setUserCredentialsError((prev) => ({
                            ...prev,
                            last_name: false,
                          }));
                      }}
                    />
                    <Form.Label className="font-lighter">Last Name</Form.Label>
                    {userCredentialsError.last_name && (
                      <p className="invalid-color mt-1 d-flex align-item-center">
                        <Image src={ErrorFill} className="mx-2" />
                        Please enter Last name
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col className="p-0" xs={12}>
                  <Form.Group
                    controlId="floatingInput"
                    className="form-floating mb-1"
                  >
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={userCredentials?.email}
                      className={'form-control'}
                      onChange={(event) => {
                        setUserCredentials((prev) => ({
                          ...prev,
                          email: event?.target?.value,
                        }));
                      }}
                      onBlur={(event) => {
                        if (
                          !event?.target?.value ||
                          event?.target?.value === '' ||
                          !isValidEmail(event?.target?.value)
                        )
                          setUserCredentialsError((prev) => ({
                            ...prev,
                            email: true,
                          }));
                        else
                          setUserCredentialsError((prev) => ({
                            ...prev,
                            email: false,
                          }));
                      }}
                    />
                    <Form.Label className="font-lighter">
                      Your Email Address
                    </Form.Label>
                    {userCredentialsError.email && (
                      <p className="invalid-color mt-1 d-flex align-item-center">
                        <Image src={ErrorFill} className="mx-2" />
                        Please enter valid email address
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} className="p-0">
                  <Form.Group
                    controlId="floatingInput"
                    className="form-floating mb-1"
                  >
                    <Form.Control
                      //   type="text"
                      as="textarea"
                      rows={7}
                      placeholder="name@example.com"
                      value={userCredentials?.feedback}
                      className={'form-control h-auto'}
                      onChange={(event) => {
                        setUserCredentials((prev) => ({
                          ...prev,
                          feedback: event?.target?.value,
                        }));
                      }}
                      onBlur={(event) => {
                        if (
                          !event?.target?.value ||
                          event?.target?.value === ''
                        )
                          setUserCredentialsError((prev) => ({
                            ...prev,
                            feedback: true,
                          }));
                        else
                          setUserCredentialsError((prev) => ({
                            ...prev,
                            feedback: false,
                          }));
                      }}
                    />
                    <Form.Label className="font-lighter">
                      Share your thoughts
                    </Form.Label>
                    {userCredentialsError.feedback && (
                      <p className="invalid-color mt-1 d-flex align-item-center">
                        <Image src={ErrorFill} className="mx-2" />
                        Please add message to share
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col
                  xs={12}
                  className="p-0 d-flex justify-content-end gap-3 mt-10"
                >
                  <Button
                    variant="outline"
                    // onClick={() => scrollToTarget()}
                    className="rounded-pill btn-lg  px-xs-1 px-md-5 border border-gray"
                  >
                    Reset
                  </Button>
                  <Button
                    variant="primary"
                    disabled={isError()}
                    // onClick={() => scrollToTarget()}
                    className="rounded-pill btn-lg px-xs-1 px-md-5 text-nowrap"
                  >
                    Send
                    <FaArrowRight className="text-sm ms-2" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <Footer />
    </div>
  );
};
