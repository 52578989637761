import { Route, Routes } from 'react-router-dom';
import {
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  Home,
  Faqs,
  AboutUs,
  SuitabilityQuestionnaire,
} from '../../pages';
import VerifyEmail from '../../pages/VerifyEmail/VerifyEmail';
import { PrivateRoutes } from '../PrivateRoutes/PrivateRoutes';
import OurTeam from '../../pages/OurTeam/OurTeam';
import AuthGuard from '../AuthGuard/AuthGuard';
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from '../../pages/TermsOfUse/TermsOfUse';
import CookiesPolicy from '../../pages/CookiesPolicy/CookiesPolicy';
import { ContactUs } from '../../pages/ContactUs/ContactUs';
import { ROUTES } from '../routes';

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.REGISTER} element={<Register />} />
      <Route
        path="/activate-account/:userId/:token/:verified"
        element={<VerifyEmail />}
      />
      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path="/faq" element={<Faqs />} />
      <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
      {/* [todo](aryan) reomve unused code about, Faq */}
      <Route path={ROUTES.OUR_TEAM} element={<OurTeam />} />
      <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={ROUTES.TERMS_OF_USE} element={<TermsOfUse />} />
      <Route path={ROUTES.COOKIES_POLICY} element={<CookiesPolicy />} />
      <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
      <Route path="/*" element={<PrivateRoutes />} />
      <Route
        path={ROUTES.SUITABILITY_QUESTIONNAIRE}
        element={
          <AuthGuard>
            <SuitabilityQuestionnaire />
          </AuthGuard>
        }
      />
    </Routes>
  );
};
