import { Container, Button, Form, Image } from 'react-bootstrap';
import { Header } from '../../component';
import { IoChevronBackOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { forgotPasswordService } from '../../services/auth/forgetPasswordService';
import { toast } from 'react-toastify';
import { ErrorFill } from '../../assets/image';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();
  const sendResetLink = () => {
    forgotPasswordService({ email })
      .then((result) => {
        if (result) {
          setTimeout(() => {
            toast.success('Password Reset link has been sent successfully');
          }, 2000); // 2000 milliseconds = 2 seconds
          navigate('/login');
        }
      })
      .catch((error) => console.log(error));
  };

  const isValidEmail = (email: string): boolean => {
    // Regular expression for validating an email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  return (
    <div>
      <Header />
      <div className="h-calc vstack forgot-h-full login-background background-no-repeat bg-center bg-cover">
        <Container className="my-auto vstack ">
          <div className="mb-auto"></div>
          <div className="max-w-screen-433 mx-auto my-auto">
            <h2 className="text-center mb-1 font-bolder">Forgot Password?</h2>
            <p className="text-center text-dark mb-12">
              No worries if you forgot your password! Just drop your email or
              username, and we'll send over the reset details.
            </p>
            <Form>
              <Form.Group
                controlId="floatingInput"
                className="form-floating mb-14"
              >
                <Form.Control
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  onBlur={(event) => {
                    setEmailError(
                      !event.target.value ||
                        event.target.value === '' ||
                        !isValidEmail(event.target.value),
                    );
                  }}
                  type="text"
                  placeholder="name@example.com"
                />
                <Form.Label htmlFor="floatingInput">Email Address*</Form.Label>
                {emailError && (
                  <p className="invalid-color mt-1 d-flex align-item-center">
                    <Image src={ErrorFill} className="mx-2" />
                    Please enter valid email
                  </p>
                )}
              </Form.Group>
              <div className="text-center">
                <Button
                  disabled={emailError}
                  onClick={() => sendResetLink()}
                  variant="primary"
                  className="rounded-pill btn-lg w-full py-4 mb-6 font-semibold text-sm"
                >
                  SEND RESET LINK
                </Button>
                <Link to="/login" className="text-dark text-sm font-semibold">
                  <IoChevronBackOutline className="text-gray text-md" /> BACK TO
                  LOGIN
                </Link>
              </div>
            </Form>
          </div>
          <div className="mt-auto d-flex justify-content-center">
            <p className="font-semibold text-dark mb-10">
              Terms & conditions line
            </p>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ForgotPassword;
