import { configureStore, combineReducers, Tuple } from '@reduxjs/toolkit';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import { thunk } from 'redux-thunk';
import authReducer from '../slices/auth/authSlice';
import registrationReducer from '../slices/auth/registrationSlice';
import suitabilityQuestionnaireReducer from '../slices/suitabilityQuestionnaire/suitabilityQuestionnaireSlice';
import userResponseSQReducer from '../slices/suitabilityQuestionnaire/userResponseSQSlice';
import propertiesReducer from '../slices/property/propertiesSlice';
import paymentReducer from '../slices/payment/paymentSlice';
import sideMenuReducer from '../slices/sideMenu/sideMenuSlice';
import walletReducer from '../slices/wallet/walletSlice';

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: authReducer,
    register: registrationReducer,
    suitabilityQuestionnaire: suitabilityQuestionnaireReducer,
    userResponseSQ: userResponseSQReducer,
    properties: propertiesReducer,
    payment: paymentReducer,
    sideMenu: sideMenuReducer,
    wallet: walletReducer,
  }),
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: () => new Tuple(thunk),
});

export const persistor = persistStore(store);
