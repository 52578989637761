import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import { AiOutlineBell, AiOutlineQuestionCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store/type';
import { getInitials } from '../../utilities/helper';
import { setSelectedTab } from '../../redux/slices/sideMenu/sideMenuSlice';
import { ROUTES } from '../../routes/routes';
import { logoutService } from '../../services/auth/logoutService';
import { setFavouritesFlag } from '../../redux/slices/property/propertiesSlice';
import {
  MdOutlineHomeWork,
  MdOutlineInsertChart,
  MdStarBorder,
} from 'react-icons/md';
import { PiPiggyBank } from 'react-icons/pi';
import { BiUser } from 'react-icons/bi';
import './BottomNavbar.scss';

function BottomNavbar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const sideMenu = useSelector((state: RootState) => state.sideMenu);

  const handleLogout = () => {
    logoutService().then((res: any) => {
      if (res?.success) {
        navigate('/login');
      }
    });
  };

  return (
    <Navbar
      fixed="bottom"
      className="d-md-none d-block p-0 w-full position-fixed"
    >
      <Container fluid>
        {/* <Navbar.Toggle /> */}
        <Nav
          className="w-full bg-white justify-content-between bottom-navbar"
          style={{ maxHeight: '100px' }}
        >
          <Nav.Link
            onClick={() => {
              dispatch(setFavouritesFlag({ isFavouritesTab: false }));
              navigate(ROUTES.PROP_OFFERING);
              dispatch(setSelectedTab({ selectedTab: 'Prop Offerings' }));
            }}
            className="text-md py-3"
            active={sideMenu?.selectedTab === 'Prop Offerings'}
          >
            <MdOutlineHomeWork />
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              navigate(ROUTES.PORTFOLIO);
              dispatch(setSelectedTab({ selectedTab: 'Portfolio' }));
            }}
            className="text-md py-3"
            active={sideMenu?.selectedTab === 'Portfolio'}
          >
            <MdOutlineInsertChart />
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              dispatch(setFavouritesFlag({ isFavouritesTab: true }));
              navigate(ROUTES.FAVOURITES);
              dispatch(setSelectedTab({ selectedTab: 'Favourites' }));
            }}
            className="text-md py-3"
            active={sideMenu?.selectedTab === 'Favourites'}
          >
            <MdStarBorder />
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              navigate(ROUTES.WALLET);
              dispatch(setSelectedTab({ selectedTab: 'Wallet' }));
            }}
            className="text-md py-3"
            active={sideMenu?.selectedTab === 'Wallet'}
          >
            <PiPiggyBank />
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              navigate(ROUTES.ACCOUNT);
              dispatch(setSelectedTab({ selectedTab: 'Account' }));
            }}
            className="text-md py-3"
            active={sideMenu?.selectedTab === 'Account'}
          >
            <BiUser />
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default BottomNavbar;
