import { axios } from '../axios/AxiosConfig';
import { VOPAY_INVEST_IN_PROPERTY } from '../services/apiEndPoints';
import { useState } from 'react';
import { handleError } from '../utilities/handleError';
import { toast } from 'react-toastify';

function useInvestInProperty() {
  const [isInProgress, setIsInProgress] = useState(false);

  // TODO: Fix globalslice
  const makeInvestmentRequest = async (payload) => {
    try {
      setIsInProgress(true);

      const response = await axios.post(VOPAY_INVEST_IN_PROPERTY, payload);
      toast.success('Investment request submitted');
      return response;
    } catch (err) {
      console.error(err);
      handleError(err);
    } finally {
      setIsInProgress(false);
    }
  };
  return {
    isInProgress,
    makeInvestmentRequest,
  };
}

export default useInvestInProperty;
