import { GET_CURRENT_USER_API } from '../apiEndPoints';
import { axios } from '../../axios/AxiosConfig';
import { toast } from 'react-toastify';

export const currentUserService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_CURRENT_USER_API + '?lng=eng');
      resolve(response?.data?.data?.data);
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error);
      reject(error);
    }
  });
};
