import { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
  Offcanvas,
  Form,
} from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/pagination';
import { FaChevronLeft } from 'react-icons/fa6';
import { PropList } from './Components/PropList/PropList';
import { HeaderDark } from '../../component';
import BottomNavbar from '../../component/BottomNavbar/BottomNavbar';
import { setSelectedTab } from '../../redux/slices/sideMenu/sideMenuSlice';
import { useDispatch } from 'react-redux';
import { setFavouritesFlag } from '../../redux/slices/property/propertiesSlice';

function PropOffering() {
  const [showMenu1, setShowMenu1] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  // const handleMenuToggle = () => setShowMenu(!showMenu);
  // const handleMenuToggle1 = () => setShowMenu1(!showMenu1);

  const dispatch = useDispatch();
  dispatch(setSelectedTab({ selectedTab: 'Prop Offerings' }));
  dispatch(setFavouritesFlag({ isFavouritesTab: false }));

  return (
    <div className="pb-14">
      <HeaderDark>
        <h5 className="font-bolder mx-4">Prop Offerings</h5>
      </HeaderDark>
      <Container fluid>
        <Tabs
          defaultActiveKey="all-offerings"
          transition={false}
          className="tabs-type-1"
        >
          <Tab eventKey="all-offerings" title="All Offerings">
            <PropList
              listingType=""
              isFilterOpen={showMenu}
              setShowMenu={setShowMenu}
            />
          </Tab>
          <Tab eventKey="income-generator" title="Income Generator">
            <PropList
              listingType="Income Generator"
              isFilterOpen={showMenu}
              setShowMenu={setShowMenu}
            />
          </Tab>
          <Tab eventKey="wealth-creator" title="Wealth Creator">
            <PropList
              listingType="Large Cap Equity Fund"
              isFilterOpen={showMenu}
              setShowMenu={setShowMenu}
            />
          </Tab>
          <Tab eventKey="asset-builder" title="Asset Builder">
            <PropList
              listingType="Asset Builder"
              isFilterOpen={showMenu}
              setShowMenu={setShowMenu}
            />
          </Tab>
        </Tabs>
      </Container>

      <Offcanvas
        show={showMenu1}
        onHide={() => setShowMenu1(false)}
        placement="end"
        className="mt-16 mb-4 me-4 rounded-4 border"
      >
        <Offcanvas.Header className="gap-6 justify-content-start border-bottom ">
          <Button
            variant="link"
            className="p-0 border-0 shadow-none me-2 bg-gray-hover text-dark-hover text-dark"
            aria-label="Close"
            onClick={() => setShowMenu1(false)}
          >
            <FaChevronLeft className="bg-gray text-gray" />
          </Button>
          <p>Type of Property</p>
          <Button
            variant="link"
            className="btn-close p-0 border-0 shadow-none ms-auto me-0"
            aria-label="Close"
            onClick={() => setShowMenu1(false)}
          ></Button>
        </Offcanvas.Header>
        <Offcanvas.Body className="px-4 pt-0">
          <ul className="list-unstyled">
            <li>
              <div className="py-2 px-2 hstack">
                <Form.Check className="secondary-checkbox">
                  <Form.Check.Input
                    type="checkbox"
                    className="success-checkbox"
                  />
                </Form.Check>
                <p className="text-dark ms-2">Villa</p>
                <div className="ms-auto bg-gray-200">03</div>
              </div>
            </li>
            <li>
              <div className="py-2 px-2 hstack">
                <Form.Check className="secondary-checkbox">
                  <Form.Check.Input
                    type="checkbox"
                    className="success-checkbox"
                  />
                </Form.Check>{' '}
                <p className="text-dark ms-2">Apartment</p>
                <div className="ms-auto bg-gray-200">02</div>
              </div>
            </li>
            <li>
              <div className="py-2 px-2 hstack">
                <Form.Check className="secondary-checkbox">
                  <Form.Check.Input
                    type="checkbox"
                    className="success-checkbox"
                  />
                </Form.Check>{' '}
                <p className="text-dark ms-2">Office</p>
                <div className="ms-auto bg-gray-200">01</div>
              </div>
            </li>
          </ul>
        </Offcanvas.Body>
        <Offcanvas.Header className="gap-4">
          <Button
            variant="link"
            className="w-full border bg-primary-hover text-light-hover rounded-pill"
          >
            Clear All
          </Button>
          <Button variant="primary" className="w-full rounded-pill">
            Show 2 Properties
          </Button>
        </Offcanvas.Header>
      </Offcanvas>
      <BottomNavbar />
    </div>
  );
}

export default PropOffering;
