import { Card, CardBody } from 'react-bootstrap';

export function LocationSection(props) {
  const { property } = props;
  return (
    <Card>
      <CardBody>
        {property?.companyId?.coordinates ? (
          <iframe
            src={`https://maps.google.com/maps?q=${property?.companyId?.coordinates}&hl=es;z=14&amp;&output=embed`}
            width="100%"
            height="320"
            className="rounded-3"
            loading="lazy"
            title="iframe"
          ></iframe>
        ) : (
          <h4>Coordinates are not provided</h4>
        )}
      </CardBody>
    </Card>
  );
}
