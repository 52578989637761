// AuthGuard.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthGuard from './useAuthGuard';
import { currentUserService } from '../../services/user/currentUserService';
import { setUserReducer } from '../../redux/slices/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store/type';

const AuthGuard = ({ children }) => {
  const isAuthenticated = useAuthGuard();
  const user = useSelector((state: RootState) => state.auth?.user);
  const dispatch = useDispatch();
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  } else if (!user) {
    currentUserService().then((result: any) => {
      if (result) {
        dispatch(setUserReducer({ user: result }));
      }
    });
  }

  return children;
};

export default AuthGuard;
