import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import {
  REACT_APP_RUM_CLIENT_APPLICATION_ID,
  REACT_APP_RUM_CLIENT_IDENTITY_POOL,
} from './envConstants';
// Readme
// https://github.com/aws-observability/aws-rum-web#readme

export const initRumClient = () => {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      identityPoolId: REACT_APP_RUM_CLIENT_IDENTITY_POOL,
      endpoint: 'https://dataplane.rum.ca-central-1.amazonaws.com',
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: false,
    };

    // prod - ca-central-1:e5980e42-699d-4397-b936-79fab1fd93e6
    // prod - 934ba782-e802-4c73-b3cd-2b35444aa09c

    const APPLICATION_ID: string = REACT_APP_RUM_CLIENT_APPLICATION_ID;
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'ca-central-1';

    const awsRumClient: AwsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    );
    return awsRumClient;
  } catch (error) {
    console.error('Could not configure RUM client', error);
  }
};
