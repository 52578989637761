import {
  authFailReducer,
  authSuccessReducer,
  loadingReducer,
} from '../../redux/slices/auth/authSlice';
import { axios } from '../../axios/AxiosConfig';
import { LOGIN_API } from '../apiEndPoints';
import { getLanguageCode } from '../../utilities/localStorageFun';
import { handleError } from '../../utilities/handleError';

export const loginService = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      loadingReducer({ isLoading: true });

      const authenticationData = {
        email: email,
        password: password,
        createdAt: Date.now(),
      };

      const response = await axios.post(`${LOGIN_API}`, authenticationData, {
        params: {
          ...(getLanguageCode() !== null && {
            lng: getLanguageCode(),
          }),
          active: 1,
        },
      });

      const expirationTokenTime = 10800;
      const expirationDate = new Date(
        new Date().getTime() + expirationTokenTime * 1000,
      );
      localStorage.setItem('auth_token_key', response.data.token);
      localStorage.setItem('expirationDate', expirationDate.toString());
      localStorage.setItem('email', email);
      localStorage.setItem('slug', response.data.data.user.slug);
      localStorage.setItem('userType', response.data.data.user.userType);
      localStorage.setItem(
        'twoFactorLoginStatus',
        response.data.message.enableTwoFactorAuth,
      );
      // yield call(getCurrentUserSaga);
      // yield call(getCampaignSettingsSaga);
      // yield call(getSiteSettingsSaga);
      authSuccessReducer({ token: response.data.token });
      loadingReducer({ isLoading: false });
      // put(checkAuthTimeout(expirationTokenTime));
      // delay(500);
      // put(setAlert(true));
      // delay(8000);
      // put(setAlert(false));
      // put(isSpinning(false));
      resolve(response);
    } catch (error: any) {
      // if (appUserIdQueryParam) console.log("app-auto-login-error"); // app web view login error
      loadingReducer({ isLoading: false });
      authFailReducer({ error: error?.response?.data?.message });
      handleError(error);
      reject(error);
    }
  });
};
