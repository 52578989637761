import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  Col,
  Stack,
  ProgressBar,
  OverlayTrigger,
  Popover,
  Row,
  Button,
  Card,
  CardBody,
  Form,
} from 'react-bootstrap';
import ReactFlagsSelect from 'react-flags-select';
import RadioButtonGroup from '../../../../base-components/RadioButtonGroup/RadioButtonGroup';
import SelectDropdown from '../../../../base-components/SelectDropdown/SelectDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store/type';
import {
  addResponse,
  updateResponse,
} from '../../../../redux/slices/suitabilityQuestionnaire/userResponseSQSlice';
import { getSuitabilityQuestionnaireService } from '../../../../services/suitabilityQuestionnaire/getService';
import { questionnaireSuccessReducer } from '../../../../redux/slices/suitabilityQuestionnaire/suitabilityQuestionnaireSlice';
import { ConfirmationModal } from './components/ConfirmationModal/ConfirmationModal';
import { CiCircleInfo } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { updateUserResponseSQService } from '../../../../services/suitabilityQuestionnaire/userRSQ/updateUserResponseSQService';
import { ROUTES } from '../../../../routes/routes';
import { MdArrowBackIosNew } from 'react-icons/md';

export const QuestionsList = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const userResponseData = useSelector(
    (state: RootState) => state.userResponseSQ.responses,
  );
  const navigate = useNavigate();
  const [acceptTermAndCondition, setAcceptTermAndCondition] = useState(false);

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const questionnaireData = useSelector(
    (state: RootState) => state.suitabilityQuestionnaire.questionnaireData,
  );

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (userResponseData?.length) {
      updateUserResponseSQService(userResponseData)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => console.log(error));
    }
  }, [userResponseData]);

  const getAnswerByQuestion = (questionNumber: number) => {
    return userResponseData?.find(
      (res) => res.questionNumber === questionNumber,
    )?.answer;
  };

  const setAnswerByQuestion = (questionNumber: number, answer: string[]) => {
    if (
      userResponseData?.find((res) => res.questionNumber === questionNumber)
        ?.answer
    )
      dispatch(updateResponse({ questionNumber, answer }));
    else dispatch(addResponse({ answer, questionNumber }));
  };

  const [selected, setSelected] = useState('');

  const onSelectFlag = (countryCode) => {
    setSelected(countryCode);
  };

  useLayoutEffect(() => {
    getSuitabilityQuestionnaireService()
      .then((res: any) => {
        dispatch(
          questionnaireSuccessReducer({
            questionnaireData: res,
            successMessage: 'success',
          }),
        );
        console.log(res);
      })
      .catch((err) => console.log(err));
  }, [dispatch]);
  return (
    <React.Fragment>
      <Col className="h-sept-cal vstack col-lg-6 ps-md-6 pe-md-6 pe-lg-8 ps-xl-20 ps-lg-12 pe-xl-20 pe-lg-12">
        <Stack direction="vertical">
          <div className="d-md-block d-none">
            <p className="mb-1 text-sm font-italic font-semibold">
              {currentStep} of 5 completed
            </p>
            <ProgressBar
              variant="success"
              now={20 * currentStep}
              className="h-1 mb-6 max-w-screen-400"
            />
          </div>
          <div className="d-md-none d-flex position-sticky fixed-top bg-white align-items-center py-3 px-3 border">
            <ProgressBar
              variant="success"
              now={20 * currentStep}
              className="h-1 max-w-screen-400"
              style={{ width: '70%' }}
            />
            <p className="mb-1 text-regular font-italic font-semibold ms-2">
              {currentStep} / 5
            </p>
            <div className="position-absolute end-4 text-primary">
              <a onClick={() => navigate(ROUTES.PROP_OFFERING)}>Skip</a>
            </div>
          </div>
          <div className="max-w-screen-md-459 mt-md-0 mt-4 mb-20 mb-md-4 ps-md-0 pe-md-0 ps-6 pe-6">
            {currentStep === 1 && (
              <div>
                <h3 className="mb-18">Financial Situation</h3>
                <ol className="text-md text-dark vstack gap-10">
                  <li>
                    <p className="mb-5">
                      <span className="font-bold">
                        {' '}
                        {questionnaireData?.[0]?.question}
                      </span>
                      {questionnaireData?.[0]?.info && (
                        <OverlayTrigger
                          // trigger="click"
                          placement="auto-start"
                          overlay={
                            <Popover id="popover-1">
                              <Popover.Body
                                dangerouslySetInnerHTML={{
                                  __html: questionnaireData?.[0]?.info,
                                }}
                              >
                                {/* <h4 className="mb-1 text-base">Net Financial Assets : </h4>
                                    <p className="text-dark">
                                      Your total financial holdings, excluding debts, are your 'net financial assets.' These include cash, investments, and other
                                      assets.
                                    </p> */}
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span {...triggerHandler} ref={ref}>
                              <CiCircleInfo
                                className="ps-1 pb-1 font-large font-bold"
                                style={{
                                  fontSize: '1.4rem',
                                  strokeWidth: 0.9,
                                  stroke: 'black',
                                }}
                              />
                            </span>
                          )}
                        </OverlayTrigger>
                      )}
                    </p>

                    {questionnaireData?.[0]?.type === 'radio' && (
                      <RadioButtonGroup
                        options={questionnaireData?.[0]?.options}
                        selectedOption={getAnswerByQuestion(0)?.[0] as string}
                        onChange={(value) => setAnswerByQuestion(0, [value])}
                      />
                    )}
                    <p className="font-italic text-sm">
                      {questionnaireData?.[0]?.disclaimer}
                    </p>
                  </li>

                  <li>
                    <p className="mb-5">
                      <span className="font-bold">
                        {' '}
                        {questionnaireData?.[1]?.question}
                      </span>
                      {questionnaireData?.[1]?.info && (
                        <OverlayTrigger
                          // trigger="click"
                          placement="auto-start"
                          overlay={
                            <Popover id="popover-1">
                              <Popover.Body>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: questionnaireData?.[1]?.info,
                                  }}
                                />
                                {/* <h4 className="mb-1 text-base">Net Financial Assets : </h4>
                                    <p className="text-dark">
                                      Your total financial holdings, excluding debts, are your 'net financial assets.' These include cash, investments, and other
                                      assets.
                                    </p> */}
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span {...triggerHandler} ref={ref}>
                              <CiCircleInfo
                                className="ps-1 pb-1 font-large font-bold"
                                style={{
                                  fontSize: '1.4rem',
                                  strokeWidth: 0.9,
                                  stroke: 'black',
                                }}
                              />
                            </span>
                          )}
                        </OverlayTrigger>
                      )}
                    </p>

                    {questionnaireData?.[1]?.type === 'radio' && (
                      <RadioButtonGroup
                        options={questionnaireData?.[1]?.options}
                        selectedOption={getAnswerByQuestion(1)?.[0] as string}
                        onChange={(value) => setAnswerByQuestion(1, [value])}
                      />
                    )}
                    <p className="font-italic text-sm">
                      {questionnaireData?.[1]?.disclaimer}
                    </p>
                  </li>
                </ol>
              </div>
            )}
            {currentStep === 2 && (
              <div>
                <h3 className="mb-18">Past Investment Experience</h3>
                <ol className="text-md text-dark vstack gap-10" start={3}>
                  <li>
                    <p className="mb-5">
                      <span className="font-bold">
                        {' '}
                        {questionnaireData?.[2]?.question}
                      </span>
                      {questionnaireData?.[2]?.info && (
                        <OverlayTrigger
                          // trigger="click"
                          placement="auto-start"
                          overlay={
                            <Popover id="popover-1">
                              <Popover.Body>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: questionnaireData?.[2]?.info,
                                  }}
                                />
                                {/* <h4 className="mb-1 text-base">Net Financial Assets : </h4>
                                    <p className="text-dark">
                                      Your total financial holdings, excluding debts, are your 'net financial assets.' These include cash, investments, and other
                                      assets.
                                    </p> */}
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span {...triggerHandler} ref={ref}>
                              <CiCircleInfo
                                className="ps-1 pb-1 font-large font-bold"
                                style={{
                                  fontSize: '1.4rem',
                                  strokeWidth: 0.9,
                                  stroke: 'black',
                                }}
                              />
                            </span>
                          )}
                        </OverlayTrigger>
                      )}
                    </p>

                    {questionnaireData?.[2]?.type === 'radio' && (
                      <RadioButtonGroup
                        options={questionnaireData?.[2]?.options}
                        selectedOption={getAnswerByQuestion(2)?.[0] as string}
                        onChange={(value) => setAnswerByQuestion(2, [value])}
                      />
                    )}
                    <p className="font-italic text-sm">
                      {questionnaireData?.[2]?.disclaimer}
                    </p>
                  </li>
                  <li>
                    <p className="mb-5">
                      <span className="font-bold">
                        {' '}
                        {questionnaireData?.[3]?.question}
                      </span>
                      {questionnaireData?.[3]?.info && (
                        <OverlayTrigger
                          // trigger="click"
                          placement="auto-start"
                          overlay={
                            <Popover id="popover-1">
                              <Popover.Body>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: questionnaireData?.[3]?.info,
                                  }}
                                />
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span {...triggerHandler} ref={ref}>
                              <CiCircleInfo
                                className="ps-1 pb-1 font-large font-bold"
                                style={{
                                  fontSize: '1.4rem',
                                  strokeWidth: 0.9,
                                  stroke: 'black',
                                }}
                              />
                            </span>
                          )}
                        </OverlayTrigger>
                      )}
                    </p>
                    {questionnaireData?.[3]?.type === 'radio' && (
                      <RadioButtonGroup
                        options={questionnaireData?.[3]?.options}
                        selectedOption={getAnswerByQuestion(3)?.[0] as string}
                        onChange={(value) => setAnswerByQuestion(3, [value])}
                      />
                    )}
                    <p className="font-italic text-sm">
                      {questionnaireData?.[3]?.disclaimer}
                    </p>
                  </li>
                  <li>
                    <p className="mb-5">{questionnaireData?.[4]?.question}</p>

                    <SelectDropdown
                      options={questionnaireData?.[4]?.options}
                      onChange={(value) =>
                        setAnswerByQuestion(4, [value as string])
                      }
                    />
                  </li>
                </ol>
              </div>
            )}

            {currentStep === 3 && (
              <div>
                <h3 className="mb-18">Investment Preferences</h3>
                <ol className="text-md text-dark vstack gap-10" start={6}>
                  <li>
                    <p className="mb-5">
                      <span className="font-bold">
                        {' '}
                        {questionnaireData?.[5]?.question}
                      </span>
                      {questionnaireData?.[5]?.info && (
                        <OverlayTrigger
                          // trigger="click"
                          placement="auto-start"
                          overlay={
                            <Popover id="popover-1">
                              <Popover.Body>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: questionnaireData?.[5]?.info,
                                  }}
                                />
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span {...triggerHandler} ref={ref}>
                              <CiCircleInfo
                                className="ps-1 pb-1 font-large font-bold"
                                style={{
                                  fontSize: '1.4rem',
                                  strokeWidth: 0.9,
                                  stroke: 'black',
                                }}
                              />
                            </span>
                          )}
                        </OverlayTrigger>
                      )}
                    </p>
                    {questionnaireData?.[5]?.type === 'radio' && (
                      <RadioButtonGroup
                        options={questionnaireData?.[5]?.options}
                        selectedOption={getAnswerByQuestion(5)?.[0] as string}
                        onChange={(value) => setAnswerByQuestion(5, [value])}
                      />
                    )}
                    <p className="font-italic text-sm">
                      {questionnaireData?.[5]?.disclaimer}
                    </p>
                  </li>
                  <li>
                    <p className="mb-5">
                      <span className="font-bold">
                        {' '}
                        {questionnaireData?.[6]?.question}
                      </span>
                      {questionnaireData?.[6]?.info && (
                        <OverlayTrigger
                          // trigger="click"
                          placement="auto-start"
                          overlay={
                            <Popover id="popover-1">
                              <Popover.Body>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: questionnaireData?.[6]?.info,
                                  }}
                                />
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span {...triggerHandler} ref={ref}>
                              <CiCircleInfo
                                className="ps-1 pb-1 font-large font-bold"
                                style={{
                                  fontSize: '1.4rem',
                                  strokeWidth: 0.9,
                                  stroke: 'black',
                                }}
                              />
                            </span>
                          )}
                        </OverlayTrigger>
                      )}
                    </p>
                    {questionnaireData?.[6]?.type === 'radio' && (
                      <RadioButtonGroup
                        options={questionnaireData?.[6]?.options}
                        selectedOption={getAnswerByQuestion(6)?.[0] as string}
                        onChange={(value) => setAnswerByQuestion(6, [value])}
                      />
                    )}
                    <p className="font-italic text-sm">
                      {questionnaireData?.[6]?.disclaimer}
                    </p>
                  </li>
                  <li>
                    <p className="mb-5">
                      <span className="font-bold">
                        {' '}
                        {questionnaireData?.[7]?.question}
                      </span>
                      {questionnaireData?.[7]?.info && (
                        <OverlayTrigger
                          // trigger="click"
                          placement="auto-start"
                          overlay={
                            <Popover id="popover-1">
                              <Popover.Body>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: questionnaireData?.[7]?.info,
                                  }}
                                />
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span {...triggerHandler} ref={ref}>
                              <CiCircleInfo
                                className="ps-1 pb-1 font-large font-bold"
                                style={{
                                  fontSize: '1.4rem',
                                  strokeWidth: 0.9,
                                  stroke: 'black',
                                }}
                              />
                            </span>
                          )}
                        </OverlayTrigger>
                      )}
                    </p>
                    {questionnaireData?.[7]?.type === 'radio' && (
                      <RadioButtonGroup
                        options={questionnaireData?.[7]?.options}
                        selectedOption={getAnswerByQuestion(7)?.[0] as string}
                        onChange={(value) => setAnswerByQuestion(7, [value])}
                      />
                    )}
                    <p className="font-italic text-sm">
                      {questionnaireData?.[7]?.disclaimer}
                    </p>
                  </li>
                  <li>
                    <p className="mb-5">
                      <span className="font-bold">
                        {' '}
                        {questionnaireData?.[8]?.question}
                      </span>
                      {questionnaireData?.[8]?.info && (
                        <OverlayTrigger
                          // trigger="click"
                          placement="auto-start"
                          overlay={
                            <Popover id="popover-1">
                              <Popover.Body>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: questionnaireData?.[8]?.info,
                                  }}
                                />
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span {...triggerHandler} ref={ref}>
                              <CiCircleInfo
                                className="ps-1 pb-1 font-large font-bold"
                                style={{
                                  fontSize: '1.4rem',
                                  strokeWidth: 0.9,
                                  stroke: 'black',
                                }}
                              />
                            </span>
                          )}
                        </OverlayTrigger>
                      )}
                    </p>
                    {questionnaireData?.[8]?.type === 'radio' && (
                      <RadioButtonGroup
                        options={questionnaireData?.[8]?.options}
                        selectedOption={getAnswerByQuestion(8)?.[0] as string}
                        onChange={(value) => setAnswerByQuestion(8, [value])}
                      />
                    )}
                    <p className="font-italic text-sm">
                      {questionnaireData?.[8]?.disclaimer}
                    </p>
                  </li>
                </ol>
              </div>
            )}
            {currentStep === 4 && (
              <div>
                <h3 className="mb-18">Personal Information</h3>
                <ol className="text-md text-dark vstack gap-10" start={10}>
                  <li>
                    <p className="mb-5 font-bold">
                      {questionnaireData?.[9]?.question}
                    </p>

                    <ReactFlagsSelect
                      selected={selected}
                      onSelect={onSelectFlag}
                      searchable={true}
                      className="max-w-screen-330"
                    />
                  </li>
                  <li>
                    <p className="mb-5">
                      <span className="font-bold">
                        {' '}
                        {questionnaireData?.[10]?.question}
                      </span>
                      {questionnaireData?.[10]?.info && (
                        <OverlayTrigger
                          // trigger="click"
                          placement="auto-start"
                          overlay={
                            <Popover id="popover-1">
                              <Popover.Body>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: questionnaireData?.[10]?.info,
                                  }}
                                />
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span {...triggerHandler} ref={ref}>
                              <CiCircleInfo
                                className="ps-1 pb-1 font-large font-bold"
                                style={{
                                  fontSize: '1.4rem',
                                  strokeWidth: 0.9,
                                  stroke: 'black',
                                }}
                              />
                            </span>
                          )}
                        </OverlayTrigger>
                      )}
                    </p>
                    {questionnaireData?.[10]?.type === 'radio' && (
                      <RadioButtonGroup
                        options={questionnaireData?.[10]?.options}
                        selectedOption={getAnswerByQuestion(10)?.[0] as string}
                        onChange={(value) => setAnswerByQuestion(10, [value])}
                      />
                    )}
                    <p className="font-italic text-sm">
                      {questionnaireData?.[10]?.disclaimer}
                    </p>
                  </li>
                  <li>
                    <span className="font-bold">
                      {' '}
                      <p className="mb-5">
                        {questionnaireData?.[11]?.question}
                      </p>
                    </span>
                    <SelectDropdown
                      options={questionnaireData?.[11]?.options}
                      onChange={(value) =>
                        setAnswerByQuestion(11, [value as string])
                      }
                    />
                  </li>

                  <li>
                    <span className="font-bold">
                      {' '}
                      <p className="mb-5">
                        {questionnaireData?.[12]?.question}
                      </p>
                    </span>
                    <SelectDropdown
                      options={questionnaireData?.[12]?.options}
                      onChange={(value) =>
                        setAnswerByQuestion(12, [value as string])
                      }
                    />
                  </li>
                </ol>
              </div>
            )}
            {currentStep === 5 && (
              <div>
                <h3 className="mb-18">Legal and Compliance</h3>
                <ol className="text-md text-dark vstack gap-10 mb-7" start={14}>
                  <li>
                    <p className="mb-5">
                      <span className="font-bold">
                        {' '}
                        {questionnaireData?.[13]?.question}
                      </span>
                      {questionnaireData?.[13]?.info && (
                        <OverlayTrigger
                          // trigger="click"
                          placement="auto-start"
                          overlay={
                            <Popover id="popover-1">
                              <Popover.Body>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: questionnaireData?.[13]?.info,
                                  }}
                                />
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span {...triggerHandler} ref={ref}>
                              <CiCircleInfo
                                className="ps-1 pb-1 font-large font-bold"
                                style={{
                                  fontSize: '1.4rem',
                                  strokeWidth: 0.9,
                                  stroke: 'black',
                                }}
                              />
                            </span>
                          )}
                        </OverlayTrigger>
                      )}
                    </p>
                    {questionnaireData?.[13]?.type === 'radio' && (
                      <RadioButtonGroup
                        options={questionnaireData?.[13]?.options}
                        selectedOption={getAnswerByQuestion(13)?.[0] as string}
                        onChange={(value) => setAnswerByQuestion(13, [value])}
                      />
                    )}
                    <p className="font-italic text-sm">
                      {questionnaireData?.[13]?.disclaimer}
                    </p>
                  </li>
                  <li>
                    <p className="mb-5">
                      <span className="font-bold">
                        {' '}
                        {questionnaireData?.[14]?.question}
                      </span>
                      {questionnaireData?.[14]?.info && (
                        <OverlayTrigger
                          // trigger="click"
                          placement="auto-start"
                          overlay={
                            <Popover id="popover-1">
                              <Popover.Body>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: questionnaireData?.[14]?.info,
                                  }}
                                />
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span {...triggerHandler} ref={ref}>
                              <CiCircleInfo
                                className="ps-1 pb-1 font-large font-bold"
                                style={{
                                  fontSize: '1.4rem',
                                  strokeWidth: 0.9,
                                  stroke: 'black',
                                }}
                              />
                            </span>
                          )}
                        </OverlayTrigger>
                      )}
                    </p>
                    {questionnaireData?.[14]?.type === 'radio' && (
                      <RadioButtonGroup
                        options={questionnaireData?.[14]?.options}
                        selectedOption={getAnswerByQuestion(14)?.[0] as string}
                        onChange={(value) => setAnswerByQuestion(14, [value])}
                      />
                    )}
                    <p className="font-italic text-sm">
                      {questionnaireData?.[14]?.disclaimer}
                    </p>
                  </li>
                  <li>
                    <p className="mb-5">
                      <span className="font-bold">
                        {' '}
                        {questionnaireData?.[15]?.question}
                      </span>
                      {questionnaireData?.[15]?.info && (
                        <OverlayTrigger
                          // trigger="click"
                          placement="auto-start"
                          overlay={
                            <Popover id="popover-1">
                              <Popover.Body>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: questionnaireData?.[15]?.info,
                                  }}
                                />
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {({ ref, ...triggerHandler }) => (
                            <span {...triggerHandler} ref={ref}>
                              <CiCircleInfo
                                className="ps-1 pb-1 font-large font-bold"
                                style={{
                                  fontSize: '1.4rem',
                                  strokeWidth: 0.9,
                                  stroke: 'black',
                                }}
                              />
                            </span>
                          )}
                        </OverlayTrigger>
                      )}
                    </p>
                    {questionnaireData?.[15]?.type === 'radio' && (
                      <RadioButtonGroup
                        options={questionnaireData?.[15]?.options}
                        selectedOption={getAnswerByQuestion(15)?.[0] as string}
                        onChange={(value) => setAnswerByQuestion(15, [value])}
                      />
                    )}
                    <p className="font-italic text-sm">
                      {questionnaireData?.[15]?.disclaimer}
                    </p>
                  </li>
                </ol>
                <Card className="rounded-1 bg-gray-200">
                  <CardBody className="d-flex gap-1 p-4">
                    <Form.Check
                      type="checkbox"
                      checked={acceptTermAndCondition}
                      onChange={(e) =>
                        setAcceptTermAndCondition(e.target.checked)
                      }
                      id="confirm-investment"
                    />
                    <p className="font-italic">
                      I confirm that my Investment in a single Offering is less
                      than 10% of my net financial assets.
                    </p>
                  </CardBody>
                </Card>
              </div>
            )}
          </div>
          <Row className="d-flex align-items-center justify-content-between position-md-static fixed-bottom px-4 py-2 bottom-bg px-md-0 py-md-0 mt-auto mb-xxl-24 mb-xl-18 mb-lg-14 mb-0 max-w-screen-md gap-md-2">
            <Col className="d-block" xs={4}>
              {currentStep != 1 && (
                <Button
                  variant=""
                  className="rounded-2 btn-sm text-sm"
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  <MdArrowBackIosNew className="me-1" />
                  Back
                </Button>
              )}
            </Col>
            {currentStep != 5 ? (
              <Col
                className="d-flex justify-content-end align-items-center"
                xs={4}
                md={7}
              >
                {currentStep != 5 && (
                  <Button
                    variant=""
                    className="btn-sm rounded-2 text-primary d-md-flex d-none"
                    onClick={() => navigate(ROUTES.PROP_OFFERING)}
                  >
                    Skip All
                  </Button>
                )}
                <Button
                  variant="primary"
                  className="rounded-pill btn-lg px-8 text-sm"
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  Continue
                </Button>
              </Col>
            ) : (
              <Col className="d-flex justify-content-end" xs={4} md={7}>
                <Button
                  variant="dark"
                  disabled={!acceptTermAndCondition}
                  className="rounded-pill btn-md px-16 py-4 text-sm"
                  onClick={() => handleShow()}
                >
                  Confirm
                </Button>
              </Col>
            )}
          </Row>
        </Stack>
      </Col>
      <ConfirmationModal handleClose={handleClose} show={show} />
    </React.Fragment>
  );
};
