export const REGISTRATION_API = `users/signup`;
export const LOGIN_API = `users/login`;
export const UPDATE_PASSWORD_API = `users/updateMyPassword`;
export const GET_CURRENT_USER_API = `users/me`;
export const UPDATE_MY_PROFILE_API = `users/updateMe`;
export const USER_EMAIL_PREFERNECES_API = `email-preferences`;
export const USER_EMAIL_PREFERNECES_API_UPDATE = `email-preferences/`;
export const INVESTOR_PROFILE = `investor-profiles`;
export const FORGOT_PASSWORD = `users/forgotPassword`;
export const RESET_PASSWORD = `users/resetPassword/`;
export const INVESTOR_PROFILE_UPDATE_API = `investor-profiles/`;
export const SITE_SETTINGS = `admin/site-setting/front`;
export const SLIDERS_API = `admin/sliders/front-sliders?status=yes`;
export const GET_LANGUAGES = `admin/languages/front?fields=name,folder&sort=createdAt&status=true`;
export const GET_DEFAULT_LANGUAGE = `admin/languages/front?fields=name,folder&isDefault=true`;
export const RECAPTCHA_API = `admin/recaptcha/front`;
export const MENU_API = `admin/menus/`;
export const CAMPAIGN_TYPES_API = `admin/types/front`;
export const GET_CATEGORIES_API = `admin/categories/front`;
export const GET_INDUSTRY_API = `admin/manage-options/company-industry`;
export const GET_TEAM_MEMBER_API = `admin/manage-options/team-member-type`;
export const GET_CAMPAIGN_TEAM_MEMBERS_API = `equity/team-members/`;
export const COMPANY_DATA_API = `equity/company-profile/`;
export const CAMPAIGN_STAGE_API = `admin/manage-options/campaign-stage`;
export const INVESTING_ROUND_API = `admin/manage-options/investing-round`;
export const INVESTOR_API = `admin/manage-options/investor-type`;
export const FUNDRAISING_API = `campaign/fundraising-detail/`;
export const CONTENT_PAGE_API = `admin/content-page/pages/`;
export const DEAL_TYPE_API = `admin/deal-types/front?status=yes`;
export const CAMPAIGN_LIST_API = `campaigns/equities`;
export const CAMPAIGN_DELETE_API = `campaigns/`;
export const CREATE_CAMPAIGN_TYPE_API = `equities/`;
export const TERMS_API = `campaign/campaign-terms/`;
export const STORY_API = `campaign/campaign-story/`;
export const EXTRAS_API = `campaign/extras-info/`;
export const EXTRAS_IMAGE_API = `equity/extras-images/`;
export const EXTRAS_DOCUMENT_API = `equity/extras-document/`;
export const EXTRAS_VIDEOS_API = `equity/extras-videos/`;
export const PAYMENT_API = `campaign/payment-detail/`;
export const META_API = `admin/meta-setting/front`;
export const EQUITY_API = `equities/`;
export const FAQ_API = `admin/content-page/faqs/front/`;
export const FAQS_API = `equity/campaign-faqs/`;
export const ALL_FRONT_FAQ_API = '/admin/front-faqs/front';
export const EQUITY_UPDATE_API = `equity/updates/`;
export const SAVE_CAMPAIGN = 'saved-campaigns/';
export const INVESTMENT_API = `process-investment/`;
export const PROCESS_INVESTMENT_STATUS = `process-investment/process-investment-check`;
export const CONTRACT_API = `contracts/generate-pdf`;
export const CREATE_PDF = `contracts/create-pdf`;
export const GET_CONTRACT_API = `/contracts/get-investor-contract`;
export const PAYMENT_GATEWAY_API = `admin/payment-gateway/`;
export const NORTH_KYC_API = `northKYC/createParty`;
export const NORTH_KYC_UPDATE_PARTY_API = `northKYC/update-party`;
export const UPLOAD_PARTY_DOCUMENT_API = `northKYC/uploadPartyDocument`;
export const GET_NORTH_KYC_STATUS_API = `northKYC/get-user-kyc`;
export const UPDATE_NORTH_KYC_STATUS_API = `northKYC/updateKycAml`;
export const STRIPE_API = `equity/payment-gateway/checkout-session/`;
export const CHECK_STRIPE_API = `equity/payment-gateway/check-payment-session/`;
export const TRANSACTIONS_API = `transactions/`;
export const USER_PROFILE_API = `users/profile-network/`;
export const COMMENTS_API = `equity/comments/`;
export const VERIFY_PROFILE_API = `users/activeAccount/`;
export const ACTIVITY_API = `user-activity/`;
export const USER_NOTIFICATION_API = `notifications`;
export const SEARCH_API = `equities/front`;
export const ACTIVATE_ACCOUNT_API = `users/activeAccount/`;
export const LEAD_INVESTOR_API = `equity/lead-investors/`;
export const REVENUE_API = `admin/revenue-setting/front`;
export const COUNTRIES_API = `admin/manage-options/countries`;
export const RESEND_EMAIL_API = `equity/team-members/resend-email/`;
export const CAMPAIGN_SETTINGS_API = `admin/campaign-setting/front`;
export const MY_INVESTMENTS_API = `my-investment/`;
export const MY_INVESTMENTS_DASHBOARD_API = `my-investment/dashboard/`;
export const MY_INVESTMENTS_GRAPH_API = `my-investment/get-graph-data`;
export const MY_INVESTMENTS_COLUMN_CHART_API = `my-investment/get-graph-by-transactions`;
export const CURRENCY_API = `admin/currencies/front`;
export const LATEST_INVESTMENTS_API = `process-investment/get-latest-investment-activity/front`;
export const STATISTICS_API = `users/get-investment-received-pending-investments`;
export const SOCIAL_LOGIN = `users/social-login`;
export const USER_INVITATION_API = `users/send-invites`;
export const USER_PROFILE_CAMAPAIGNS_API = 'users/get-user-campaigns/';
export const USER_PROFILE_INVESTORS_API = 'users/get-user-investments/';
export const USER_INVESTMENT_UNIQUE_CURRENCIES_API =
  'my-investment/get-unique-currency';
export const CONTACT_US_API = `contact-us`;
export const USERS_DELETE_ACCOUNT_API = `users/request-delete-account`;
export const PAYPAL_GENERATE_TOKEN_API = `equity/paypal-payment/checkout-session`;
export const PAYPAL_CONFIRM_PAYMENT_API = `equity/paypal-payment/process-payment`;
export const GET_CAMPAIGN_INVESTORS_API = `process-investment/campaign-investors`;
export const VERIFY_TWO_FACTOR_USER_API = `users/validateSignupTwoFactorUser`;
export const VERIFY_TWO_FACTOR_LOGIN_API = `users/validateLoginTwoFactorUser`;
export const USER_TYPE_API = `admin/usertypes/`;
export const USER_STRIPE_CONNECTION_URL = `equity/ach-payment/get-connection-url`;
export const USER_STRIPE_ACH_CONNECTED_API = `equity/ach-payment/connect-ach-account`;
export const USER_STRIPE_ACH_GET_ALL_USER_ACCOUNTS_API = `equity/ach-payment/get-all-accounts-by-user`;
export const USER_STRIPE_ACH_DISCONNECTED_API = `equity/ach-payment/disconnect-ach-account/`;
export const GET_TWO_FACTOR_QR_CODE = `users/regenerate-qr-code`;

export const STRIPE_ACH_PAYMENT_TOKEN_API = `equity/ach-payment/create-payment-link-token`;
export const STRIPE_ACH_PAYMENT_CONFIRMATION_ID_API = `equity/ach-payment/process-payment`;

export const CAMPAIGN_ACH_SETTINGS = `admin/payment-gateway/ach/front?fields=ownerPayoutEnable,achAutoRepayment,repaymentStatus,achSupport,status`;
export const SUBSCRIBE_NEWSLETTER = `users/current-user-newsletter-subscribe`;
export const RESEND_EMAIL_VERIFICATION_API = `users/resendUserActivationLink`;
export const USER_LOGOUT_API = `users/logout`;

export const SOCIAL_SETTING_API = `admin/social-signup/`;

export const ADD_WALLET_TOP_UP_API = `wallet/add-wallet-topup`;
export const WALLET_ACH_PAYMENT_API = `/equity/ach-payment/wallet-process-payment`;
export const GET_WALLET_DETAILS_API = `wallet/get-user-wallet-detail`;
export const GET_WALLET_TRANSACTIONS_LIST_API = `wallet/get-user-wallet-list`;
export const GET_WALLET_PREAPPROVAL_DETAILS_API = `wallet/wallet-preapproval`;
export const WALLET_PAYMENT_API = `wallet/update-wallet-topup`;
export const WALLET_STRIPE_PAYMENT_API = `equity/payment-gateway/wallet-checkout-session`;
export const WITHDRAW_FROM_WALLET_API = `wallet/wallet-withdraw`;

// VoPay API starts
export const VOPAY_GET_USER_BALANCE = `wallet/v2/user/balance`;
export const VOPAY_SET_UP_USER_WALLET = `wallet/v2/user/setup`;
export const VOPAY_INITIATE_INTERAC_TOP_UP_REQUEST = `wallet/v2/user/interac-transfer-request`;
export const VOPAY_INVEST_IN_PROPERTY = `invest/v2/investment-in-property`;
export const VOPAY_INVESTMENT_HOLDINGS = `invest/v2/equity-holdings`;
export const VOPAY_GET_USER_WALLET_TRANSACTION_HISTORY = `wallet/v2/user/transactions-history`;
// VoPay API ends

export const APP_AUTO_LOGIN = `users/app-auto-login`;
export const TAXONOMY_API = `admin/taxonomy-setting/front`;
export const SAVE_INVESTMENT = `save-investment`;
export const SAVE_INVESTMENT_DELETE = `save-investment/delete-saved-investment-user/`;
export const CHECK_SAVE_INVESTMENT = `save-investment/check-saved-investment`;

// MangoPay API
export const GET_MANGOPAY_CURRENT_WALLET_API = `equity/mangopay-payment/wallet-detail`;
export const GET_MANGOPAY_TRANSACTIONS_LIST_API = `equity/mangopay-payment/wallet-transaction-list`;
export const ADD_MANGOPAY_WALLET_TOP_UP_API = `equity/mangopay-payment/checkout-session`;
export const GET_MANGOPAY_PAYMENT_RECEIPT_API = `equity/mangopay-payment/check-payment-session`;
export const GET_MANGOPAY_AUTHORIZED_COUNTRIES_API = `equity/mangopay-payment/get-authorized-countries`;
export const POST_MANGOPAY_LINKED_BANK_ACCOUNTS_API =
  'equity/mangopay-payment/linked-bank-accounts';
export const GET_MANGOPAY_BANK_DETAIL_API = `equity/mangopay-payment/linked-bank-account`;
export const GET_MANGOPAY_DEACTIVATE_BANK_ACCOUNT_API = `equity/mangopay-payment/deactivate-bank-account`;
export const MANGOPAY_PAYOUT_API = `equity/mangopay-payment/process-payout`;

// Image Upload API using CKEditor
export const CKEDITOR_IMAGE_UPLOAD_API = `admin/content-page/pages/custom-image-upload/front`;

// Share Certificate
export const EQUITY_SHARE_CERTIFICATE_API = `equity/share-certificate/`;

// PropXchange

// Suitability Questionnaire
export const GET_SUITABILITY_QUESTIONNAIRE_API = 'suitability-questionnaire';
export const USER_RESPONSE_SQ_API = 'suitability-questionnaire/user';
