import { toast } from 'react-toastify';
import axios from 'axios';

export const handleError = (error) => {
  if (axios.isAxiosError(error)) {
    const errorMessage =
      error?.response?.data?.errors?.[0]?.msg ||
      error?.response?.data?.message?.message ||
      error?.response?.data?.message ||
      error?.message ||
      'An error occurred';
    toast.error(errorMessage);
  } else {
    toast.error(error?.message || 'An error occurred');
  }
};
