import { Header } from '../../component';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  Image,
} from 'react-bootstrap';
import '../../pages/SuitabilityQuestionnaire/SuitabilityQuestionnaire.scss';
import '../SuitabilityQuestionnaire/SuitabilityQuestionnaire.scss';
import { QuestionsList } from './components/QuestionsList.tsx/QuestionsList';
import { Farm, MetroVillas, VillageHouse } from '../../assets/image';

function SuitabilityQuestionnaire() {
  return (
    <>
      <Header className="d-md-block d-none" />
      <Container className="gx-0" fluid>
        <Row className="gx-0 gy-6 suitability-row">
          <Col className="bg-light-yellow pt-16 pb-0 rounded-top-2 vstack h-sept-cal col-lg-6 overflow-hidden d-md-block d-none">
            <div className="max-w-screen-sm mx-auto w-full px-8 pt-8 h-full">
              <h1 className="display-3 mb-16 font-regular text-black">
                <span className="font-light">Start. </span>
                <br />
                <span className="font-light"> Invest. </span> Grow.
                <br />
              </h1>
              <Card className="bg-light border border-2 border-bottom-0 rounded-bottom-0 border-dark h-full max-w-screen-590">
                <CardBody>
                  {/* <div className="hstack flex-warp gap-4 "> slkdhklshdklhskl</div> */}
                  <Row className="mb-4 justify-content-start gap-3">
                    <Col className="p-0" xs={'auto'}>
                      <Button
                        variant="outline-dark"
                        className="font-bold py-2 px-6 rounded-5"
                      >
                        Income Generator
                      </Button>
                    </Col>
                    <Col className="p-0" xs={'auto'}>
                      <Button
                        variant="outline-dark"
                        style={{ width: '100%' }}
                        className="font-bold py-2 px-6 rounded-5"
                      >
                        Wealth Creator
                      </Button>
                    </Col>
                    <Col className="p-0" xs={'auto'}>
                      <Button
                        variant="outline-dark"
                        style={{ width: '100%' }}
                        className="font-bold py-2 px-6 rounded-5"
                      >
                        Asset Builder
                      </Button>
                    </Col>
                  </Row>
                  <Row className="gap-2">
                    <Col xs sm className="p-0">
                      <Image
                        src={VillageHouse}
                        rounded
                        style={{ width: '100%', height: '7rem' }}
                      />
                    </Col>
                    <Col xs sm className="p-0">
                      <Image
                        src={Farm}
                        rounded
                        style={{ width: '100%', height: '7rem' }}
                      />
                    </Col>
                    <Col xs={12} sm md className="p-0">
                      <Image
                        src={MetroVillas}
                        rounded
                        style={{ width: '100%', height: '7rem' }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
          <QuestionsList />
        </Row>
      </Container>
    </>
  );
}

export default SuitabilityQuestionnaire;
