import { Card, CardBody, Row, Col } from 'react-bootstrap';
import { FaRegFileAlt } from 'react-icons/fa';
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import { REACT_APP_AWS_URL } from '../../utilities/envConstants';

export function DocumentSection(props) {
  const { selectedPropertyDocs } = props;

  const openPdf = (path: string) => {
    const newWindow = window.open();
    const pdfUrl = `${REACT_APP_AWS_URL}/${path}`;
    // window.open(pdfUrl, '_blank');
    newWindow?.document.write(`
      <html>
        <head>
          <title>PDF Viewer</title>
        </head>
        <body>
          <embed src="${pdfUrl}" type="application/pdf" width="100%" height="100%" />
        </body>
      </html>
    `);
  };

  return (
    <Card className="mb-8">
      <CardBody>
        <h6 className="text-sm text-gray-700 mb-4">Due Diligence</h6>
        <Row className="row-cols-lg-4 row-cols-sm-2 gy-4">
          {selectedPropertyDocs?.map((docs) => (
            <Col md={4}>
              <Card onClick={() => openPdf(docs?.documentUrl)}>
                <CardBody className="p-4 hstack gap-1">
                  <FaRegFileAlt />
                  <p className="text-sm">{docs?.documentTitle}</p>
                  <FaArrowUpRightFromSquare
                    color="#812ad8"
                    className="ms-auto"
                  />
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
}
