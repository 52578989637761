import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WalletState {
  isSetUp: boolean | null;
  balance: number;
}

const initialState: WalletState = {
  isSetUp: null,
  balance: 0,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setBalance(state, action: PayloadAction<{ balance: number }>) {
      state.balance = action.payload.balance;
      state.isSetUp = true;
    },
    setIsSetUp(state, action: PayloadAction<{ isSetUp: boolean }>) {
      state.isSetUp = action.payload.isSetUp;
    },
  },
});

export const { setBalance, setIsSetUp } = walletSlice.actions;

export default walletSlice.reducer;
