import { toast } from 'react-toastify';
import { axios } from '../../axios/AxiosConfig';
import { EXTRAS_DOCUMENT_API } from '../apiEndPoints';
import { DocumentData } from './types';

export const getPropertyDocs = async (equityId): Promise<DocumentData[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${EXTRAS_DOCUMENT_API}${equityId}/front`,
      );
      resolve(response.data?.data?.data);
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error);
      reject(error);
    }
  });
};
