import { Container, Row, Col, Image } from 'react-bootstrap';
import {
  Discord,
  Facebook2,
  Instagram,
  LinkedIn,
  LogoFooter,
  TwitterLight,
  Youtube,
} from '../../assets/image';
import {
  MdOutlineCall,
  MdOutlineLocationOn,
  MdOutlineMail,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes/routes';
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  TWITTER_URL,
} from '../../constants';

function Footer() {
  const handleNavigation = (url) => {
    // This forces a reload when navigating to the same URL
    if (window.location.pathname === url) {
      window.location.reload();
    } else {
      window.location.href = url;
    }
  };

  return (
    <>
      <div className="bg-black px-5 px-md-16">
        <div className="border-gray-800 border-bottom">
          <Container className="py-8  ">
            <Row className="gy-6">
              <Col md={4}>
                <Image src={LogoFooter} />
              </Col>
              <Col md={8}>
                <div className="hstack gap-xl-20 gap-lg-12 gap-6 flex-md-nowrap flex-wrap">
                  <Link
                    to={ROUTES.ABOUT_US}
                    className="text-gray-300 text-primary-hover ms-md-auto"
                  >
                    About Us
                  </Link>
                  <Link
                    to={ROUTES.OUR_TEAM}
                    className="text-gray-300 text-primary-hover"
                  >
                    Our Team
                  </Link>
                  <Link
                    to={ROUTES.PROP_OFFERING}
                    className="text-gray-300 text-primary-hover"
                  >
                    Invest
                  </Link>
                  <Link
                    to={ROUTES.FAQ}
                    className="text-gray-300 text-primary-hover"
                  >
                    FAQ
                  </Link>
                  {/* <Link to="" className="text-gray-300 text-primary-hover">
                    Blog
                  </Link>
                  <Link to="" className="text-gray-300 text-primary-hover">
                    Podcast
                  </Link> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="border-gray-800 border-bottom">
          <Container className="py-7">
            <Row className="gy-6">
              <Col xl={4}>
                <div className="d-flex gap-3">
                  <a
                    href={INSTAGRAM_URL}
                    target="_blank"
                    className="d-flex align-items-center justify-content-center rounded-circle h-10 w-10 bg-gray-900"
                  >
                    <Image src={Instagram} width={14} height={18} />
                  </a>
                  <a
                    href={TWITTER_URL}
                    target="_blank"
                    className="d-flex align-items-center justify-content-center rounded-circle h-10 w-10 bg-gray-900"
                  >
                    <Image src={TwitterLight} width={14} height={18} />
                  </a>
                  <a
                    href={LINKEDIN_URL}
                    target="_blank"
                    className="d-flex align-items-center justify-content-center rounded-circle h-10 w-10 bg-gray-900"
                  >
                    <Image src={LinkedIn} width={24} height={18} />
                  </a>
                  <a
                    href={FACEBOOK_URL}
                    target="_blank"
                    className="d-flex align-items-center justify-content-center rounded-circle h-10 w-10 bg-gray-900"
                  >
                    <Image src={Facebook2} width={8} height={18} />
                  </a>
                  {/*<Link to="" className="d-flex align-items-center justify-content-center rounded-circle h-10 w-10 bg-gray-900">*/}
                  {/*  <Image src={Youtube} width={18} height={18} />*/}
                  {/*</Link>*/}
                  {/*<Link to="" className="d-flex align-items-center justify-content-center rounded-circle h-10 w-10 bg-gray-900">*/}
                  {/*  <Image src={Discord} width={20} height={15} />*/}
                  {/*</Link>*/}
                </div>
              </Col>
              <Col xl={8}>
                <div className="hstack gap-lg-12 gap-6 flex-md-nowrap flex-wrap">
                  <div className="hstack gap-2 align-items-start ms-xl-auto">
                    <Link
                      to=""
                      className="d-flex align-items-center justify-content-center rounded-circle h-10 w-10 bg-gray-900"
                    >
                      <MdOutlineCall className="text-light" />
                    </Link>
                    <div>
                      <p className="text-sm mb-1 text-gray-400">Talk to us</p>
                      <h5 className="text-base text-light">
                        +1 (604) 670-6150
                      </h5>
                    </div>
                  </div>
                  <div className="hstack gap-2 align-items-start">
                    <Link
                      to=""
                      className="d-flex align-items-center justify-content-center rounded-circle h-10 w-10 bg-gray-900"
                    >
                      <MdOutlineMail className="text-light" />
                    </Link>
                    <div>
                      <p className="text-sm mb-1 text-gray-400">
                        Send us an email
                      </p>
                      <h5 className="text-base text-light">
                        info@propXchange.ca
                      </h5>
                    </div>
                  </div>
                  <div className="hstack gap-2 align-items-start">
                    <Link
                      to=""
                      className="d-flex align-items-center justify-content-center rounded-circle h-10 w-10 bg-gray-900"
                    >
                      <MdOutlineLocationOn className="text-light" />
                    </Link>
                    <div>
                      <p className="text-sm mb-1 text-gray-400">Our location</p>
                      <h5 className="text-base text-light">
                        410 West Georgia Street, 3rd floor
                        <br />
                        Vancouver, BC V6B 1Z3
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="border-gray-800 border-bottom text-xs">
          <Container className="py-5">
            <Row className="gy-6 text-gray-400">
              <Col md={4}>
                <p>© 2024 propXchange. All rights reserved. </p>
              </Col>
              <Col md={8}>
                <div className="hstack gap-6 flex-md-nowrap flex-wrap">
                  <Link
                    to={ROUTES.PRIVACY_POLICY}
                    className="text-gray-400 text-primary-hover ms-md-auto"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    to={ROUTES.COOKIES_POLICY}
                    className="text-gray-400 text-primary-hover"
                  >
                    Use of Cookies
                  </Link>
                  <Link
                    to={ROUTES.TERMS_OF_USE}
                    className="text-gray-400 text-primary-hover"
                  >
                    Terms of Use
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Footer;
