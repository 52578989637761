import { Row, Col, Container, Spinner } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/pagination';
import { PropFilters } from '../PropFilters/PropFilters';
import { PropCard } from './Components/PropCard/PropCard';
import { useEffect, useState } from 'react';
import { getProperties } from '../../../../services/property/getProperties';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPropertiesSuccess } from '../../../../redux/slices/property/propertiesSlice';
import { RootState } from '../../../../redux/store/type';

export const PropList = ({
  isFilterOpen = false,
  setShowMenu,
  listingType = '',
}: {
  isFilterOpen: boolean;
  setShowMenu: any;
  listingType: string;
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const properties = useSelector((state: RootState) => {
    const list = state.properties.properties;
    return listingType === ''
      ? list
      : list.filter((p) => p.category.name === listingType); // TODO: change category.name to actual type after API gets updated
  });
  const isFavouriteTab = useSelector(
    (state: RootState) => state.properties.isFavouritesTab,
  );
  const favouriteCompanyIds = useSelector(
    (state: RootState) => state.auth.user?.favouriteCompanyIds,
  );

  // [TODO] - Remove this logic - seems like it's no longer required.
  // Test it before removing.
  const filteredProperties = isFavouriteTab
    ? properties?.filter((property) =>
        favouriteCompanyIds?.includes(property.id),
      )
    : properties;

  useEffect(() => {
    setLoading(true);
    getProperties()
      .then((res) => {
        setLoading(false);
        dispatch(fetchPropertiesSuccess({ properties: res }));
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Row className="justify-content-md-center py-8">
      <Col style={{ maxWidth: '68rem' }} xs={isFilterOpen ? 8 : 12}>
        <Container>
          <Row className="row-cols-lg-2 justify-content-md-center gy-4">
            {!loading ? (
              filteredProperties?.map((property, index) => (
                <Col>
                  <PropCard
                    key={'index' + index}
                    index={index}
                    property={property}
                  />
                </Col>
              ))
            ) : (
              <Spinner />
            )}
          </Row>
        </Container>
      </Col>
      {isFilterOpen && (
        <Col xs={4}>
          <PropFilters handleMenuToggle1={() => {}} setShowMenu={setShowMenu} />
        </Col>
      )}
    </Row>
  );
};
