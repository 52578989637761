import { Footer, Header } from '../../component';
import { Container } from 'react-bootstrap';
import {
  WealthCreator,
  MetroVillas,
  IncomeGenerator,
} from '../../assets/image';
import { TeamMemberCard } from './TeamMemberCard';
import './OurTeam.scss';

function OurTeam() {
  const teamMemebers = [
    {
      name: 'Kam Pawar',
      role: 'Position',
      image: MetroVillas,
      linkedin: 'https://linkedin.com',
    },
    {
      name: 'Kam Pawar',
      role: 'Position',
      image: IncomeGenerator,
      linkedin: 'https://linkedin.com',
    },
    {
      name: 'Kam Pawar',
      role: 'Position',
      image: WealthCreator,
      linkedin: 'https://linkedin.com',
    },
    {
      name: 'Kam Pawar',
      role: 'Position',
      image: MetroVillas,
      linkedin: 'https://linkedin.com',
    },
    {
      name: 'Kam Pawar',
      role: 'Position',
      image: IncomeGenerator,
      linkedin: 'https://linkedin.com',
    },
  ];

  const boardMembers = [
    {
      name: 'Kam Pawar',
      role: 'Position',
      image: MetroVillas,
      linkedin: 'https://linkedin.com',
    },
    {
      name: 'Kam Pawar',
      role: 'Position',
      image: IncomeGenerator,
      linkedin: 'https://linkedin.com',
    },
    {
      name: 'Kam Pawar',
      role: 'Position',
      image: WealthCreator,
      linkedin: 'https://linkedin.com',
    },
  ];

  return (
    <div>
      <Header />
      {/*<Container>*/}
      <div className="viewport-height py-5 px-5 px-md-18 pb-18">
        <div className="d-flex bg-light-blue rounded-5 flex-column px-10 py-16 p-sm-20 text-center align-items-center mb-8">
          <h1 className="mb-4 display-3 font-bold">
            <div className="font-light">Meet our</div>
            <span> Team Members</span>
          </h1>
          <div className="sub-title">
            Our team combines diverse skills and expertise to drive innovation
            and achieve excellence.
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center">
          {teamMemebers.map((m) => (
            <TeamMemberCard {...m} />
          ))}
        </div>
      </div>
      <div className="py-5 px-2 px-md-18 px-lg-18 pb-18">
        <div className="d-flex bg-light-blue rounded-5 flex-column px-10 py-16 p-sm-20 text-center align-items-center mb-8">
          <h1 className="mb-4 display-3 font-bold">
            <div className="font-light">Our</div>
            <span> Board of Advisors</span>
          </h1>
          <div className="sub-title">
            Our Board of Advisors brings unparalleled expertise and strategic
            vision to guide our journey.
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center pb-16">
          {boardMembers.map((m) => (
            <TeamMemberCard {...m} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default OurTeam;
