import { jwtDecode } from 'jwt-decode';

// get Authorization token
function getAccessToken(): string {
  const token: string = getToken();
  if (decodable(token)) {
    return token;
  } else {
    return '';
  }
}

function decodable(token: string) {
  try {
    const decoded: any = jwtDecode(token);
    if (decoded) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
}

function getToken(): string {
  // NOTE: Don't change the order!
  console.log(process.env.NODE_ENV);
  return localStorage.getItem('auth_token_key') as string;
}

export { getAccessToken };
