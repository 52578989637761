import { UPDATE_MY_PROFILE_API } from '../apiEndPoints';
import { axios } from '../../axios/AxiosConfig';
import { toast } from 'react-toastify';
import { User } from '../../redux/slices/auth/authSlice';
import { convertToFormData } from '../../utilities/helper';

type FilteredUser = Partial<
  Pick<
    User,
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'photo'
    | 'coverPhoto'
    | 'phoneNumber'
    | 'userLocation'
    | 'zipcode'
    | 'aboutYourself'
    | 'outsideLinks'
    | 'slug'
    | 'kycStatus'
    | 'kycReferenceId'
    | 'enableTwoFactorAuth'
    | 'enableNotification'
    | 'notificationLanguage'
    | 'stripeClientId'
    | 'favouriteCompanyIds'
    | 'isAcceptedTermAndCondition'
  >
>;
export const updateUserService = async (user: FilteredUser) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userFormData = convertToFormData(user);
      const response = await axios.patch(UPDATE_MY_PROFILE_API, userFormData);
      resolve(response?.data?.data);
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error);
      reject(error);
    }
  });
};
