import { Route, Routes } from 'react-router-dom';
import { Layout, PropertyInformation } from '../../pages';
import PropOffering from '../../pages/PropOffering/PropOffering';
import AuthGuard from '../AuthGuard/AuthGuard';
import WorkInProgress from '../../pages/WorkInProgress/WorkInProgress';
import Account from '../../pages/Account/Account';
import FavouritesProperties from '../../pages/Favourites/PropOffering';
import { Portfolio } from '../../pages/Portfolio/Portfolio';
import { Wallet } from '../../pages/Wallet/Wallet';
import { ROUTES } from '../routes';

export const PrivateRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route
          path="/property-information/:id"
          element={
            <AuthGuard>
              <PropertyInformation />
            </AuthGuard>
          }
        />
        <Route
          path={ROUTES.PROP_OFFERING}
          element={
            <AuthGuard>
              <PropOffering />
            </AuthGuard>
          }
        />
        <Route
          path={ROUTES.FAVOURITES}
          element={
            <AuthGuard>
              <FavouritesProperties />
            </AuthGuard>
          }
        />
        <Route
          path={ROUTES.ACCOUNT}
          element={
            <AuthGuard>
              <Account />
            </AuthGuard>
          }
        />
        <Route
          path={ROUTES.WALLET}
          element={
            <AuthGuard>
              <Wallet />
            </AuthGuard>
          }
        />
        {/* <Route
          path="/favourites"
          element={
            <AuthGuard>
              <WorkInProgress />
            </AuthGuard>
          }
        /> */}
        <Route
          path={ROUTES.PORTFOLIO}
          element={
            <AuthGuard>
              <Portfolio />
            </AuthGuard>
          }
        />
        <Route
          path="/messages"
          element={
            <AuthGuard>
              <WorkInProgress />
            </AuthGuard>
          }
        />
        <Route
          path={ROUTES.DASHBOARD}
          element={
            <AuthGuard>
              <WorkInProgress />
            </AuthGuard>
          }
        />
      </Routes>
    </Layout>
  );
};
