import { Modal, Button, Spinner, Form, InputGroup } from 'react-bootstrap';
import { useState } from 'react';
import { handleError } from '../../utilities/handleError';
import useInitiateIntercRequestForTopUp from '../../hooks/useInitiateInteracRequestForTopUp';
import { toast } from 'react-toastify';
import { Interac } from '../../assets/image';
import './TopUpWalletModal.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/type';

const TopUpWalletModal = ({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: any;
}) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [isInProgress, setIsInProgress] = useState(false);
  const [amount, setAmount] = useState(100);
  const { initiateRequest } = useInitiateIntercRequestForTopUp();

  const onSubmit = async () => {
    try {
      setIsInProgress(true);
      const response = await initiateRequest({
        amount: amount,
        currency: 'CAD',
      });
      toast.success(
        'Transfer request initiated. Refer to transaction history for updates.',
      );
      handleClose();
    } catch (error) {
      console.log('Error', error);
      handleError(error);
    } finally {
      setIsInProgress(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="modal-w"
      backdrop="static"
    >
      <Modal.Body className="p-8 pt-6">
        <p className="text-dark text-xl font-bold">ADD BALANCE TO WALLET</p>
        <div className="text-center">
          <img src={Interac} alt="img" className="w-13 py-8" />
        </div>
        {isInProgress ? (
          <div className="text-center">
            <Spinner />
            <p>Please wait while your request is being processed...</p>
          </div>
        ) : (
          <Form>
            <Form.Group
              controlId="amountInput"
              className="d-flex justify-content-between align-items-center"
            >
              <Form.Label className="m-0 text-dark font-bold">
                Add Amount (CAD):
              </Form.Label>
              <Form.Control
                value={amount}
                onChange={(e) => {
                  const input = e.target.value;
                  if (parseInt(input) > 0) {
                    const unit = parseInt(input);
                    setAmount(unit);
                  }
                }}
                inputMode="numeric"
                className="w-24"
                type="text"
                id="text"
              />
            </Form.Group>
          </Form>
        )}
        <div className="my-6">
          <p className="text-sm text-gray-500">Registered Id: </p>
          <p className="text-dark font-bold">{user?.email}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">How it works: </p>
          <ol className="text-xs text-dark ps-4">
            <li>Receive the request on your registered mail id.</li>
            <li>Approve the transfer.</li>
            <li>Check updated balance on your wallet.</li>
          </ol>
        </div>
        {!isInProgress && (
          <div className="d-flex">
            <Button
              onClick={handleClose}
              variant="outline-dark"
              className="rounded-pill text-sm py-3 px-4 flex-1 me-2"
              style={{ borderColor: '#bfb5b5' }}
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              variant="primary"
              className="rounded-pill btn btn-primary text-sm py-3 px-4 flex-1"
            >
              Get Request
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TopUpWalletModal;
