import { Card, Col, Row } from 'react-bootstrap';
import { Footer, Header } from '../../component';
import {
  AboutStar,
  About_1,
  About_2,
  About_3,
  About_4,
  About_5,
  About_6,
  About_7,
  OurMission,
} from '../../assets/image';
import BeginInvestingComponent from './BeginInvestingComponent';

const AboutUs = () => {
  return (
    <div>
      <Header />
      <div className="px-md-18 px-5 py-5">
        <BeginInvestingComponent />
        <Row className="px-md-32 section_p px-sm-0 py-sm-32">
          <Col lg={6}>
            <img src={OurMission} alt="" />
          </Col>
          <Col lg={6} className="my-auto">
            <h1 className="display-3 mb-3 font-bold mb-5">
              <span className="font-light pe-3">Our</span>
              Mission
            </h1>
            <p className="text-sm-lg text-dark font-semibold">
              To revolutionize real estate investment by providing a platform
              that is accessible, transparent, and beneficial for investors of
              all backgrounds. We aim to empower individuals by offering
              investment opportunities that were traditionally out of reach,
              helping them build wealth and secure their financial future.
            </p>
            {/*<button type="button" className="rounded-pill text-sm btn-xs font-semibold w-40 btn btn-dark">*/}
            {/*  Know More*/}
            {/*</button>*/}
          </Col>
        </Row>
        <div
          className="rounded-5 p-lg-32 px-md-26 py-md-26 py-16 px-5 mb-10"
          style={{ backgroundColor: '#F3F3E1' }}
        >
          <div className="d-flex justify-content-between align-items-center flex-wrap pb-14">
            <h1 className="display-3 font-bold">
              <span className="font-light">What's</span> most <br /> Important{' '}
              <span className="font-light">to Us</span>
            </h1>
            <img src={AboutStar} alt="img" className="w-64" />
          </div>
          <Row className="gap-5 justify-content-right align-items-center">
            <Col className="d-lg-flex flex-raw justify-content-end gap-2">
              <Card
                style={{ minWidth: '12rem' }}
                className="border w-lg-64 border-2 border-dark p-0 mb-4"
              >
                <Card.Body className="p-0">
                  <Card.Title
                    className="display-6 p-3 m-0"
                    style={{ fontSize: '1rem' }}
                  >
                    Inclusivity
                  </Card.Title>
                  <Card.Text
                    className="px-3 pb-3 font-light"
                    style={{ fontSize: '0.8rem' }}
                  >
                    Promoting diverse and equitable access to real estate
                    investments for all.
                  </Card.Text>
                </Card.Body>
                <Card.Img
                  src={About_1}
                  className="border-top border-2 border-dark rounded-top-0 rounded-bottom-2"
                />
              </Card>
              <Card
                style={{ minWidth: '12rem' }}
                className="border w-lg-64 border-2 border-dark p-0 mb-4"
              >
                <Card.Body className="p-0">
                  <Card.Title
                    className="display-6 p-3 m-0"
                    style={{ fontSize: '1rem' }}
                  >
                    Transparency
                  </Card.Title>
                  <Card.Text
                    className="px-3 pb-3 font-light"
                    style={{ fontSize: '0.8rem' }}
                  >
                    Ensuring clarity and openness in every interaction to
                    empower informed decisions.
                  </Card.Text>
                </Card.Body>
                <Card.Img
                  src={About_2}
                  className="border-top border-2 border-dark rounded-top-0 rounded-bottom-2"
                />
              </Card>
              <Card
                style={{ minWidth: '12rem' }}
                className="border w-lg-64 border-2 border-dark p-0 mb-4"
              >
                <Card.Body className="p-0">
                  <Card.Title
                    className="display-6 p-3 m-0"
                    style={{ fontSize: '1rem' }}
                  >
                    Community
                  </Card.Title>
                  <Card.Text
                    className="px-3 pb-3 font-light"
                    style={{ fontSize: '0.8rem' }}
                  >
                    Cultivating a supportive network for learning, growth, and
                    shared experiences.
                  </Card.Text>
                </Card.Body>
                <Card.Img
                  src={About_3}
                  className="border-top border-2 border-dark rounded-top-0 rounded-bottom-2"
                />
              </Card>
            </Col>
            <Col className="d-lg-flex flex-raw justify-content-end gap-2">
              <Card
                style={{ minWidth: '12rem' }}
                className="border w-lg-64 border-2 border-dark p-0 mb-4"
              >
                <Card.Body className="p-0">
                  <Card.Title
                    className="display-6 p-3 m-0"
                    style={{ fontSize: '1rem' }}
                  >
                    Empowerment
                  </Card.Title>
                  <Card.Text
                    className="px-3 pb-3 font-light"
                    style={{ fontSize: '0.8rem' }}
                  >
                    Providing the tools and opportunities for individuals to
                    take charge of their financial futures.
                  </Card.Text>
                </Card.Body>
                <Card.Img
                  src={About_4}
                  className="border-top border-2 border-dark rounded-top-0 rounded-bottom-2"
                />
              </Card>
              <Card
                style={{ minWidth: '12rem' }}
                className="border w-lg-64 border-2 border-dark p-0 mb-4"
              >
                <Card.Body className="p-0">
                  <Card.Title
                    className="display-6 p-3 m-0"
                    style={{ fontSize: '1rem' }}
                  >
                    Accountability
                  </Card.Title>
                  <Card.Text
                    className="px-3 pb-3 font-light"
                    style={{ fontSize: '0.8rem' }}
                  >
                    Maintaining the highest standards of integrity and
                    responsibility in all our dealings.
                  </Card.Text>
                </Card.Body>
                <Card.Img
                  src={About_5}
                  className="border-top border-2 border-dark rounded-top-0 rounded-bottom-2"
                />
              </Card>
              <Card
                style={{ minWidth: '12rem' }}
                className="border w-lg-64 border-2 border-dark p-0 mb-4"
              >
                <Card.Body className="p-0">
                  <Card.Title
                    className="display-6 p-3 m-0"
                    style={{ fontSize: '1rem' }}
                  >
                    Partnership
                  </Card.Title>
                  <Card.Text
                    className="px-3 pb-3 font-light"
                    style={{ fontSize: '0.8rem' }}
                  >
                    Valuing strong, enduring relationships with each of our
                    investors as true partners.
                  </Card.Text>
                </Card.Body>

                <img
                  src={About_6}
                  className="border-top border-2 border-dark rounded-top-0 rounded-bottom-2"
                  alt="Your Alt Text"
                />
              </Card>
              <Card
                style={{ minWidth: '12rem' }}
                className="border w-lg-64 border-2 border-dark p-0 mb-4"
              >
                <Card.Body className="p-0">
                  <Card.Title
                    className="display-6 p-3 m-0"
                    style={{ fontSize: '1rem' }}
                  >
                    Education
                  </Card.Title>
                  <Card.Text
                    className="px-3 pb-3 font-light"
                    style={{ fontSize: '0.8rem' }}
                  >
                    Dedicated to enhancing investor knowledge through continuous
                    learning and resources.
                  </Card.Text>
                </Card.Body>
                <img
                  src={About_7}
                  className="border-top border-2 border-dark rounded-top-0 rounded-bottom-2"
                  alt="Your Alt Text"
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
