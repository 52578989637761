import { Navbar, Dropdown, Button, Container, Image } from 'react-bootstrap';
import { AiOutlineBell, AiOutlineQuestionCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/type';
import { getInitials } from '../../utilities/helper';
import { logoutService } from '../../services/auth/logoutService';
import { Logo } from '../../assets/image';
import './header-style.scss';

function HeaderDark({ children, takeMobileChildren = false }) {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);

  const handleLogout = () => {
    logoutService().then((res: any) => {
      if (res?.success) {
        navigate('/login');
      }
    });
  };

  return (
    <Navbar
      variant="light"
      fixed="top"
      className="bg-light py-3 px-0  d-flex  align-items-center position-sticky top-0 overlap-2000 border-bottom"
    >
      <Container fluid className="d-md-block d-none">
        {children}
      </Container>
      <Container fluid className="d-md-none d-block">
        {takeMobileChildren ? (
          children
        ) : (
          <Navbar.Brand onClick={() => navigate('/')}>
            <Image
              alt=""
              src={Logo}
              width="160"
              style={{ maxWidth: '160px', cursor: 'pointer' }}
            />
          </Navbar.Brand>
        )}
      </Container>
      <Container>
        <div className="py-0 ms-auto d-flex gap-4">
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              className="rounded-circle h-11 w-11 p-0 vstack justify-content-center header-button"
            >
              <AiOutlineBell className="text-lg" />
            </Dropdown.Toggle>
            {/* <Dropdown.Menu className="">
              <p className="text-lg ">Connect with us on Social Media</p>
            </Dropdown.Menu> */}
          </Dropdown>
          <Button
            onClick={() => {}}
            variant="light"
            className="rounded-pill btn-md text-sm font-semibold w-full px-4 py-2 bg-gray-200 d-md-block d-none"
          >
            <AiOutlineQuestionCircle className="text-xl" /> Need Help
          </Button>

          {/* profile menu */}
          <Dropdown className="d-md-block d-none">
            <Dropdown.Toggle
              className="rounded-circle h-11 w-11 p-0 vstack justify-content-center profile-button header-button"
              id="dropdown-basic"
            >
              <div
                className="profile-initials rounded-circle d-flex align-items-center justify-content-center"
                style={{
                  minWidth: '40px',
                  height: '40px',
                  fontSize: '20px',
                  color: 'gray',
                  fontWeight: 'normal',
                }}
              >
                {getInitials(user?.firstName, user?.lastName)}
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="profile-menu right-corner-menu">
              <Dropdown.Item
                className="font-bold"
                style={{ color: 'red' }}
                onClick={handleLogout}
              >
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Container>
    </Navbar>
  );
}

export default HeaderDark;
