import { useState } from 'react';
import { Container, Row, Col, Card, Button, Tab, Tabs } from 'react-bootstrap';
import './Account.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store/type';
import { getInitials } from '../../utilities/helper';
import { HeaderDark } from '../../component';
import { KYCComponent } from './components/KYCComponent';
import BottomNavbar from '../../component/BottomNavbar/BottomNavbar';
import { setSelectedTab } from '../../redux/slices/sideMenu/sideMenuSlice';
import { WalletBalanceComponent } from './components/WalletBalanceComponent';
import { AddBalanceOrSetupWallet } from './components/AddBalanceOrSetupWallet';
import { formatMobileNumber } from '../util';

const Account = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [isEditName, setIsEditName] = useState(false);

  const dispatch = useDispatch();
  dispatch(setSelectedTab({ selectedTab: 'Account' }));

  return (
    <div className="account-page pb-14">
      <HeaderDark>
        <h5 className="font-bolder mx-4">Account</h5>
      </HeaderDark>
      <Container className="d-flex justify-content-center">
        <Row
          className="justify-content-center align-item-center"
          style={{ maxWidth: '520px', width: '100%' }}
        >
          <Col className="my-5">
            <Card className="border-0 p-4">
              <div className="d-block d-md-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center flex-column flex-md-row text-center">
                  <div
                    className="profile-initials rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      border: '2px dotted gray',
                      color: 'gray',
                      fontWeight: 'normal',
                      borderRadius: '50%',
                    }}
                  >
                    {getInitials(user?.firstName, user?.lastName)}
                  </div>
                  <div className="text-center d-flex flex-column mx-6 justify-content-center mt-2 mt-md-0">
                    <h6>
                      {user?.firstName} {user?.lastName}
                    </h6>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center text-center mt-4 mt-md-0">
                  <Button
                    onClick={() => setIsEditName(true)}
                    variant="outline-primary"
                    className="rounded-6 py-1 px-4 text-sm"
                    style={{ borderColor: '#bfb5b5' }}
                  >
                    Edit Info
                  </Button>
                </div>
              </div>
              <hr />
              <Row className="justify-content-between align-items-center">
                <Col className="d-flex flex-column">
                  <WalletBalanceComponent />
                </Col>
                <Col className="d-flex justify-content-end">
                  <AddBalanceOrSetupWallet />
                </Col>
              </Row>
              <hr />
              <Row className="justify-content-between align-items-start">
                <Col className="d-flex flex-column">
                  <div className="text-muted text-sm">KYC Verification</div>
                </Col>
                <Col className="d-flex justify-content-end">
                  <KYCComponent />
                </Col>
              </Row>
              <hr />
              {/*<Row className="mb-4">*/}
              {/*  <Col xs={10} md={8}>*/}
              {/*    <div className={"form-floating"}>*/}
              {/*      <input className="form-control" id="emailInput" defaultValue={user?.email}/>*/}
              {/*      <label htmlFor="emailInput">Email Address</label>*/}
              {/*    </div>*/}
              {/*  </Col>*/}
              {/*  <Col className="d-flex justify-content-end align-items-center">*/}
              {/*    <FaEdit/>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <Row className="mb-4">
                <Col xs={10} md={8}>
                  <div className="text-muted text-xs">Email Address</div>
                  <div className="w-full font-bold">{user?.email}</div>
                </Col>
                {/*<Col className="d-flex justify-content-end align-items-center">*/}
                {/*  <FaEdit/>*/}
                {/*</Col>*/}
              </Row>
              <Row className="mb-2">
                <Col>
                  <div className="text-muted text-xs">Phone Number</div>
                  <div className="w-full font-bold">
                    {formatMobileNumber(user?.phoneNumber as string)}
                  </div>
                </Col>
                {/*<Col className="d-flex justify-content-end align-items-center">*/}
                {/*  <FaEdit/>*/}
                {/*</Col>*/}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      <BottomNavbar />
    </div>
  );
};

export default Account;
