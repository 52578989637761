import { Header, Footer } from '../../component';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  CardBody,
  Image,
} from 'react-bootstrap';
import {
  AssetBuilder,
  GetNotified1,
  GetNotified2,
  IncomeGenerator,
  SliderVilla,
  WealthCreator,
} from '../../assets/image';
import { IoCheckmarkCircle } from 'react-icons/io5';
import './Home.scss';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TopSection from './TopSection';
import { ROUTES } from '../../routes/routes';
import { useTranslation } from 'react-i18next';
import SubscribeForm from './SubscribeForm';

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const targetRef = useRef<any>(null);

  const scrollToTarget = () => {
    targetRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <Header />
      <TopSection scrollToTarget={scrollToTarget} />

      <Container ref={targetRef} className="mb-lg-16 mb-10 px-md-0 px-7">
        <Card className="mx-md-14 rounded-5">
          <Row className="overflow-hidden rounded-md-0 rounded-5">
            <Col
              md={6}
              className="bg-dark-green rounded-start-md-5 px-0 d-flex align-items-center"
            >
              <Row className="w-fill-available my-18 my-md-0">
                <Col xs className="text-end pe-0">
                  <div className="image-wrapper">
                    <Image
                      src={SliderVilla}
                      className="side-image rounded-end-5"
                    />
                    <div
                      className="position-absolute top-5 end-5 bg-white rounded-5 text-dark px-3"
                      style={{ fontSize: 'x-small' }}
                    >
                      Villa
                    </div>
                  </div>
                </Col>
                <Col xs={10} className="text-center">
                  <div className="mx-5 position-relative">
                    <Image
                      src={IncomeGenerator}
                      className="center-image rounded-5"
                      style={{ borderBottom: '6px solid white !important' }}
                    />
                    <div
                      className="position-absolute top-5 end-5 bg-white rounded-5 text-dark px-3"
                      style={{ fontSize: 'x-small' }}
                    >
                      Strip Mall
                    </div>
                  </div>
                </Col>
                <Col xs className="text-start ps-0 h-fill-available">
                  <div className="image-wrapper">
                    <Image
                      src={WealthCreator}
                      className="side-image rounded-start-5"
                    />
                    {/* <div className="position-absolute top-5 end-5 bg-white rounded-5 text-dark px-3" style={{fontSize:"x-small"}} >
                          Condo
                    </div> */}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              md={6}
              className="bg-light-green rounded-end-md-5 br-end-20 px-md-20 py-md-24 px-5 py-10"
            >
              <div className="display-6 pb-8 pb-md-16 text-dark">
                {t('home.income_generator.title')}
              </div>
              <div className="mb-8 mb-md-16" style={{ maxWidth: '615px' }}>
                <ul className="gap-4 mb-8 mb-md-16">
                  <li className="h6">Focus:</li>
                  <p className="mb-10">{t('home.income_generator.focus')}</p>
                  <li className="h6">Ideal for:</li>
                  <p>{t('home.income_generator.ideal_for')}</p>
                </ul>

                <ul style={{ listStyleType: 'none', padding: '0px' }}>
                  <li className="mb-2 d-flex">
                    <div className="d-flex align-items-top">
                      <IoCheckmarkCircle
                        className="text-dark me-2"
                        style={{ fontSize: '1.5rem' }}
                      />
                    </div>
                    <div>
                      <span className="h6">Low minimum investments:</span> Start
                      as low as CA $10.
                    </div>
                  </li>
                  <li className="mb-2 d-flex">
                    <div className="d-flex align-items-top">
                      <IoCheckmarkCircle
                        className="text-dark me-2"
                        style={{ fontSize: '1.5rem' }}
                      />
                    </div>
                    <div>
                      <span className="h6"> Passive income:</span> Earn rental
                      income without the hassle of managing properties.
                    </div>
                  </li>
                  <li className="d-flex">
                    <div className="d-flex align-items-top">
                      <IoCheckmarkCircle
                        className="text-dark me-2"
                        style={{ fontSize: '1.5rem' }}
                      />
                    </div>
                    <div>
                      <span className="h6">Diversification:</span> Spread your
                      investment across multiple projects to reduce risk.
                    </div>
                  </li>
                </ul>
              </div>
              <Row className="mx-4 mx-md-0">
                <Col md={7} className="d-flex justify-content-between">
                  <div className="me-4">
                    <h2>Open</h2>
                    <p style={{ color: '#726f6f' }}>Investment Tenure</p>
                  </div>
                </Col>
                <Col
                  md={5}
                  className="d-flex mt-md-0 mt-6 justify-content-md-end justify-content-center"
                >
                  <Button
                    variant="dark"
                    onClick={() => navigate(ROUTES.PROP_OFFERING)}
                    className="h-12 rounded-pill px-10"
                  >
                    {t('home.invest_now')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>

      <Container className="mb-lg-16 mb-10 px-md-0 px-7">
        <Card className="mx-md-14 rounded-5">
          <Row className="overflow-hidden flex-md-row flex-column-reverse rounded-md-0 rounded-5">
            <Col
              md={6}
              className="bg-light-purple rounded-start-md-5 px-md-20 py-md-24 px-5 py-10"
            >
              <div className="display-6 pb-8 pb-md-16 text-dark">
                Wealth Creator
              </div>
              <div className="mb-8 mb-md-16" style={{ maxWidth: '615px' }}>
                <ul className="gap-4 mb-8 mb-md-16">
                  <li className="h6">Focus:</li>
                  <p className="mb-10">Grow your wealth over time.</p>
                  <li className="h6">Ideal for:</li>
                  <p>
                    Investors with a long-term investment horizon aiming for
                    significant capital appreciation.
                  </p>
                </ul>

                <ul style={{ listStyleType: 'none', padding: '0px' }}>
                  <li className="mb-2 d-flex">
                    <div className="d-flex align-items-top">
                      <IoCheckmarkCircle
                        className="text-dark me-2"
                        style={{ fontSize: '1.5rem' }}
                      />
                    </div>
                    <div>
                      <span className="h6">
                        {' '}
                        Access high-potential projects:
                      </span>{' '}
                      Invest in carefully selected real estate projects with the
                      potential for high returns.
                    </div>
                  </li>
                  <li className="mb-2 d-flex">
                    <div className="d-flex align-items-top">
                      <IoCheckmarkCircle
                        className="text-dark me-2"
                        style={{ fontSize: '1.5rem' }}
                      />
                    </div>
                    <div>
                      <span className="h6"> Strategic investing:</span> Leverage
                      professional expertise to maximize your returns.
                    </div>
                  </li>
                  <li className="d-flex">
                    <div className="d-flex align-items-top">
                      <IoCheckmarkCircle
                        className="text-dark me-2"
                        style={{ fontSize: '1.5rem' }}
                      />
                    </div>
                    <div>
                      <span className="h6">Build long-term wealth:</span> Enjoy
                      potential capital appreciation over time.
                    </div>
                  </li>
                </ul>
              </div>
              <Row className="mx-4 mx-md-0">
                <Col md={7} className="d-flex justify-content-between">
                  <div className="me-4">
                    <h2>3-4 Yrs</h2>
                    <p style={{ color: '#726f6f' }}>Investment Tenure</p>
                  </div>
                </Col>
                <Col
                  md={5}
                  className="d-flex mt-md-0 mt-6 justify-content-md-end justify-content-center"
                >
                  <Button
                    onClick={() => navigate(ROUTES.PROP_OFFERING)}
                    variant="dark"
                    className="h-12 rounded-pill px-10"
                  >
                    {t('home.invest_now')}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col
              md={6}
              className="bg-dark-purple rounded-end-md-5 px-0 d-flex align-items-center"
            >
              <Row className="w-fill-available my-18 my-md-0">
                <Col xs className="text-end pe-0">
                  <div className="image-wrapper">
                    <Image
                      src={IncomeGenerator}
                      className="side-image rounded-end-5"
                    />
                    <div
                      className="position-absolute top-5 end-5 bg-white rounded-5 text-dark px-3"
                      style={{ fontSize: 'x-small' }}
                    >
                      Split Mall
                    </div>
                  </div>
                </Col>
                <Col xs={10} className="text-center">
                  <div className="mx-5 position-relative">
                    <Image
                      src={WealthCreator}
                      className="center-image rounded-5"
                      style={{ borderBottom: '6px solid white !important' }}
                    />
                    <div
                      className="position-absolute top-5 end-5 bg-white rounded-5 text-dark px-3"
                      style={{ fontSize: 'x-small' }}
                    >
                      Condo
                    </div>
                  </div>
                </Col>
                <Col xs className="text-start ps-0">
                  <div className="image-wrapper">
                    <Image
                      src={AssetBuilder}
                      className="side-image rounded-start-5"
                    />
                    {/* <div className="position-absolute top-5 end-5 bg-white rounded-5 text-dark px-3" style={{fontSize:"x-small"}} >
                          Land
                    </div> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>

      <Container className="mb-lg-16 mb-10 px-md-0 px-7">
        <Card className="mx-md-14 rounded-5">
          <Row className="overflow-hidden rounded-md-0 rounded-5">
            <Col
              md={6}
              className="bg-dark-blue rounded-start-md-5 px-0 d-flex align-items-center"
            >
              <Row className="w-fill-available my-18 my-md-0">
                <Col xs className="text-end pe-0">
                  <div className="image-wrapper">
                    <Image
                      src={SliderVilla}
                      className="side-image rounded-end-5"
                    />
                    <div
                      className="position-absolute top-5 end-5 bg-white rounded-5 text-dark px-3"
                      style={{ fontSize: 'x-small' }}
                    >
                      Villa
                    </div>
                  </div>
                </Col>
                <Col xs={10} className="text-center">
                  <div className="mx-5 position-relative">
                    <Image
                      src={AssetBuilder}
                      className="center-image rounded-5"
                      style={{ borderBottom: '6px solid white !important' }}
                    />
                    <div
                      className="position-absolute top-5 end-5 bg-white rounded-5 text-dark px-3"
                      style={{ fontSize: 'x-small' }}
                    >
                      Land
                    </div>
                  </div>
                </Col>
                <Col xs className="text-start ps-0">
                  <div className="image-wrapper">
                    <Image
                      src={WealthCreator}
                      className="side-image rounded-start-5"
                    />
                    {/* <div className="position-absolute top-5 end-5 bg-white rounded-5 text-dark px-3" style={{fontSize:"x-small"}} >
                          Condo
                    </div> */}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              md={6}
              className="bg-light-blue rounded-end-md-5  text-dark px-md-20 py-md-24 px-5 py-10"
            >
              <div className="display-6 pb-8 pb-md-16 text-dark">
                Asset Builder
              </div>
              <div className="mb-8 mb-md-16" style={{ maxWidth: '615px' }}>
                <ul className="gap-4 mb-8 mb-md-16">
                  <li className="h6">Focus:</li>
                  <p className="mb-10">
                    Build a diversified portfolio of assets.
                  </p>
                  <li className="h6">Ideal for:</li>
                  <p>Investors seeking a balanced approach to build wealth.</p>
                </ul>

                <ul style={{ listStyleType: 'none', padding: '0px' }}>
                  <li className="mb-2 d-flex">
                    <div className="d-flex align-items-top">
                      <IoCheckmarkCircle
                        className="text-dark me-2"
                        style={{ fontSize: '1.5rem' }}
                      />
                    </div>
                    <div>
                      <span className="h6">Pool resources:</span> Invest in
                      larger projects alongside other investors.
                    </div>
                  </li>
                  <li className="mb-2 d-flex">
                    <div className="d-flex align-items-top">
                      <IoCheckmarkCircle
                        className="text-dark me-2"
                        style={{ fontSize: '1.5rem' }}
                      />
                    </div>
                    <div>
                      <span className="h6"> Diversification:</span> Spread your
                      investment across different locations to mitigate risk.
                    </div>
                  </li>
                  <li className="d-flex">
                    <div className="d-flex align-items-top">
                      <IoCheckmarkCircle
                        className="text-dark me-2"
                        style={{ fontSize: '1.5rem' }}
                      />
                    </div>
                    <div>
                      <span className="h6">Long-term growth: </span> Own a piece
                      of real estate with sustained increase in value.
                    </div>
                  </li>
                </ul>
              </div>
              <Row className="mx-4 mx-md-0">
                <Col md={7} className="d-flex justify-content-between">
                  <div className="me-4">
                    <h2>4-7 Years</h2>
                    <p style={{ color: '#726f6f' }}>Investment Tenure</p>
                  </div>
                </Col>
                <Col
                  md={5}
                  className="d-flex mt-md-0 mt-6 justify-content-md-end justify-content-center"
                >
                  <Button
                    onClick={() => navigate(ROUTES.PROP_OFFERING)}
                    variant="dark"
                    className="h-12 rounded-pill px-10"
                  >
                    {t('home.invest_now')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>

      <Container className="mb-lg-16 mb-10">
        <Card className="bg-iceberg mx-md-14 rounded-5">
          <CardBody className="py-20 position-relative ">
            <Image
              src={GetNotified1}
              className="position-absolute bottom-12 start-32"
            />
            <Image
              src={GetNotified2}
              className="position-absolute end-24 top-26"
            />
            <div className="max-w-screen-590 mx-auto position-relative overlap-40">
              <h2 className="display-2 text-center">
                <span className="font-light">Get</span> Notified
              </h2>
              <p className="text-center mb-16 text-lg text-dark">
                Stay informed and be the first to know about our latest property
                releases.
              </p>
              <SubscribeForm />
            </div>
          </CardBody>
        </Card>
      </Container>
      <Footer />
    </div>
  );
}

export default Home;
