import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  ProgressBar,
  Image,
} from 'react-bootstrap';
import { BsArrowUpCircleFill } from 'react-icons/bs';
import {
  IoMdStarOutline,
  IoIosInformationCircleOutline,
  IoMdStar,
} from 'react-icons/io';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { Doc } from '../../../../../../services/property/types';
import { selectProperty } from '../../../../../../redux/slices/property/propertiesSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserService } from '../../../../../../services/user/updateUserService';
import { RootState } from '../../../../../../redux/store/type';
import { updateUserReducer } from '../../../../../../redux/slices/auth/authSlice';
import { toast } from 'react-toastify';
import { formatAmount } from '../../../../../util';
import { REACT_APP_AWS_URL } from '../../../../../../utilities/envConstants';

export const PropCard = ({
  property,
  index,
}: {
  property: Doc;
  index: number;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const favouriteCompanyIds = useSelector(
    (state: RootState) => state.auth.user?.favouriteCompanyIds,
  );
  const showPropertyDetails = (indexOfProperty, id) => {
    dispatch(selectProperty({ index: indexOfProperty }));
    navigate('/property-information/' + id);
  };
  const toggleFavourite = async () => {
    try {
      let companyIds: string[];
      const propertyId = property?.id as string;

      if (favouriteCompanyIds?.includes(propertyId)) {
        // If the propertyId is already in the array, remove it
        companyIds = favouriteCompanyIds.filter((id) => id !== propertyId);
      } else {
        // If the propertyId is not in the array, add it
        companyIds = [...(favouriteCompanyIds || []), propertyId];
      }

      const response = await updateUserService({
        favouriteCompanyIds: companyIds,
      });
      if (response) {
        toast.success(
          favouriteCompanyIds?.includes(propertyId)
            ? 'Property removed from Favourites'
            : 'Property added to Favourites',
        );
        dispatch(
          updateUserReducer({ user: { favouriteCompanyIds: companyIds } }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isFavourite = favouriteCompanyIds?.includes(property.id);
  return (
    <Card
      style={{
        border: '2px solid #E7EAEC',
        borderRadius: '16px',
        overflow: 'hidden',
      }}
    >
      <CardBody className="p-0">
        <Swiper
          pagination={true}
          modules={[Pagination]}
          className="prop-offering-slider"
        >
          <SwiperSlide>
            <div className="position-relative">
              <Image
                className="w-full height-336 object-cover"
                src={`${REACT_APP_AWS_URL}/${property?.campaignImageURL}`}
                style={{ borderRadius: 'inherit' }}
              />
              {/* <div className="position-absolute top-0 end-0 bg-light px-2 py-1 rounded-pill m-4">Strip Mall</div> */}
            </div>
          </SwiperSlide>
        </Swiper>
      </CardBody>
      <CardBody className="px-5 py-2">
        <Row className="align-items-center gy-4">
          <Col>
            <h6 className="text-sm">{property?.companyId?.companyName}</h6>
            <p className="text-xs">{property?.companyId?.address}</p>
          </Col>
          <Col>
            <div className="hstack gap-2">
              <p className="text-dark ms-auto">
                <span className="font-bold">
                  {formatAmount(property?.pricePerShare)}
                </span>
                /Unit
              </p>
              <Button
                onClick={() => toggleFavourite()}
                variant=""
                className="btn-square rounded-circle border w-8 h-8"
              >
                {isFavourite ? (
                  <IoMdStar className="text-md text-warning" /> // Golden filled star
                ) : (
                  <IoMdStarOutline className="text-md text-dark" /> // Outline star
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardBody className="p-0">
        <Row className="align-items-center gx-0 gy-0">
          <Col>
            <div className="border-end border-top border-bottom py-2 px-5">
              <div className="hstack">
                <p className="text-xs">IRR</p>
                <IoIosInformationCircleOutline className=" ms-1 text-base" />
              </div>
              <h5 className="text-xs">{property?.annualRateOfReturn}%</h5>
            </div>
          </Col>
          <Col>
            <div className="border-top border-bottom py-2 px-5">
              <p className="text-xs">Prop Units Available</p>
              <h5 className="text-xs">{property?.availableShares}</h5>
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardBody className="px-5 py-4">
        <Row className="align-items-center gy-3">
          <Col sm={4}>
            <div className="hstack mb-1">
              <h6>{property?.percentageCompleted}%</h6>
              <div className="hstack ms-auto">
                <BsArrowUpCircleFill className="me-1" />
                <p>Raised</p>
              </div>
            </div>
            <ProgressBar
              variant="success"
              now={property?.percentageCompleted}
            />
          </Col>
          <Col sm={8}>
            <div className="hstack gap-2">
              <Button
                variant="outline-dark border-gray text-xs py-2 px-4 ms-sm-auto w-sm-auto w-full"
                className="rounded-pill"
              >
                Add to Watchlist
              </Button>
              <Button
                onClick={() => showPropertyDetails(index, property?.id)}
                variant="primary"
                className="rounded-pill btn btn-primary text-xs py-2 px-4 w-sm-auto w-full"
              >
                View Details
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
