import { toast } from 'react-toastify';
import { axios } from '../../axios/AxiosConfig';
import { SEARCH_API } from '../apiEndPoints';
import { Doc } from './types';

export const getProperties = async (): Promise<Doc[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(SEARCH_API);
      resolve(response.data?.data?.data?.docs);
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error);
      reject(error);
    }
  });
};
