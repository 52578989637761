import { VERIFY_PROFILE_API } from '../apiEndPoints';
import { axios } from '../../axios/AxiosConfig';
import { handleError } from '../../utilities/handleError';

export const verifyEmailService = async ({
  token,
  userId,
}: {
  token: string;
  userId: string;
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        VERIFY_PROFILE_API + `${userId}/${token}`,
      );
      resolve({ success: response?.data?.data?.user?.active });
    } catch (error: any) {
      handleError(error);
      console.log(error);
      reject(error);
    }
  });
};
