import { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Spinner,
} from 'react-bootstrap';
import { Header } from '../../component';
import { FcGoogle } from 'react-icons/fc';
import { ErrorFill } from '../../assets/image';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { loginService } from '../../services/auth';
import { currentUserService } from '../../services/user/currentUserService';
import { useDispatch } from 'react-redux';
import { setUserReducer } from '../../redux/slices/auth/authSlice';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import { getUserResponseSQService } from '../../services/suitabilityQuestionnaire/userRSQ/getUserResponseSQService';
import { ROUTES } from '../../routes/routes';
import { useTranslation } from 'react-i18next';
function Login() {
  const { t } = useTranslation();
  const [eye, setEye] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: '',
  });
  const [userCredentialsError, setUserCredentialsError] = useState({
    email: false,
    password: false,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Eye = () => {
    setEye(!eye);
  };
  const isError =
    userCredentialsError?.password ||
    userCredentialsError?.email ||
    userCredentials?.password === '' ||
    userCredentials?.email === '';

  const login = () => {
    if (userCredentialsError?.password && userCredentialsError?.email) return;
    setLoading(true);
    loginService({
      email: userCredentials.email,
      password: userCredentials.password,
    })
      .then((result) => {
        if (result) {
          currentUserService().then((result: any) => {
            if (result) {
              dispatch(setUserReducer({ user: result }));
            }
          });
          getUserResponseSQService()
            .then((result: any) => {
              setLoading(false);
              if (result && result?.length > 0) navigate(ROUTES.PROP_OFFERING);
              else {
                navigate(ROUTES.SUITABILITY_QUESTIONNAIRE);
              }
            })
            .catch((err) => {
              setLoading(false);
              throw err;
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const isValidEmail = (email: string): boolean => {
    // Regular expression for validating an email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  return (
    <div className="min-h-screeen vstack">
      <Header />
      <div className="vstack login-h-full login-background background-no-repeat bg-center bg-cover">
        <Container className="vstack">
          <div className="max-w-screen-300 m-auto mb-0 w-full pb-4 ">
            <h2 className="display-6 text-center mb-1 font-bolder">Login</h2>
            <p className="text-center text-dark mb-12">
              Please authenticate and proceed...
            </p>
            <Form>
              <div className="vstack gap-3 mb-10">
                <Form.Group
                  controlId="floatingInput"
                  className="form-floating mb-1"
                >
                  <Form.Control
                    type="text"
                    placeholder="name@example.com"
                    value={userCredentials?.email}
                    className={
                      userCredentialsError.email
                        ? 'invalid-border invalid-border-shadow'
                        : 'form-control'
                    }
                    onChange={(event) => {
                      setUserCredentials((prev) => ({
                        ...prev,
                        email: event?.target?.value,
                      }));
                    }}
                    onBlur={(event) => {
                      if (
                        !event?.target?.value ||
                        event?.target?.value === '' ||
                        !isValidEmail(event?.target?.value)
                      )
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          email: true,
                        }));
                      else
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          email: false,
                        }));
                    }}
                  />
                  <Form.Label className="font-lighter">
                    Email Address*
                  </Form.Label>
                  {userCredentialsError.email && (
                    <p className="invalid-color mt-1 d-flex align-item-center">
                      <Image src={ErrorFill} className="mx-2" />
                      Please enter valid email address
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="position-relative form-floating">
                  <Form.Control
                    type={eye ? 'text' : 'password'}
                    className={
                      userCredentialsError.password
                        ? 'invalid-border invalid-border-shadow form-control'
                        : 'form-control'
                    }
                    placeholder=""
                    name="password"
                    value={userCredentials?.password}
                    onChange={(event) => {
                      setUserCredentials((prev) => ({
                        ...prev,
                        password: event?.target?.value,
                      }));
                    }}
                    onBlur={(event) => {
                      if (!event?.target?.value || event?.target?.value === '')
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          password: true,
                        }));
                      else
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          password: false,
                        }));
                    }}
                  />
                  {!eye ? (
                    <FaRegEye onClick={Eye} className="fa-eye" />
                  ) : (
                    <FaRegEyeSlash onClick={Eye} className="fa-eye" />
                  )}
                  <Form.Label className="font-lighter">Password*</Form.Label>
                  {userCredentialsError.password && (
                    <p className="invalid-color mt-1 d-flex align-item-center">
                      <Image src={ErrorFill} className="mx-2" />
                      {t('login.invalid_password')}
                    </p>
                  )}
                </Form.Group>
                <Link
                  to="/forgot_password"
                  className="font-semibold text-dark underline-text text-end"
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="text-center">
                <Button
                  disabled={isError || loading}
                  onClick={() => login()}
                  variant="primary"
                  className="rounded-pill py-4 w-full mb-8 font-semibold text-sm"
                >
                  {loading ? (
                    <span>
                      <Spinner animation="border" size="sm" />
                    </span>
                  ) : (
                    'Login'
                  )}
                </Button>
                <div className="position-relative  font-semibold  hstack justify-content-center mb-6">
                  <span className="px-2 bg-light position-relative overlap-20">
                    Or continue with
                  </span>
                  <div className="border-bottom border-2 w-full position-absolute"></div>
                </div>
              </div>
              <Row className="mb-8 gy-6 gx-3">
                <Col md={12}>
                  <Button
                    disabled
                    variant=""
                    className="w-full rounded-pill text-sm border text-gray btn-lg font-semibold"
                  >
                    <FcGoogle className="me-2 text-lg" />
                    Google
                  </Button>
                </Col>
                {/* <Col md={6}>
                  <Button disabled variant="" className="w-full rounded-pill text-sm border text-gray btn-lg font-semibold">
                    <Image className="me-2 text-lg" src={FacebookIcon} width={20} />
                    FACEBOOK
                  </Button>
                </Col> */}
              </Row>
            </Form>
          </div>
          <div className="max-w-screen-400 m-auto mt-0 w-full">
            <Row>
              <Col md={6} xs={12}>
                <p className="font-semibold text-md-start text-center">
                  New to propXchange?{' '}
                </p>
              </Col>
              <Col md={6} xs={12}>
                <Link
                  to="/register"
                  className="font-semibold text-dark underline-text text-center"
                >
                  <p>Create an Account</p>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Login;
