import { toast } from 'react-toastify';
import { axios } from '../../axios/AxiosConfig';
import { ZOHO_INTEGRATION_LAMBDA_URL } from '../../constants';

export const subscribeToMailingList = async (email: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${ZOHO_INTEGRATION_LAMBDA_URL}/subscribe`,
        {
          email,
        },
      );
      resolve(response.data); // Assuming the response directly contains the questionnaire data
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error);
      reject(error);
    }
  });
};
