import { useEffect, useState } from 'react';
import { VOPAY_INVESTMENT_HOLDINGS } from '../services/apiEndPoints';
import { axios } from '../axios/AxiosConfig';
import { RootState } from '../redux/store/type';
import { useSelector } from 'react-redux';

// [IN PROGRESS] - created this to ensure basic working
function useGetHoldings() {
  const { isSetUp } = useSelector((state: RootState) => state.wallet);
  const [holdings, setHoldings] = useState(-1);

  useEffect(() => {
    const fetchInvestmentHoldings = async () => {
      try {
        if (isSetUp) {
          const holdings = await axios.get(VOPAY_INVESTMENT_HOLDINGS);
          // [TODO] - Revisit
          // if (!transactions) {
          //   handleError({
          //     message: 'Unable to fetch Transactions.',
          //   });
          //   return;
          // }
          setHoldings(holdings.data?.data?.data?.holdings);
        }
      } catch (err) {
        console.error('Error while fetching transaction history ', err);
        // handleError(err); // [TODO] - Revisit
      }
    };
    fetchInvestmentHoldings();
  }, [isSetUp]);

  return {
    holdings: holdings,
    isLoading: holdings === -1,
  };
}

export default useGetHoldings;
