import { axios } from '../axios/AxiosConfig';
import { VOPAY_INITIATE_INTERAC_TOP_UP_REQUEST } from '../services/apiEndPoints';

function useInitiateIntercRequestForTopUp() {
  // TODO: Fix globalslice
  const initiateRequest = async (payload) => {
    try {
      const response = await axios.post(
        VOPAY_INITIATE_INTERAC_TOP_UP_REQUEST,
        payload,
      );
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  return {
    initiateRequest,
  };
}

export default useInitiateIntercRequestForTopUp;
