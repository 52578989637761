import { toast } from 'react-toastify';
import { axios } from '../../../axios/AxiosConfig';
import { USER_RESPONSE_SQ_API } from '../../apiEndPoints';
import { UserResponse } from '../../../redux/slices/suitabilityQuestionnaire/userResponseSQSlice';

export const updateUserResponseSQService = async (
  responseData: UserResponse[],
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(USER_RESPONSE_SQ_API, responseData);
      resolve(response.data?.data?.data); // Assuming the response directly contains the questionnaire data
    } catch (error: any) {
      toast.error(error?.message);
      console.log(error);
      reject(error);
    }
  });
};
