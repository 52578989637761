import { Footer, Header } from '../../component';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div className="px-5 py-5 px-md-32">
        <div className="bg-light-orange rounded-5 mt-1 mb-18">
          <div>
            <div className="text-center px-16 py-16 py-sm-18 py-md-30">
              <h1 className="display-3 mb-3 font-bold">
                <span className="font-light">Privacy </span>
                Policy
              </h1>
              <p className="mb-8 mb-lg-14 text-md text-dark">
                Your privacy, our priority. We protect your data every step of
                the way.
              </p>
              <p className="">
                <em>Last Updated 23rd May, 2024 </em>
              </p>
            </div>
          </div>
        </div>
        <div className="pb-48 text-dark">
          <h6>Welcome to PropXchange!</h6>
          <br />
          <p>
            <small>
              PropXchange Technology Inc. (together with all companies owned by
              and in common ownership with PropXchange Technology Inc.,
              “PropXchange,” “we,” “us,” “our”) provides its Services (as
              defined herein) to you through its website located at
              https://www.propxchange.ca (the “Website”). The term “Website”
              shall include any modifications, enhancements, integrations, or
              related applications thereto. The term “Services” shall include
              all content and associated services published or otherwise made
              available to you, via the Website or otherwise, under these Terms.
              The Services and the Website are collectively referred to as the
              “Site”.
            </small>
          </p>
          <br />
          <h5>Use of the Site </h5>
          <br />
          <ol>
            <small>
              <li>
                <span className="font-bold">Acceptance of Terms: </span> These
                Terms of Use (as amended, supplemented, or replaced from time to
                time, the “Terms”) govern your access to and use of the Site. By
                accessing or using the Site, you agree to be bound by these
                Terms. If you do not accept these Terms, you must not access or
                use the Site.
              </li>
              <br />
              <li>
                <span className="font-bold">Organizational Use: </span> If you
                are accepting these Terms on behalf of an organization, you
                represent that you have the authority to bind such organization
                to these Terms, in which case “you” or “your” will refer to such
                organization. If you do not have such authority, or you do not
                agree with these Terms, you must not accept these Terms and may
                not use the Site.
              </li>
              <br />
              <li>
                <span className="font-bold">Electronic Communications: </span>{' '}
                You agree to have these Terms and any related information made
                available to you, and to otherwise have communications between
                you and us occur, electronically. You also agree that any
                transactions through the Site shall be conducted electronically.
                You may also be subject to additional terms and conditions
                applicable to certain services, including the PropXchange mobile
                application, which are incorporated by reference into these
                Terms.
              </li>
              <br />
              <li>
                <span className="font-bold">Changes to Terms: </span> We have
                the right, in our sole discretion, to add to, remove, modify or
                otherwise change any part of these Terms, in whole or in part,
                at any time without notice to you. If we exercise this right,
                such changes will be effective as of the date the changes to
                these Terms are posted to the Site, and the “Last Update” notice
                at the top of this document shall be amended to reflect the date
                of such changes. It is your responsibility to check these Terms
                each time you access the Site to determine whether any changes
                have been made. If any change to these Terms is not acceptable
                to you, you must discontinue your use of the Site immediately.
                Your continued use of the Site after any such changes are posted
                will constitute acceptance of those changes. These Terms apply
                exclusively to your use of the Site and do not alter the terms
                or conditions of any other agreement you may have with us.
              </li>
              <br />
              <li>
                <span className="font-bold">Modification of Site: </span> We
                may, at any time without notice or liability, and for any reason
                whatsoever, terminate, change, suspend or discontinue any aspect
                of the Site, including: (a) changing the availability of,
                restricting access to, or imposing limits on any or all features
                or services on, or links to, the Site (including Third Party
                Services (as defined herein)); (b) removing, adding, modifying
                or otherwise changing any Fees (as defined herein) arising out
                of use of the Site or any features of the Site; and (c)
                removing, adding, modifying or otherwise changing any content on
                the Site. We reserve the right, in our sole discretion, to
                correct any errors or omissions in any portion of the Site at
                any time without notice.
              </li>
              <br />
              <li>
                <span className="font-bold">Age Requirement: </span> You must be
                18 years of age or older and of at least the age of majority in
                the jurisdiction in which you reside as of the time you register
                a user account with us.
              </li>
            </small>
          </ol>

          <br />
          <h5>Membership and Accounts</h5>
          <br />
          <ol>
            <small>
              <li>
                <span className="font-bold">Account Registration: </span> To use
                the Site, you must register for a user account by providing us
                with your real name, password, mailing address, valid email
                address, and certain other information that we may reasonably
                request, including information relating to your eligibility
                under applicable securities laws (including information we
                require to verify your identity, jurisdiction of residence, and
                your financial condition). You acknowledge and agree that we
                will rely on this information in providing you with access to
                the Site and any Offering (as defined herein). You must provide
                complete and accurate information to us and notify us promptly
                if your information changes.
              </li>
              <br />
              <li>
                <span className="font-bold">Use of Personal Information: </span>{' '}
                You consent to PropXchange's collection, use, and distribution
                of personal information provided by you for the purpose of
                verifying your identity (including for regulatory purposes) or
                creditworthiness (including by obtaining and using credit
                reports). Other personal identifiers, if provided, may be used
                to verify your identity, including matching credit reports.
                PropXchange may collect credit, financial, and related personal
                information for these purposes from you, credit bureaus, credit
                reporting agencies, and references provided by you to
                PropXchange. You consent to the disclosure of such information
                by these parties to PropXchange. You agree that PropXchange may,
                from time to time, use the above information and other personal
                information collected or compiled by PropXchange in connection
                with these Terms (including user account status and payment
                history) for the purposes of opening, administering, servicing,
                and enforcing these Terms, collecting amounts owing to
                PropXchange, verifying and evaluating your current and ongoing
                creditworthiness and financial status, responding to your
                inquiries, and otherwise communicating with you regarding a user
                account.
              </li>
              <br />
              <li>
                <span className="font-bold">Account Security: </span>
                You may not share your login information or password with any
                other person. You are responsible for all activity occurring
                under your user account. You shall abide by all applicable
                local, provincial, national, and foreign laws, treaties, and
                regulations in connection with the use of the Site, including
                those related to data privacy, international communications, and
                the transmission of technical or personal information. You
                shall: (a) notify us immediately of any unauthorized use of any
                password or user account or any other known or suspected breach
                of security; and (b) not impersonate another person or provide
                false identity information to gain access to or use the Site.
                Without limitation, we may immediately remove or suspend any
                user account where we suspect there has been an attempted or
                actual security breach or to ensure the security of the Site.
              </li>
              <br />
              <li>
                <span className="font-bold">Unauthorized Access: </span> If you
                become aware of unauthorized access to your account, you must
                change your password and notify us immediately. You agree to
                notify us promptly of any unauthorized use of your password and
                you will remain liable for any use of the Site to the extent
                permitted by applicable law.
              </li>
              <br />
              <li>
                <span className="font-bold">Prohibited Uses: </span> We You will
                not, and will not permit any third party to, directly or
                indirectly, use the Site in any manner that is unlawful
                (including by accessing the Site from any location where such
                access may be illegal or prohibited), unethical, indecent,
                offensive, defamatory, derogatory, fraudulent, deceptive,
                harmful, abusive, threatening, vulgar, profane, pornographic,
                obscene, sexually explicit, sexist, racist, hateful, offensive,
                harassing, invasive of the privacy rights of others (this
                includes the posting of any materials that depict, encourage,
                indicate, advocate, or tend to incite any such conduct), or is
                otherwise objectionable or which does not respect the legal
                rights and interests of others.
              </li>
              <br />
              <li>
                <span className="font-bold">Legal Compliance: </span> You are
                solely responsible for complying with all applicable laws with
                respect to your access to and use of the Site.
              </li>
            </small>
          </ol>

          <br />
          <h5>Limited Rights and Restrictions</h5>
          <br />
          <ol>
            <small>
              <li>
                <span className="font-bold">Access Rights: </span> Subject to
                these terms, you are granted a non-exclusive, non-transferable,
                non-sub-licensable, revocable, limited-purpose right to access
                and use the Site and the content therein solely for your own
                personal, or internal business, use. You may not use, access, or
                use the Site or such content on any deviation thereof for the
                purposes of resale, solicitation, advertising, or to provide
                services to third parties or otherwise deal with the same
                without our prior written permission.
              </li>
              <br />
              <li>
                <span className="font-bold">Prohibited Actions: </span>
                You will not or permit any third party to, directly or
                indirectly: (a) modify, adapt, translate, reverse engineer,
                decompile, create derivative works from, or disassemble the Site
                for any reason whatsoever, including for the purpose of creating
                competitive products or services; (b) sublicense, lease, sell,
                resell, rent, share, loan, distribute, transfer or otherwise
                commercially exploit any portion of the Site, or allow the use
                of the Site on behalf of or for the benefit of any third party;
                (c) copy any portion of the Site onto your own or any other
                website or into a database or mobile application; (d) use the
                Site in any manner that could damage, disable, overburden,
                impair, interfere with the security of, negatively affect the
                functioning of, or otherwise abuse, the Site or any other
                services, system resources, accounts, servers, networks,
                affiliated or linked sites connected to or accessible through
                the Site (including without limitation uploading, posting, or
                otherwise transmitting on the Site any computer viruses, trojan
                horses, worms, or other files or computer programs which are
                potentially harmful, disruptive, or destructive or that may
                impose an unreasonable or disproportionately large load on the
                infrastructure of the Site); (e) use any robot, spider or other
                automatic program or device, or manual process to monitor, copy,
                summarize, or otherwise extract information from the Site in
                whole or in part; (f) use the Site in any manner that may dilute
                or depreciate the PropXchange name, intellectual property
                rights, reputation, or goodwill; (g) interfere with any other
                persons’ use and enjoyment of the Site or of the Internet
                generally, including by harvesting or collecting information
                about other users of the Site; (h) send unauthorized or
                unsolicited junk mail, spam, chain letters, pyramid schemes, or
                any other form of solicitation (including solicitation for the
                purposes of any transaction) or otherwise contact any other user
                of the Site; (i) use the Site if you are not able to form
                legally binding contracts, or are temporarily or indefinitely
                suspended from using the Site; (j) access the Site by any means
                other than through the interface that is provided by us for use
                in accessing the Site; (k) use the Site to gain unauthorized
                access to, interfere with, damage, or disrupt any parts of the
                Site, the servers on which the Site is stored, or any server,
                computer, or database connected to the Site; (l) use the Site to
                promote or engage in any unlawful activity or fraudulent act;
                (m) use the Site in any manner or for any purpose that violates
                any applicable law, regulation, contractual obligation, or
                industry standard; (n) use the Site to mislead or deceive
                others; or (o) cause any third party to engage in the restricted
                activities above. Any unauthorized use of the Site or any breach
                of these Terms immediately terminates the permissions or
                licenses granted by us to you to use the Site. We may further
                terminate your use of the Site, without notice and with or
                without cause, at any time and without liability to you.
              </li>
              <br />
              <li>
                <span className="font-bold">Proprietary Rights: </span>
                The Site (including its design and appearance), and all
                information, software, processes, and materials contained
                therein, including all text, graphics, videos, audio,
                photographs, illustrations, trademarks, trade names, logos,
                services marks, slogans, domain names, trade dress, icons, the
                arrangement of any of the foregoing, copyrights, and all other
                intellectual property and proprietary rights in and to the Site
                (collectively, the “Proprietary Information”), are owned by us
                or are licensed to us by our third party partners and licensors.
                Except for the limited rights expressly granted to you in these
                Terms, you shall have no right, title, or interest in or to the
                Site, and any and all rights not expressly granted to you in
                these Terms are reserved by us and our licensors.
              </li>
            </small>
          </ol>

          <br />
          <h5>Indemnity</h5>
          <br />
          <ul>
            <small>
              <li>
                You agree to defend, indemnify and hold harmless PropXchange and
                our respective directors, officers, employees, contractors,
                representatives, agents, licensors, successors, and assigns
                (collectively, the “PropXchange Parties”) from and against any
                and all losses, damages, costs, claims, and demands (including
                without limitation, reasonable legal and accounting fees)
                arising out of or in connection with your use or misuse of the
                Site, your breach of these Terms, or your violation of any
                rights of another person or entity. We reserve the right to
                assume the exclusive defense and control of any matter subject
                to indemnification by you, and you agree to cooperate with us in
                the defense of any such claim.
              </li>
            </small>
          </ul>

          <br />
          <h5>Limitation of Liability</h5>
          <br />
          <ol>
            <small>
              <li>
                <span className="font-bold">No Warranty: </span> The site and
                all information, content, and materials available through the
                site are provided on an “as is” and “as available” basis without
                any warranties of any kind, either express or implied, including
                but not limited to, implied warranties of merchantability,
                fitness for a particular purpose, title, and non-infringement.
                PropXchange makes no warranties or representations about the
                accuracy or completeness of the content provided through the
                site or the content of any websites linked to the site.
              </li>
              <br />
              <li>
                <span className="font-bold">Exclusion of Liability: </span> The
                site and To the fullest extent permitted by applicable law,
                PropXchange will not be liable for any indirect, incidental,
                special, consequential, or punitive damages, or any loss of
                profits, revenues, data, goodwill, or other intangible losses,
                resulting from your use or inability to use the site.
                <br />
                <br />
                <div className="d-flex">
                  <div className="font-bold px-2">a. </div>
                  <div>
                    <span className="font-bold">Limitation of Liability: </span>
                    PropXchange's aggregate liability for all claims arising out
                    of or in connection with these terms or your use of the site
                    shall not exceed the greater of: (a) the amount you have
                    paid to PropXchange for the use of the site in the twelve
                    (12) months preceding the event giving rise to the claim, or
                    (b) one hundred Canadian dollars (CAD$100.00).
                  </div>
                </div>
              </li>
            </small>
          </ol>

          <br />
          <h5>General</h5>
          <br />
          <ol>
            <small>
              <li>
                <span className="font-bold">Governing Law: </span> These Terms
                and any dispute arising out of or in connection with them shall
                be governed by and construed in accordance with the laws of the
                Province of Ontario and the federal laws of Canada applicable
                therein, without regard to its conflict of laws rules. You agree
                to submit to the exclusive jurisdiction of the courts of
                Ontario, Canada, to resolve any disputes arising out of these
                Terms or the Site.
              </li>
              <br />
              <li>
                <span className="font-bold">Severability: </span>
                If any provision of these Terms is found to be unlawful, void,
                or for any reason unenforceable, then that provision shall be
                deemed severable from these Terms and shall not affect the
                validity and enforceability of any remaining provisions.
              </li>
              <br />
              <li>
                <span className="font-bold">Assignment: </span>
                You may not assign these Terms or any rights or obligations
                hereunder, by operation of law or otherwise, without our prior
                written consent. We may assign these Terms at any time without
                notice to you.
              </li>
              <br />
              <li>
                <span className="font-bold">Waiver: </span>
                No waiver of any provision of these Terms by us shall be deemed
              </li>
            </small>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
