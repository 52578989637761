import { useEffect, useState } from 'react';
import { VOPAY_GET_USER_WALLET_TRANSACTION_HISTORY } from '../services/apiEndPoints';
import { axios } from '../axios/AxiosConfig';
import { handleError } from '../utilities/handleError';
import { RootState } from '../redux/store/type';
import { useSelector } from 'react-redux';

function useGetUserWalletTransactionHistory() {
  const { isSetUp } = useSelector((state: RootState) => state.wallet);
  const [transactionHistory, setTransactionHistory] = useState(-1);

  useEffect(() => {
    const fetchTransactionHistory = async () => {
      try {
        if (isSetUp) {
          const transactionHistory = await axios.get(
            VOPAY_GET_USER_WALLET_TRANSACTION_HISTORY,
          );
          const transactions =
            transactionHistory.data?.data?.data?.transactions;

          if (!transactions) {
            handleError({
              message: 'Unable to fetch Transactions.',
            });
            return;
          }
          setTransactionHistory(transactions);
        }
      } catch (err) {
        console.error('Error while fetching transaction history ', err);
        handleError(err);
      }
    };
    fetchTransactionHistory();
  }, [isSetUp]);

  return {
    transactionHistory: transactionHistory,
    isLoading: transactionHistory === -1,
  };
}

export default useGetUserWalletTransactionHistory;
