import { Button, Accordion, Stack } from 'react-bootstrap';
import { FaAngleRight } from 'react-icons/fa6';
import { IoIosCloseCircle } from 'react-icons/io';

export const PropFilters = ({ handleMenuToggle1, setShowMenu }) => {
  return (
    <div
      style={{ backgroundColor: '#fff', height: '80vh' }}
      className={`mb-4 me-4 rounded-4 border d-flex flex-column`}
    >
      <div className="py-7 px-5 border-bottom d-flex justify-content-between align-items-center">
        <p className="font-bolder mb-0">Filters</p>
        <Button
          variant="link"
          className="btn-close p-0 border-0 shadow-none"
          aria-label="Close"
          onClick={() => setShowMenu(false)}
        ></Button>
      </div>
      <div className="filter-menu-body px-4 pt-0">
        <ul className="list-unstyled">
          <li className="border-bottom">
            <div className="py-3 px-2 hstack">
              <p>Type of Property</p>
              <div className="ms-auto">
                <Button
                  variant="link"
                  className="border bg-gray-hover py-1 text-dark-hover text-dark rounded-pill me-2"
                >
                  villa <IoIosCloseCircle />
                </Button>
                <Button
                  variant="link"
                  className="border bg-gray-hover py-1 text-dark-hover text-dark rounded-pill me-2"
                >
                  Apartment <IoIosCloseCircle />
                </Button>
                <FaAngleRight onClick={handleMenuToggle1} />
              </div>
            </div>
          </li>
          <li className="border-bottom">
            <div className="py-3 px-2 hstack">
              <Accordion className="filter-accordion w-full">
                <Accordion.Item eventKey="0" className="border-0">
                  <div className="w-full hstack">
                    <p>Location</p>
                    <Button
                      variant="link"
                      className="ms-auto py-1 border bg-gray-hover text-dark-hover text-dark rounded-pill me-4"
                    >
                      Vancouver <IoIosCloseCircle />
                    </Button>
                    <Accordion.Header></Accordion.Header>
                  </div>
                  <Accordion.Body className="px-0">test</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </li>
          <li className="border-bottom">
            <div className="py-3 px-2 hstack">
              <p>Duration</p>
              <div className="ms-auto">
                <FaAngleRight />
              </div>
            </div>
          </li>
          <li className="border-bottom">
            <div className="py-3 px-2 hstack">
              <p>IRR (Internal Rate of Return)</p>
              <div className="ms-auto">
                <FaAngleRight />
              </div>
            </div>
          </li>
          <li className="border-bottom">
            <div className="py-3 px-2 hstack">
              <p>Prop Units Available</p>
              <div className="ms-auto">
                <FaAngleRight />
              </div>
            </div>
          </li>
          <li className="border-bottom">
            <div className="py-3 px-2 hstack">
              <p>Funding Raised (%)</p>
              <div className="ms-auto">
                <FaAngleRight />
              </div>
            </div>
          </li>
          <li className="border-bottom">
            <div className="py-3 px-2 hstack">
              <p>Per Unit Price</p>
              <div className="ms-auto">
                <FaAngleRight />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <Stack direction="horizontal" className="px-5 mt-auto mb-5">
        <Button
          variant="link"
          style={{ width: '12rem' }}
          className=" border bg-primary-hover text-light-hover rounded-pill"
        >
          Clear All
        </Button>
        <Button
          variant="primary"
          style={{ width: '12rem' }}
          className=" ms-auto rounded-pill"
        >
          Show 2 Properties
        </Button>
      </Stack>
    </div>
  );
};
