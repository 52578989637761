import axios from 'axios';
import { handleError } from '../../utilities/handleError';
import { CHATBOT_INTEGRATION_LAMBDA_URL } from '../../constants';

export const getChatbotResponseService = async (inputText: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(CHATBOT_INTEGRATION_LAMBDA_URL, {
        inputText,
      });
      resolve(response?.data?.response?.text); // Assuming the response directly contains the questionnaire data
    } catch (error: any) {
      handleError(error);
      console.log(error);
      reject(error);
    }
  });
};
