import { useState } from 'react';
import { Header } from '../../component';
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Spinner,
} from 'react-bootstrap';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import { FcGoogle } from 'react-icons/fc';
import { ErrorFill } from '../../assets/image';
import { useNavigate } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { signupService } from '../../services/auth';
import './Register.scss';

function Register() {
  const [userCredentials, setUserCredentials] = useState({
    first_name: '',
    last_name: '',
    password: '',
    email: '',
    passwordConfirm: '',
  });
  const [userCredentialsError, setUserCredentialsError] = useState({
    first_name: false,
    password: false,
    passwordConfirm: false,
    email: false,
    last_name: false,
  });
  const [loading, setLoading] = useState(false);
  const [eye, setEye] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const navigate = useNavigate();
  const Eye = () => {
    setEye(!eye);
  };

  const isError = () =>
    userCredentialsError?.email ||
    userCredentialsError?.first_name ||
    userCredentialsError?.last_name ||
    userCredentialsError?.password ||
    userCredentialsError?.passwordConfirm ||
    userCredentials?.email === '' ||
    userCredentials?.password === '' ||
    userCredentials?.passwordConfirm === '' ||
    userCredentials?.first_name === '' ||
    userCredentials?.last_name === '';

  const signup = () => {
    setLoading(true);
    signupService({
      email: userCredentials.email,
      password: userCredentials.password,
      first_name: userCredentials.first_name,
      last_name: userCredentials?.last_name,
      passwordConfirm: userCredentials?.passwordConfirm,
    })
      .then((result) => {
        setLoading(false);
        if (result) {
          console.log(result);
          setTimeout(() => {
            toast.success('Activation mail has been sent to your email');
          }, 2000); // 2000 milliseconds = 2 seconds
          navigate(`/login`);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const isValidEmail = (email: string): boolean => {
    // Regular expression for validating an email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePassworStrength = (password: string): boolean => {
    if (!password.match(/[a-z]+/)) {
      return false;
    }
    if (!password.match(/[A-Z]+/)) {
      return false;
    }
    if (!password.match(/[0-9]+/)) {
      return false;
    }
    if (!password.match(/[$@#&!^%*]+/)) {
      return false;
    }
    if (password.length < 8) {
      return false;
    }
    return true;
  };

  return (
    <div className="min-h-screeen vstack">
      <Header />
      <div className="vstack login-h-full login-background background-no-repeat bg-center bg-cover">
        <Container className="vstack">
          <div className="max-w-screen-400 m-auto w-full pb-4">
            <h2 className="display-6 text-center mb-1 font-bolder">
              Create an Account
            </h2>
            <p className="text-center text-dark mb-12">
              Register now and unlock the benefits of
              <br /> investing with us.
            </p>
            <Form>
              <div className="vstack gap-3 mb-10">
                <Form.Group
                  controlId="floatingInput1"
                  className="form-floating"
                >
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    name="email"
                    value={userCredentials.email}
                    className={
                      userCredentialsError.email
                        ? 'invalid-border invalid-border-shadow'
                        : 'form-control'
                    }
                    onChange={(event) => {
                      setUserCredentials((prev) => ({
                        ...prev,
                        email: event.target.value,
                      }));
                    }}
                    onBlur={(event) => {
                      if (
                        !event.target.value ||
                        event.target.value === '' ||
                        !isValidEmail(event.target.value)
                      ) {
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          email: true,
                        }));
                      } else
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          email: false,
                        }));
                    }}
                  />
                  <Form.Label className="font-lighter">
                    Email address
                  </Form.Label>
                  {userCredentialsError.email && (
                    <p className="invalid-color mt-1 d-flex align-item-center">
                      <Image src={ErrorFill} className="mx-2" />
                      Please enter valid email
                    </p>
                  )}
                </Form.Group>
                <Form.Group
                  controlId="floatingInput2"
                  className="form-floating"
                >
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Name"
                    name="first_name"
                    className={
                      userCredentialsError.first_name
                        ? 'invalid-border invalid-border-shadow'
                        : 'form-control'
                    }
                    value={userCredentials.first_name}
                    onBlur={(event) => {
                      if (!event.target.value || event.target.value === '') {
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          first_name: true,
                        }));
                      } else
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          first_name: false,
                        }));
                    }}
                    onChange={(event) => {
                      setUserCredentials((prev) => ({
                        ...prev,
                        first_name: event.target.value,
                      }));
                    }}
                  />
                  <Form.Label className="font-lighter">First Name</Form.Label>
                  {userCredentialsError.first_name && (
                    <p className="invalid-color mt-1 d-flex align-item-center">
                      <Image src={ErrorFill} className="mx-2" />
                      Please enter valid first name
                    </p>
                  )}
                </Form.Group>
                <Form.Group
                  controlId="floatingInput2"
                  className="form-floating"
                >
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Name"
                    name="last_name"
                    className={
                      userCredentialsError.last_name
                        ? 'invalid-border invalid-border-shadow'
                        : 'form-control'
                    }
                    value={userCredentials.last_name}
                    onBlur={(event) => {
                      if (!event.target.value || event.target.value === '') {
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          last_name: true,
                        }));
                      } else
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          last_name: false,
                        }));
                    }}
                    onChange={(event) => {
                      setUserCredentials((prev) => ({
                        ...prev,
                        last_name: event.target.value,
                      }));
                    }}
                  />
                  <Form.Label className="font-lighter">Last Name</Form.Label>
                  {userCredentialsError.last_name && (
                    <p className="invalid-color mt-1 d-flex align-item-center">
                      <Image src={ErrorFill} className="mx-2" />
                      Please enter valid last name
                    </p>
                  )}
                </Form.Group>
                <Form.Group
                  controlId="floatingInput3"
                  className="position-relative form-floating"
                >
                  <Form.Control
                    type={eye ? 'text' : 'password'}
                    className={
                      userCredentialsError.password
                        ? 'invalid-border invalid-border-shadow'
                        : 'form-control'
                    }
                    placeholder="Password*"
                    name="password"
                    value={userCredentials.password}
                    onChange={(event) => {
                      setUserCredentials((prev) => ({
                        ...prev,
                        password: event.target.value,
                      }));
                    }}
                    onBlur={(event) => {
                      if (!event.target.value || event.target.value === '') {
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          password: true,
                        }));
                      } else if (!validatePassworStrength(event.target.value)) {
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          password: true,
                        }));
                      } else {
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          password: false,
                        }));
                      }
                    }}
                  />
                  {!eye ? (
                    <FaRegEye onClick={Eye} className="fa-eye" />
                  ) : (
                    <FaRegEyeSlash onClick={Eye} className="fa-eye" />
                  )}
                  <Form.Label className="font-lighter">Password</Form.Label>
                  {userCredentialsError.password && (
                    <p className="invalid-color mt-1 d-flex align-item-center">
                      <Image src={ErrorFill} className="mx-2" />
                      Please enter valid password
                    </p>
                  )}
                  {!!userCredentials?.password?.length && (
                    <>
                      <PasswordStrengthBar
                        minLength={4}
                        onChangeScore={(score) => console.log(score)}
                        className={`${
                          userCredentials.password?.length > 3 &&
                          'password-bar-custome'
                        } mb-1`}
                        password={userCredentials.password}
                      />
                      {userCredentials.password?.length > 3 && (
                        <Row className="gx-0 mb-3">
                          <Col>
                            <p className="text-sm">Weak</p>
                          </Col>
                          <Col>
                            <p className="text-sm">Average</p>
                          </Col>
                          <Col>
                            <p className="text-sm">Strong</p>
                          </Col>
                          <Col>
                            <p className="text-sm">Very Strong</p>
                          </Col>
                        </Row>
                      )}
                      <span style={{ fontSize: '0.9rem' }}>
                        <em>
                          Use at least 8 characters{' '}
                          <strong>1 uppercase letter, 1 lowercase </strong> and{' '}
                          <strong>1 number, 1 Special Character</strong> in your
                          password
                        </em>
                      </span>
                    </>
                  )}
                </Form.Group>
                <Form.Group
                  controlId="floatingInput4"
                  className="position-relative form-floating"
                >
                  <Form.Control
                    type={'password'}
                    className={
                      userCredentialsError.passwordConfirm
                        ? 'invalid-border invalid-border-shadow'
                        : 'form-control'
                    }
                    placeholder="Password*"
                    name="passwordConfirm"
                    onChange={(event) => {
                      setUserCredentials((prev) => ({
                        ...prev,
                        passwordConfirm: event.target.value,
                      }));
                    }}
                    onBlur={(event) => {
                      if (
                        !event.target.value ||
                        event.target.value !== userCredentials?.password
                      ) {
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          passwordConfirm: true,
                        }));
                      } else
                        setUserCredentialsError((prev) => ({
                          ...prev,
                          passwordConfirm: false,
                        }));
                    }}
                  />
                  {/* <FaEyeSlash className="fa-eye" /> */}
                  <Form.Label className="font-lighter">
                    Re-enter Password
                  </Form.Label>
                  {userCredentialsError.passwordConfirm && (
                    <p className="invalid-color mt-1 d-flex align-item-center">
                      <Image src={ErrorFill} className="mx-2" />
                      Passwords does not match
                    </p>
                  )}
                </Form.Group>
              </div>
              <div className="d-flex">
                <Form.Check
                  type="checkbox"
                  checked={acceptTerms}
                  onChange={(e) => setAcceptTerms(e.target.checked)}
                  id="confirm-investment"
                />
                <p className="mb-8 ms-2">
                  By continuing,you agree to PropXchange`s <br />
                  <Link
                    to="/"
                    className="font-semibold text-dark underline-text"
                  >
                    Terms of Services
                  </Link>
                  {' and '}
                  <Link
                    to="/privacy-policy"
                    className="font-semibold text-dark underline-text"
                  >
                    Privacy Policy
                  </Link>
                </p>
              </div>
              <div className="text-center">
                <Button
                  onClick={() => signup()}
                  variant="primary"
                  disabled={isError() || loading || !acceptTerms}
                  className="rounded-pill py-4 w-full mb-5 font-semibold text-sm"
                >
                  {loading ? (
                    <span>
                      <Spinner animation="border" size="sm" />
                    </span>
                  ) : (
                    'Create an Account'
                  )}
                </Button>
                <div className="position-relative  font-semibold  hstack justify-content-center mb-6">
                  <span className="px-2 bg-light position-relative overlap-20">
                    Or continue with
                  </span>
                  <div className="border-bottom border-2 w-full position-absolute"></div>
                </div>
              </div>
              <Row className="mb-8 gy-6 gx-3">
                <Col md={12}>
                  <Button
                    variant=""
                    className="w-full rounded-pill text-sm border text-gray btn-lg font-semibold"
                  >
                    <FcGoogle className="me-2 text-lg" />
                    Google
                  </Button>
                </Col>
                {/* <Col md={6}>
                  <Button variant="" className="w-full rounded-pill text-sm border text-gray btn-lg font-semibold">
                    <Image className="me-2 text-lg" src={FacebookIcon} width={20} />
                    FACEBOOK
                  </Button>
                </Col> */}
              </Row>
            </Form>
          </div>
          <div className="max-w-screen-md mx-auto mt-auto w-full">
            <Row className="border-top border border-0 border-1 gy-4 pb-4">
              <Col sm={6}>
                <p className="font-semibold text-sm-start text-center">
                  Already have an account?
                </p>
              </Col>
              <Col sm={6} className="text-sm-end text-center">
                <Link
                  to="/login"
                  className="font-semibold text-dark underline-text "
                >
                  Login to propXchange
                </Link>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Register;
