import { axios } from '../../axios/AxiosConfig';
import { getLanguageCode } from '../../utilities/localStorageFun';
import { FORGOT_PASSWORD } from '../apiEndPoints';
import { handleError } from '../../utilities/handleError';

export const forgotPasswordService = async ({ email }: { email: string }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        FORGOT_PASSWORD,
        { email },
        {
          params: { lng: getLanguageCode() },
        },
      );
      resolve({ status: response?.data?.status });
    } catch (error: any) {
      handleError(error);
      reject(error.response.data.message);
    }
  });
};
