import {
  loadingReducer,
  logoutReducer,
} from '../../redux/slices/auth/authSlice';
import { handleError } from '../../utilities/handleError';

export const logoutService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      loadingReducer({ isLoading: true });
      localStorage.removeItem('auth_token_key');
      localStorage.removeItem('expirationDate');
      localStorage.removeItem('userType');
      localStorage.removeItem('email');
      localStorage.removeItem('slug');
      localStorage.removeItem('twoFactorLoginStatus');
      logoutReducer();
      resolve({ success: true, status_code: 200 });
    } catch (error: any) {
      handleError(error);
      // if (appUserIdQueryParam) console.log("app-auto-login-error"); // app web view login error
      loadingReducer({ isLoading: false });
      // authFailReducer({ error: error?.response?.data?.message });
      reject(error);
    }
  });
};
