import { Card } from 'react-bootstrap';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { formatAmount } from '../../util';
import useGetHoldings from '../../../hooks/useGetHoldings';

const Holdings = () => {
  const { holdings } = useGetHoldings();
  console.log('[DEBUG](nvtalavi) holdings ', holdings);
  const mockHoldings = [
    {
      name: 'Harmony Heights',
      diff: -11.15,
      total: '10k',
      diff_val: 1100,
    },
    {
      name: 'Angel Heights',
      diff: 3.15,
      total: '5k',
      diff_val: 200,
    },
    {
      name: 'Harmony Heights',
      diff: -48.15,
      total: '3k',
      diff_val: 1100,
    },
    {
      name: 'Harmony Heights',
      diff: 91.15,
      total: '21k',
      diff_val: 19000,
    },
  ];

  return (
    <>
      <Card className="shadow-lg">
        <Card.Body className="p-5">
          <div className="py-0 mb-6 ms-auto d-flex gap-12 justify-space-between">
            <Card.Text className="text-sm">Holdings</Card.Text>
            <Card.Text className="text-sm">Total P&L</Card.Text>
          </div>
          {mockHoldings.map((h) => (
            <div className="mb-6">
              <div className="py-0 ms-auto d-flex gap-12 justify-space-between">
                <Card.Text className="font-bold text-dark text-sm">
                  {h.name}
                </Card.Text>
                <Card.Text
                  className={`text-sm align-self-center ${
                    h.diff < 0 ? 'text-danger' : 'text-success'
                  }`}
                >
                  {h.diff < 0 ? (
                    <AiFillCaretDown className="mx-1" />
                  ) : (
                    <AiFillCaretUp className="mx-1" />
                  )}
                  {Math.abs(h.diff)}%
                </Card.Text>
              </div>
              <div className="py-0 ms-auto d-flex gap-12 justify-space-between">
                <Card.Text className="font-bold text-sm">
                  C$ {h.total}
                </Card.Text>
                <Card.Text
                  className={`text-sm align-self-center ${
                    h.diff < 0 ? 'text-danger' : 'text-success'
                  }`}
                >
                  {formatAmount(h.diff_val)}
                </Card.Text>
              </div>
            </div>
          ))}
        </Card.Body>
      </Card>
    </>
  );
};

export default Holdings;
