import { VOPAY_GET_USER_BALANCE } from '../apiEndPoints';
import { axios } from '../../axios/AxiosConfig';
import { toast } from 'react-toastify';

export const getCurrentBalance = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const getBalanceResponse = await axios.get(VOPAY_GET_USER_BALANCE);
      const balances = getBalanceResponse.data?.data?.data?.balances;
      const balanceInCAD = balances['0']?.Balance;

      if (!balanceInCAD) {
        throw new Error('Unable to fetch Wallet Balance.');
      }
      resolve(balanceInCAD);
    } catch (error: any) {
      // Commenting this - as this method is used to check whether wallet is setup or not
      // toast.error(error?.message);
      console.log(error);
      reject(error);
    }
  });
};
