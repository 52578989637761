// src/Chatbot.js
import React, { useEffect, useRef, useState } from 'react';
import './Chatbot.scss';
import { LogoNew } from '../../assets/image';
import { BsChatRight } from 'react-icons/bs';
import { AiOutlineSend } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import { Button } from 'react-bootstrap';
import { getChatbotResponseService } from '../../services/chatbot/getChatbotResponseService';

const questions = [
  'What is propXchange?',
  'What are PropUnits?',
  'Is PropUnit a Cryptocurrency?',
  'How does it work?',
  'Why Invest with us?',
];

export const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<any>([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const callMsgBot = (text: string) => {
    getChatbotResponseService(text)
      .then((response) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: response, timestamp: new Date(), user: false },
        ]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleButtonClick = (e) => {
    const value = e.currentTarget.getAttribute('data-value');
    setMessages([
      ...messages,
      { text: value, timestamp: new Date(), user: true },
    ]);
    setInput('');
    setLoading(true);
    callMsgBot(value);
    // Simulate bot response
  };

  const handleSend = () => {
    if (input.trim()) {
      setMessages([
        ...messages,
        { text: input, timestamp: new Date(), user: true },
      ]);
      setInput('');
      setLoading(true);
      callMsgBot(input);
      // Simulate bot response
      //   setTimeout(() => {
      //     setMessages((prevMessages) => [...prevMessages, { text: "This is a bot response.", user: false }]);
      //     setLoading(false);
      //   }, 1000);
      setInput('');
    }
  };

  const formatDate = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (!loading) {
      inputRef.current?.focus();
    }
  }, [messages, loading]);

  return (
    <React.Fragment>
      {!isOpen ? (
        <div className="chatbot-button" onClick={toggleChat}>
          <Button className="chatbot-open-btn">
            <BsChatRight />
          </Button>
        </div>
      ) : (
        <div className={`chatbot`}>
          <div className="chatbot-open">
            <div className="chatbot-header px-4 py-4" onClick={toggleChat}>
              <img src={LogoNew} alt="propXchange" className="h-4" />
              {/* <button className="chatbot-close-btn">✕</button> */}
            </div>
            <div className="d-flex flex-column justify-content-between py-6 px-4 msg-height-91">
              {messages.length === 0 ? (
                <div className="chatbot-body">
                  <div className="mb-6 chatbot-greeting">
                    <h1>Hi there!</h1>
                    <h3>Need help with something?</h3>
                  </div>
                  {/* <div className="mb-3 position-relative chatbot-search">
                <input
                  type="text"
                  placeholder="Search for the help"
                  className="p-3 border border-2 "
                />
                <img src={SearchImg} alt="Search icon" className="position-absolute end-5 bottom-4" />
              </div> */}
                  <div className="chatbot-options mb-6">
                    {questions?.map((que) => (
                      <button
                        key={que}
                        data-value={que}
                        onClick={handleButtonClick}
                      >
                        {que}
                      </button>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="chatbot-messages mb-6">
                  {messages.map((msg, index) => (
                    <>
                      <div
                        key={index + msg?.text}
                        className={`message ${msg.user ? 'user' : 'bot'}`}
                      >
                        {msg.text}
                      </div>
                      <div
                        style={{ color: 'gray', fontSize: '0.8em' }}
                        className={`${
                          msg.user ? 'align-self-end' : 'align-self-start'
                        }`}
                      >
                        {formatDate(new Date(msg.timestamp))}
                      </div>
                    </>
                  ))}
                  {loading && (
                    <div className="message bot message-bot-loading">
                      <span></span>
                    </div>
                  )}
                  <div ref={messagesEndRef} />
                </div>
              )}
              <div className="chatbot-footer position-relative">
                <input
                  type="text"
                  value={input}
                  ref={inputRef}
                  disabled={loading}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Write us a Message"
                  onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                />
                <AiOutlineSend
                  className="position-absolute end-5"
                  onClick={handleSend}
                />
              </div>
            </div>
          </div>
          <div className="chatbot-close-button" onClick={toggleChat}>
            <Button className="chatbot-close-btn">
              <IoIosArrowDown />
            </Button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
