import { getLanguageCode } from '../../utilities/localStorageFun';
import { REGISTRATION_API } from '../apiEndPoints';
import { registrationSuccessReducer } from '../../redux/slices/auth/registrationSlice';
import AxiosAPI from 'axios';
import { axios } from '../../axios/AxiosConfig';
import { handleError } from '../../utilities/handleError';

export const signupService = async ({
  first_name,
  last_name,
  password,
  email,
  passwordConfirm,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const ipv4Data = await AxiosAPI.get(
        'https://geolocation-db.com/json/',
      ).catch((err) => console.log(err));
      const userData = {
        firstName: first_name,
        lastName: last_name,
        email,
        phoneNumber: '9876543210',
        password,
        passwordConfirm,
        createdAt: Date.now().toString(),
        signupIpAddress: ipv4Data?.data?.IPv4,
      };
      const response = await axios.post(
        `${REGISTRATION_API}?lng=${getLanguageCode()}`,
        userData,
      );
      registrationSuccessReducer({ successMessage: response.data.data.data });
      resolve(response?.data?.data);
    } catch (error: any) {
      handleError(error);
      console.log('Signup Error', error);
      reject(error);
    }
  });
};
