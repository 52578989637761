// useAuth.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAccessToken } from '../../utilities/getAccessToken';

const useAuthGuard = () => {
  const navigate = useNavigate();
  const token = getAccessToken();

  useEffect(() => {
    if (!token || token === '') {
      navigate('/login');
    }
  }, [token, navigate]);

  return !!token;
};

export default useAuthGuard;
