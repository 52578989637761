import { axios } from '../axios/AxiosConfig';
import { VOPAY_SET_UP_USER_WALLET } from '../services/apiEndPoints';

function useSetUpUserWallet() {
  // TODO: Fix globalslice
  const setUpUserWallet = async () => {
    try {
      const response = await axios.post(VOPAY_SET_UP_USER_WALLET, {});
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  return {
    setUpUserWallet,
  };
}

export default useSetUpUserWallet;
