import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/routes';
import './BeginInvestingComponent.scss';

// bg-blue-light rounded-5 mt-1 text-center px-10 full-viewport-height
function BeginInvestingComponent() {
  const navigate = useNavigate();

  return (
    <div className="bg-blue-light rounded-5 text-center pt-md-16 pt-16 px-10 full-viewport-height">
      <h1 className="display-3 mb-3 font-bold">
        <span className="font-light">Paving the way to</span> <br />
        Accessible Property <br /> Investment
      </h1>
      <p className="mb-8 mb-lg-14 text-lg text-dark font-semibold">
        Transform your dream into reality
      </p>
      <button
        onClick={() => navigate(ROUTES.PROP_OFFERING)}
        type="button"
        className="rounded-pill text-sm btn-xs font-semibold w-56 btn btn-dark p-4"
      >
        Begin Investing Now
      </button>
    </div>
  );
}

export default BeginInvestingComponent;
