import { Modal, Card, CardBody, Button } from 'react-bootstrap';
// import { updateUserResponseSQService } from "../../../../../../services/suitabilityQuestionnaire/userRSQ/updateUserResponseSQService";
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../../routes/routes';
// import { useSelector } from "react-redux";
// import { RootState } from "../../../../../../redux/store/type";
// import { updateUserService } from "../../../../../../services/user/updateUserService";

export const ConfirmationModal = ({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: any;
}) => {
  const navigate = useNavigate();
  // const userResponseData = useSelector((state: RootState) => state.userResponseSQ.responses);
  const acceptTermAndCondition = async () => {
    try {
      // const userResponse = await updateUserService({ isAcceptedTermAndCondition: true });
      // const sqResponse = await updateUserResponseSQService(userResponseData);

      navigate(ROUTES.PROP_OFFERING);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <h3 className="mb-6">Disclaimer & Acknowledgement</h3>
        <Card className="mb-8">
          <CardBody className="p-3">
            <h5 className="text-base mb-6">1. Informational Purposes Only:</h5>
            <ul className="mb-6">
              <li>
                The content provided on propXchange website, webinars, blog,
                emails, and accompanying material is intended for informational
                purposes only. It does not constitute or form any part of any
                offer, invitation, solicitation, or recommendation to purchase
                any securities.
              </li>
            </ul>

            <h5 className="text-base mb-6">
              2. Not Financial or Professional Advice:
            </h5>
            <ul className="mb-6">
              <li>
                The information should not be considered financial or
                professional advice. It is recommended to consult with a
                professional to determine what may be best for your individual
                needs.
              </li>
            </ul>

            <h5 className="text-base mb-6">3. Forward-Looking Statements:</h5>
            <ul className="mb-0">
              <li>
                Certain information contains forward-looking statements within
                the meaning of applicable securities legislation.
              </li>
              <li>
                These statements are identified by terms such as "intention",
                "will", "may", "can", and similar expressions.
              </li>
              <li>
                Although propXchange believes the expectations reflected in such
                forward-looking statements are reasonable, undue reliance should
                not be placed on them.
              </li>
              <li>
                Forward-looking statements involve risks, uncertainties, and
                other factors that may cause actual results to differ materially
                from those anticipated.
              </li>
              <li>
                The forward-looking statements are made as of the date of this
                release, and propXchange does not undertake any obligations to
                publicly update or revise them, except as required by law.
              </li>
            </ul>
          </CardBody>
        </Card>
        <div className="text-end">
          <Button
            variant="primary"
            className="rounded-pill btn-md px-16 py-4 text-sm"
            onClick={() => {
              acceptTermAndCondition();
            }}
          >
            I Acknowledge
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
