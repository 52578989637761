import { Footer, Header } from '../../component';

const TermsOfUse = () => {
  return (
    <div>
      <Header />
      <div className="px-5 py-5 px-md-32">
        <div className="bg-light-orange rounded-5 mt-1 mb-18">
          <div>
            <div className="text-center px-16 py-16 py-sm-18 py-md-30">
              <h1 className="display-3 mb-3 font-bold">
                <span className="font-light">Terms of </span>
                Use
              </h1>
              <p className="mb-8 mb-lg-14 text-md text-dark">
                Your Guide to Using PropXchange Safely and Responsibly
              </p>
              <p className="">
                <em>Last Updated 23rd May, 2024</em>
              </p>
            </div>
          </div>
        </div>
        <div className="pb-48 text-dark">
          <h6>Welcome to PropXchange!</h6>
          <br />
          <p>
            <small>
              PropXchange Technology Inc. (together with all companies owned by
              and in common ownership with PropXchange Technology Inc.,
              “PropXchange,” “we,” “us,” “our”) provides its services
              (“Services”) to you through its website located at
              https://propxchange.ca (the “Website”). The term “Website” shall
              include any modifications, enhancements, integrations, or related
              applications. The term “Services” includes all content and
              associated services published or otherwise made available to you
              via the Website or otherwise under these Terms. The Services and
              the Website are collectively referred to as the “Site.”
            </small>
          </p>
          <br />
          <h5>Ownership</h5>
          <br />
          <ol>
            <small>
              <li>
                <span className="font-bold">Rights in the Site: </span>{' '}
                PropXchange owns all rights, title, and interest in the Site,
                including all intellectual property rights. No other rights are
                granted to you other than the limited right to access and use
                the Site.
              </li>
              <br />
              <li>
                <span className="font-bold">Rights in User Data: </span> You own
                all rights, title, and interest in the content, information,
                materials, and data collected or shared by you using the Site.
                You are responsible for the accuracy and legality of your User
                Data.
              </li>
            </small>
          </ol>

          <br />
          <h5>Third Party Services</h5>
          <br />
          <p>
            <small>
              PropXchange or third parties may make available to you access to
              third party services. PropXchange does not own or control these
              third-party services, and their use is at your own risk and
              subject to additional terms and conditions.
            </small>
          </p>

          <br />
          <h5>Privacy Statement</h5>
          <br />
          <p>
            <small>
              Any personal information PropXchange collects via the Site is
              subject to our Privacy Statement found at www.propxchange.ca. By
              using our mobile applications or interacting with the Site, you
              agree that information about you and your use of the Site may be
              communicated to us.
            </small>
          </p>

          <br />
          <h5>Usage Data/Statistical Data</h5>
          <br />
          <p>
            <small>
              PropXchange may collect, store, analyze, and interpret data
              associated with your use of the Site. We may use this data for any
              purpose, provided it is anonymized and aggregated.
            </small>
          </p>

          <br />
          <h5>Disclaimer</h5>
          <br />
          <p>
            <small>
              Your use of the Site is at your sole risk. The Site is provided
              "as is" and "as available." PropXchange makes no representations,
              warranties, or conditions of any kind, whether express or implied.
            </small>
          </p>

          <br />
          <h5>Limitation of Liability</h5>
          <br />
          <p>
            <small>
              PropXchange and its representatives will not be liable for any
              damages arising from your use of the Site. Our maximum aggregate
              liability shall not exceed one hundred Canadian dollars (CAD$100).
            </small>
          </p>

          <br />
          <h5>Indemnity</h5>
          <br />
          <p>
            <small>
              You agree to defend, indemnify, and hold PropXchange and its
              representatives harmless from any claims arising out of your use
              of the Site.
            </small>
          </p>

          <br />
          <h5>Jurisdiction</h5>
          <br />
          <p>
            <small>
              These Terms are governed by the laws of the Province of British
              Columbia and the federal laws of Canada applicable therein. Any
              disputes must be resolved before the Courts of the Province of
              British Columbia, Canada, sitting in the City of Vancouver.
            </small>
          </p>

          <br />
          <h5>Termination of Use</h5>
          <br />
          <ol>
            <small>
              <li>
                <span className="font-bold">Breach of Terms: </span>
                If you breach these Terms, you may no longer use the Site. We
                may terminate or suspend your account at our discretion.
              </li>
              <br />
              <li>
                <span className="font-bold">Account Termination: </span> Upon
                termination or suspension of your account, your right to use the
                Site ceases immediately. We may retain information associated
                with your account for the period specified by applicable law.
              </li>
            </small>
          </ol>

          <br />
          <h5>General</h5>
          <br />
          <p>
            <small>
              These Terms constitute the entire agreement between you and
              PropXchange regarding the use of the Site. Any waiver or
              modification of these Terms must be in writing and signed by
              PropXchange. These Terms will be binding upon and inure to the
              benefit of you and PropXchange and its successors and assigns.
            </small>
          </p>

          <br />
          <h5>Notices</h5>
          <br />
          <p>
            <small>
              All notices to us shall be in writing and made via email to
              info@propxchange.ca. Notices to you may be sent to the address or
              email associated with your account.
            </small>
          </p>

          <br />
          <h5>Interpretation</h5>
          <br />
          <p>
            <small>
              In these Terms, words importing the singular number only shall
              include the plural and vice versa, and words importing the
              masculine gender shall include the feminine. The headings are for
              convenience only and will not affect the interpretation of these
              Terms. By using the Site, you acknowledge that you have read,
              understood, and agree to be bound by these Terms of Use.
            </small>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
