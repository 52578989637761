import { Footer, Header } from '../../component';

const CookiesPolicy = () => {
  return (
    <div>
      <Header />
      <div className="px-5 py-5 px-md-32">
        <div className="bg-light-orange rounded-5 mt-1 mb-18">
          <div>
            <div className="text-center px-16 py-16 py-sm-18 py-md-30">
              <h1 className="display-3 mb-3 font-bold">
                <span className="font-light">Cookies </span>
                Policy
              </h1>
              <p className="mb-8 mb-lg-14 text-md text-dark">
                Your Guide to Using PropXchange Safely and Responsibly
              </p>
              <p className="">
                <em>Last Updated 23rd May, 2024</em>
              </p>
            </div>
          </div>
        </div>
        <div className="pb-48 text-dark">
          <h6>Introduction</h6>
          <br />
          <p>
            <small>
              When you use our websites, mobile applications, and online
              services (the “Site”), we may store cookies on your web browser.
              Other third parties you choose to visit may do the same. This
              Cookie Policy explains what a cookie is, how you can manage your
              cookies, and what kind of cookies may be on our sites.
            </small>
          </p>
          <br />
          <h5>1. What is a Cookie and Why Do We Use Them?</h5>
          <br />
          <p>
            <small>
              Cookies are small pieces of text provided by most websites and
              stored by your web browser on the device you are using. They serve
              many purposes, such as remembering your preferences, learning
              which areas of the website are useful and which need improvement,
              and providing targeted advertisements or personalized content.
              Sometimes, cookies are enabled when pixels are placed on a
              website, which are also known as web beacons, clear gifs, and
              tags.
            </small>
          </p>

          <br />
          <h6>Types of Cookies:</h6>
          <br />
          <ul>
            <small>
              <li>
                <span className="font-bold">First-party Cookies: </span>
                These are cookies you receive directly from PropXchange when
                visiting our Site.
              </li>
              <br />
              <li>
                <span className="font-bold">Third-party Cookies: </span> These
                are cookies you receive from another party, such as Google or
                Facebook. We may work with certain third-party providers to
                permit their cookies to function through our Site.
              </li>
              <br />
              <li>
                <span className="font-bold">Persistent Cookies: </span>
                These cookies are stored by the web browser on your device until
                they expire or you delete them. Persistent cookies are also
                called “tracking cookies.”
              </li>
              <br />
              <li>
                <span className="font-bold">Session Cookies: </span> These
                cookies are created temporarily on your device for use by a
                website during your visit and are deleted after you leave the
                website or close the web browser.
              </li>
            </small>
          </ul>

          <br />
          <h5>2. How Do I Manage Cookies?</h5>
          <br />
          <p>
            <small>
              You can opt-out of cookies by enabling an opt-out preference
              signal or Global Privacy Control (GPC) on your browser, which is
              recognized by our Canada-facing websites, or by opting out of
              cookies in our Canada-facing websites’ cookie preference center.
              Managing Cookies in Different Browsers:
            </small>
          </p>
          <br />
          <ul>
            <small>
              <li>Internet Explorer</li>
              <li>Firefox</li>
              <li>Chrome</li>
              <li>Safari</li>
            </small>
          </ul>
          <br />
          <p>
            You can also use online tools like <u>www.allaboutcookies.org</u> to
            clear all cookies left behind by the websites you have visited.
            Deleting cookies will anonymize the information associated with the
            pixel, and a website will not receive any further associated
            information.
          </p>
          <br />
          <h5>3. What Kind of Cookies Do We Use?</h5>
          <br />
          <p>
            <small>
              The Site may use different types of cookies, including Strictly
              Necessary Cookies, Performance Cookies, Functional Cookies,
              Targeting Cookies, and Social Media Cookies. These may be
              first-party or third-party, persistent or session cookies.
            </small>
          </p>
          <br />
          <h6>Types of Cookies:</h6>
          <br />
          <ul>
            <small>
              <li>
                <span className="font-bold">Strictly Necessary Cookies: </span>
                These are essential for using the Site. They adjust the Site
                data transmitted to match your Internet connection, get you to
                the secure versions of the Site, and help provide services you
                specifically request. They do not store any personal
                information.
              </li>
              <br />
              <li>
                <span className="font-bold">Performance Cookies: </span> These
                cookies count visits and traffic sources to measure and improve
                Site performance. They do not store any personal information.
              </li>
              <br />
              <li>
                <span className="font-bold">Functional Cookies: </span>
                These cookies remember choices you make and provide enhanced
                functionality and more personalized features. They may store
                certain types of personal information as needed to provide
                functionality.
              </li>
              <br />
              <li>
                <span className="font-bold">Targeting Cookies: </span> These
                cookies help manage and display our advertisements based on your
                activity on the Site and other sites, known as interest-based
                advertising. They rely on uniquely identifying your browser and
                internet device. Visit the Network Advertising Initiative (NAI)
                website at
                http://www.networkadvertising.org/managing/opt_out.asp to
                opt-out of receiving tailored online ads.
              </li>
              <br />
              <li>
                <span className="font-bold">Social Media Cookies: </span> These
                cookies make social sharing easier, provide you with tools to
                connect with the Site, and help us better understand both the
                audience for the Site and the effectiveness of our social media
                outreach. Your choices with respect to such cookies are
                determined by the social media platforms on which you have
                accounts.
              </li>
            </small>
          </ul>

          <br />
          <h5>4. Updates to This Cookie Policy</h5>
          <br />
          <p>
            <small>
              We may change this Cookie Policy at any time. Please review the
              “Last Updated” legend at the top of this page to see the last
              revision date to this Cookie Policy. Any changes will become
              effective when the revised Cookie Policy is available on or
              through the Site.
            </small>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CookiesPolicy;
