import { Button, Card, Container, Row, Col, Spinner } from 'react-bootstrap';
import { HeaderDark } from '../../component';
import BottomNavbar from '../../component/BottomNavbar/BottomNavbar';
import { TransactionHistoryComponent } from './TransactionHistoryComponent';
import useGetUserWalletBalance from '../../hooks/useGetUserWalletBalance';
import { formatAmount } from '../util';
import { useState } from 'react';
import TopUpWalletModal from '../../component/TopUpWalletModal/TopUpWalletModal';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../redux/store/type';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTab } from '../../redux/slices/sideMenu/sideMenuSlice';

export const Wallet = () => {
  const dispatch = useDispatch();
  dispatch(setSelectedTab({ selectedTab: 'Wallet' }));

  const { isSetUp, balance } = useSelector((state: RootState) => state.wallet);
  const { isLoading } = useGetUserWalletBalance();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const showTopUpWallet = searchParams.get('showTopUpModal') === 'true';
  const [showTopUpWalletModel, setShowTopUpWallet] = useState(showTopUpWallet);

  return (
    <div className="pb-14">
      <HeaderDark>
        <h5 className="font-bolder mx-4">Wallet</h5>
      </HeaderDark>
      {/* [TODO]: Refactor wallet balance logic to clean this */}
      {isSetUp ? (
        <Container className="p-5">
          <Row>
            <Col md={8}>
              <TransactionHistoryComponent />
            </Col>
            <Col md={4}>
              <Card className="p-5" style={{ maxWidth: '250px' }}>
                <p className="text-dark font-bold text-sm">Current Balance</p>
                <p className="text-dark font-bold text-2xl py-4">
                  {formatAmount(balance)}
                </p>

                <Button
                  onClick={() => setShowTopUpWallet(true)}
                  variant="primary"
                  className="rounded-pill btn btn-primary text-sm py-2 px-4 w-sm-auto w-full"
                >
                  + Add Balance
                </Button>
                <TopUpWalletModal
                  show={showTopUpWalletModel}
                  handleClose={() => setShowTopUpWallet(false)}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      ) : isLoading ? (
        <div className="w-full d-flex h-screen justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <>not set up</>
      )}
      <BottomNavbar />
    </div>
  );
};
