export const getInitials = (firstName, lastName) => {
  if (!firstName && !lastName) return '';
  const initials = `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`;
  return initials.toUpperCase();
};

export function convertToFormData<T extends { [key: string]: any }>(
  userData: T,
): FormData {
  const formData = new FormData();

  Object.keys(userData).forEach((key) => {
    const value = userData[key];

    if (Array.isArray(value)) {
      // Handle array of objects like outsideLinks
      value.forEach((item: any, index: number) => {
        if (
          typeof item === 'object' &&
          !(item instanceof File) &&
          !(item instanceof Blob)
        ) {
          Object.keys(item).forEach((subKey) => {
            formData.append(`${key}[${index}][${subKey}]`, item[subKey]);
          });
        } else {
          formData.append(`${key}[${index}]`, item);
        }
      });
    } else if (value instanceof File || value instanceof Blob) {
      // Handle File or Blob objects
      formData.append(key, value);
    } else if (typeof value === 'object' && value !== null) {
      // Handle nested objects
      Object.keys(value).forEach((subKey) => {
        formData.append(`${key}[${subKey}]`, value[subKey]);
      });
    } else {
      // Handle other types (string, number, etc.)
      formData.append(key, String(value));
    }
  });

  return formData;
}

export function verifyFormData(formData: FormData) {
  for (const [key, value] of formData?.entries()) {
    console.log(`Key: ${key}, Value: ${value}`);
  }
}

export function convertNumber(val: number): string {
  let s = ['', 'k', 'm', 'b', 't'];

  let sNum = ('' + val).length / 3;
  if (sNum - Math.floor(sNum) == 0) {
    sNum = Math.floor(sNum) - 1;
  } else {
    sNum = Math.floor(sNum);
  }

  let sVal = parseFloat(
    (sNum != 0 ? val / Math.pow(1000, sNum) : val).toPrecision(2),
  );

  let pVal = sVal.toString();
  if (sVal % 1 != 0) {
    pVal = sVal.toFixed(1);
  }

  return pVal + s[sNum];
}
