import { Spinner } from 'react-bootstrap';
import useGetUserWalletBalance from '../../../hooks/useGetUserWalletBalance';
import { formatAmount } from '../../util';

export const WalletBalanceComponent = () => {
  const { isWalletSetUp, userWalletBalance, isLoading } =
    useGetUserWalletBalance();

  if (isLoading) {
    return <Spinner />;
  }

  if (isWalletSetUp === false) {
    return <></>;
  }

  return (
    <>
      <div className="text-muted text-sm">Wallet Balance</div>
      <h3 className="font-bolder">{formatAmount(userWalletBalance)}</h3>
    </>
  );
};
