import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';

interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[];
  multiSelect?: boolean;
  selectedValue?: string;
  selectedValues?: string[];
  onChange: (value: string | string[]) => void;
}

const SelectDropdown: React.FC<Props> = ({
  options,
  multiSelect = false,
  selectedValue,
  selectedValues,
  onChange,
}) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (multiSelect) {
      const selectedOptions = Array.from(
        event.target.selectedOptions,
        (option) => option.value,
      );
      onChange(selectedOptions);
    } else {
      onChange(event.target.value);
    }
  };

  return (
    <Form.Select
      style={{ boxShadow: 'none', border: '1px solid #dadada' }}
      multiple={multiSelect}
      onChange={handleChange}
      value={multiSelect ? selectedValues : selectedValue}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Select>
  );
};

export default SelectDropdown;
