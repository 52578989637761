/**
 * Converts a number into a human-readable string with commas as thousand separators.
 * @param value - The number to be formatted.
 * @returns The formatted number as a string.
 */
export function formatNumber(value: string | number | undefined) {
  if (value === undefined) {
    return -1;
  }

  const num = typeof value === 'string' ? parseFloat(value) : value;

  if (isNaN(num)) {
    return -1;
  }

  return num.toLocaleString();
}

export function formatAmount(value: string | number | undefined) {
  if (value === undefined) {
    return -1;
  }

  const num = typeof value === 'string' ? parseFloat(value) : value;

  if (isNaN(num)) {
    return -1;
  }

  return `CAD $${num.toLocaleString()}`;
}

export function formatMobileNumber(input: string): string {
  // Remove any non-digit characters
  let cleanInput = input.replace(/\D/g, '');

  // If the length is not 10, return the original input or handle error
  if (cleanInput.length !== 10) {
    return input;
  }

  // Format the cleaned input into (XXX) XXX-XXXX
  let formattedNumber = `(${cleanInput.substring(0, 3)}) ${cleanInput.substring(
    3,
    6,
  )}-${cleanInput.substring(6)}`;

  return formattedNumber;
}

export function formatIRR(value) {
  return `${value}%`;
}
