export const setLanguageCode = (langCode: string) => {
  localStorage.setItem('i18nextLng', langCode);
};
export const getLanguageCode = () => {
  const languageCode =
    localStorage.getItem('i18nextLng') !== null &&
    (localStorage.getItem('i18nextLng')?.startsWith('en') ||
      localStorage.getItem('i18nextLng') === 'en-US' ||
      localStorage.getItem('i18nextLng') === 'dev')
      ? 'en'
      : localStorage.getItem('i18nextLng');
  return languageCode;
};
