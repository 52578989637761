import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import '../PropertyInformation.scss';

interface AnnualReturnObject {
  Year: string;
  Return: string;
  Investment: string;
}

export const calculateFinalAmountAfterYear = (
  initialAmount: number,
  annualRate: number,
  years: number,
) => {
  return (initialAmount * Math.pow(1 + annualRate / 100, years)).toFixed(2);
};

const calculateInvestmentGrowth = (
  initialAmount,
  annualRate,
  years,
): AnnualReturnObject[] => {
  const currentYear = new Date().getFullYear();
  let data: AnnualReturnObject[] = [];
  let totalReturn: string = '0';

  for (let i = 0; i <= years; i++) {
    let year = currentYear + i;
    totalReturn = calculateFinalAmountAfterYear(initialAmount, annualRate, i);

    data.push({
      Year: year.toString(),
      Return: totalReturn,
      Investment: initialAmount,
    });
  }

  return data;
};

const formatYAxisTick = (value: number) => {
  return `$${value.toLocaleString()}`;
};

export const GrowthChart = ({
  initialAmount = '2500',
  annualRate = '15',
  year = '10',
}) => {
  const data = calculateInvestmentGrowth(initialAmount, annualRate, year);

  return (
    <div className="graph-container">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 40,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid
            stroke="#aaa" // Color for horizontal lines
            strokeDasharray="3 3" // Style for horizontal lines
            vertical={false} // Hide vertical lines
          />
          <XAxis
            dataKey="Year"
            label={{ position: 'insideBottom', offset: -5 }}
          />
          <YAxis
            tickFormatter={formatYAxisTick}
            label={{
              angle: -90,
              position: 'insideLeft',
              offset: -5,
            }}
          />
          <Tooltip />
          <Bar dataKey="Investment" stackId="a" fill="#812AD8" barSize={20} />
          <Bar dataKey="Return" stackId="a" fill="#000000" barSize={20} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
