import { Footer, Header } from '../../component';
import {
  Accordion,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { SearchImg } from '../../assets/image';
import { faq } from './questions';
import { useState } from 'react';

const Faqs = () => {
  const [searchInput, setSearchInput] = useState('');
  const [filteredFaqs, setFilteredFaqs] = useState(faq);
  const handleSearch = (event) => {
    const input = event.target.value;
    setSearchInput(input);
    if (input.trim() === '') {
      setFilteredFaqs(faq);
    } else {
      const filtered = faq.filter(
        (item) =>
          item.question.toLowerCase().includes(input.toLowerCase()) ||
          item.answer.toLowerCase().includes(input.toLowerCase()),
      );
      setFilteredFaqs(filtered);
    }
  };
  return (
    <div>
      <Header />
      <div className="px-5 py-5 px-md-18">
        {/*<Container>*/}
        <Card className="bg-lavender-light rounded-5">
          <CardBody className="py-14 px-xl-24 px-lg-14">
            <Row className="align-items-end gy-6 mb-lg-32 mb-md-16 mb-6">
              <Col lg={6} className="px-5">
                <h1 className="display-3 mb-5 font-bold">
                  <span className="p-0 m-0 font-light">Got Questions?</span>
                  <br /> We Have Answers!
                </h1>
              </Col>
              <Col lg={6} className="">
                <div
                  style={{ width: '100%' }}
                  className="w-md-88 position-relative ms-lg-auto"
                >
                  <input
                    type="text"
                    value={searchInput}
                    onChange={handleSearch}
                    style={{ width: '100%' }}
                    placeholder="Search for the help"
                    className="p-3 w-md-88 border border-2 "
                  />
                  <img
                    src={SearchImg}
                    alt="Search icon"
                    className="position-absolute bottom-4 end-5"
                  />
                </div>
              </Col>
            </Row>
            <Row className="gy-6">
              <Col lg={3} className="d-lg-block d-none">
                <Card className="border-bottom-card border border-2 border-dark">
                  <CardBody>
                    <h3 className="font-bolder">About propXchange</h3>
                    {/* <p className="text-md">Our services, what sets us apart, and how to reach us.</p> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={9}>
                <Card className="border border-2 border-dark">
                  <CardBody>
                    {/* <p className="mb-6 text-md font-semibold">
                          <span className="bg-gray-100 px-3 py-1">GENERAL</span>
                        </p> */}
                    <Accordion
                      defaultActiveKey="0"
                      className="accordion-point mb-12"
                    >
                      {filteredFaqs.map((item, index) => (
                        <Accordion.Item eventKey={item.question}>
                          <Accordion.Header>{item.question}</Accordion.Header>
                          <Accordion.Body>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.answer,
                              }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                    {/* <p className="mb-8 text-md font-semibold">
                          <span className="bg-gray-100 px-3 py-1">FEES</span>
                        </p> */}
                    {/* <Accordion defaultActiveKey="0" className="accordion-point">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>What are propXchange’s fees?</Accordion.Header>
                            <Accordion.Body>
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                              ever since the 1500s
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* </div> */}
          </CardBody>
        </Card>
        {/*</Container>*/}
      </div>
      <Footer />
    </div>
  );
};

export default Faqs;
