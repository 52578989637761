import { useEffect, useState } from 'react';
import { VOPAY_GET_USER_BALANCE } from '../services/apiEndPoints';
import { axios } from '../axios/AxiosConfig';
import { handleError } from '../utilities/handleError';
import { getCurrentBalance } from '../services/wallet/walletService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store/type';
import { setBalance, setIsSetUp } from '../redux/slices/wallet/walletSlice';

// Check isWalletSetup is true then use userWalletBalance
// Define a custom hook named useCounter
function useGetUserWalletBalance() {
  const dispatch = useDispatch();
  const [isWalletSetUp, setIsWalletSetUp] = useState('');
  const [userWalletBalance, setUserWalletBalance] = useState(-1);
  const { isSetUp, balance } = useSelector((state: RootState) => state.wallet);

  const wallet = useSelector((state: RootState) => state.wallet);
  useEffect(() => {
    getCurrentBalance()
      .then((res: any) => {
        setUserWalletBalance(res);
        setIsWalletSetUp('true');
        dispatch(setBalance({ balance: Number(res) }));
      })
      .catch((e) => {
        setIsWalletSetUp('false');
        dispatch(setIsSetUp({ isSetUp: false }));
      });
    const funToFetch = async () => {
      try {
        const getBalanceResponse = await axios.get(VOPAY_GET_USER_BALANCE);
        const balances = getBalanceResponse.data?.data?.data?.balances;
        const balanceBalanceInCAD = balances['0']?.Balance;

        if (!balanceBalanceInCAD) {
          handleError({
            message: 'Unable to fetch Wallet Balance.',
          });
          return;
        }
        setUserWalletBalance(balanceBalanceInCAD);
        setIsWalletSetUp('true');
      } catch (err) {
        setIsWalletSetUp('false');
      }
    };
    // funToFetch();
  }, []);

  return {
    userWalletBalance: balance,
    isWalletSetUp: isSetUp === true,
    isLoading: balance === null,
  };
}

export default useGetUserWalletBalance;
