import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { IoArrowForward } from 'react-icons/io5';
import { subscribeToMailingList } from '../../services/zoho/subscribeToMailingList';
import { toast } from 'react-toastify'; // Make sure to import axios or any other HTTP client library you are using

const YourComponent = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false); // Optional: for managing loading state

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    setLoading(true); // Optional: Set loading state to true

    try {
      await subscribeToMailingList(email);
      toast.success('Successfully subscribed!'); // Show success message
      setEmail(''); // Clear the email input
    } catch (error) {
      toast.error('Failed to subscribe. Please try again.'); // Show error message
    } finally {
      setLoading(false); // Optional: Set loading state to false
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="gy-6">
        <Col md={8}>
          <Form.Control
            type="email"
            placeholder="name@example.com"
            className="h-12"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Col>
        <Col md={4} className="text-md-start text-center">
          <Button
            variant="dark"
            className="h-12 rounded-pill w-md-full"
            type="submit" // Ensure the button is of type "submit"
          >
            Subscribe
            <IoArrowForward className="ms-2" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default YourComponent;
