import { Stack } from 'react-bootstrap';
import { RadioButton } from '../Button/RadioButton';

interface Option {
  label: string;
  value: string;
}

interface RadioButtonGroupProps {
  options: Option[];
  selectedOption: string;
  onChange: (value: string) => void;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  options,
  selectedOption,
  onChange,
}) => (
  <Stack direction="horizontal" gap={2} className="flex-wrap mb-3">
    {options.map((option, index) => (
      <RadioButton
        key={index}
        label={option.label}
        value={option.value}
        onClick={() => onChange(option.value)}
        active={selectedOption === option.value}
      />
    ))}
  </Stack>
);

export default RadioButtonGroup;
