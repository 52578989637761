import { Card } from 'react-bootstrap';
import { AiFillLinkedin } from 'react-icons/ai';

export function TeamMemberCard(props) {
  return (
    <Card
      className="m-2 border-dark border-bottom-2 profile-card"
      style={{ borderBottom: '4px solid black' }}
    >
      <Card.Img
        variant="top"
        src={props.image}
        className="profile-card-image"
      />
      <Card.Body className="d-flex justify-content-between">
        <div>
          <Card.Text className="text-dark text-bold">{props.name}</Card.Text>
          <Card.Text className="text-sm text-dark">{props.role}</Card.Text>
        </div>
        <a href={props.linkedin} target="_blank">
          <AiFillLinkedin className="text-xl" color="#0077b5" />
        </a>
      </Card.Body>
    </Card>
  );
}
