import { Button, Spinner } from 'react-bootstrap';
import useGetUserWalletBalance from '../../../hooks/useGetUserWalletBalance';
import useSetUpUserWallet from '../../../hooks/useSetUpUserWallet';
import React, { useState } from 'react';
import { handleError } from '../../../utilities/handleError';
import useInitiateIntercRequestForTopUp from '../../../hooks/useInitiateInteracRequestForTopUp';
import TopUpWalletModal from '../../../component/TopUpWalletModal/TopUpWalletModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/type';

export const AddBalanceOrSetupWallet = () => {
  const { isSetUp } = useSelector((state: RootState) => state.wallet);
  const [isWalletSetUpInProgress, setIsWalletSetUpInProgress] = useState(false);
  const [showTopUpWalletModel, setShowTopUpWallet] = useState(false);

  const { setUpUserWallet } = useSetUpUserWallet();

  const handleSetUpUserWallet = async () => {
    setIsWalletSetUpInProgress(true);
    try {
      await setUpUserWallet();

      // Wallet set was successful.
      setIsWalletSetUpInProgress(false);

      // TODO: Update slice so that useGetUserWalletBalance is retriggered
      window.location.reload();
    } catch (err) {
      setIsWalletSetUpInProgress(false);

      handleError({
        message: `Wallet set up failed. Please try again after sometime.`,
      });
    }
  };

  if (isWalletSetUpInProgress || isSetUp === null) {
    return <div className="w-full d-flex justify-content-start">
      <Spinner />
    </div>;
  }

  if (!isSetUp) {
    return <Button onClick={handleSetUpUserWallet}>SetUp Wallet</Button>;
  }

  return (
    <>
      <Button
        variant="link"
        onClick={() => setShowTopUpWallet(true)}
        className="p-0 text-dark text-underline text-sm"
      >
        + Add Balance
      </Button>
      <TopUpWalletModal
        show={showTopUpWalletModel}
        handleClose={() => setShowTopUpWallet(false)}
      />
    </>
  );
};
