import { Container, Button, Form } from 'react-bootstrap';
import { Header } from '../../component';
import { useNavigate, useParams } from 'react-router-dom';
import { verifyEmailService } from '../../services/auth';
import { toast } from 'react-toastify';

export default function VerifyEmail() {
  const navigate = useNavigate();
  const { token, userId } = useParams();

  const verify = () => {
    if (!token || !userId) {
      toast.error('Invalid URL or  token');
      return;
    }
    verifyEmailService({ token, userId })
      .then((result) => {
        if (result) {
          setTimeout(() => {
            toast.success('Email Verified Successfully');
          }, 2000); // 2000 milliseconds = 2 seconds
          navigate('/login');
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <div>
      <Header />
      <div className="h-lg-calc vstack login-h-full login-background background-no-repeat bg-center bg-cover">
        <Container className="my-auto">
          <div className="max-w-screen-400 mx-auto">
            <h2 className="text-center mb-1 font-bolder">Verify Email</h2>
            {/* <p className="text-center text-dark mb-12">Enter the 6 digit code from your email</p> */}
            <Form>
              {/* <div className="vstack gap-3 mb-10">
                <Form.Group controlId="floatingInput" className="form-floating mb-3">
                  <Form.Control
                    type="text"
                    placeholder="666666"
                    value={verificationCode}
                    onChange={(event) => {
                      setVerificationCode(event.target.value);
                      if (!event?.target?.value || event?.target?.value === "") setVerificationCodeError((prev) => true);
                      else setVerificationCodeError((prev) => false);
                    }}
                  />
                  <Form.Label>Verification Code</Form.Label>
                  {verificationCodeError && (
                    <p className="text-danger mt-1">
                  
                      Verification code required
                    </p>
                  )}
                </Form.Group>
              </div> */}
              <div className="text-center">
                <Button
                  onClick={() => verify()}
                  variant="primary"
                  className="rounded-pill btn-lg w-full py-4 mb-10 font-semibold text-sm"
                >
                  VERIFY
                </Button>
                <div className="position-relative  font-semibold  hstack justify-content-center mb-6">
                  {/* <span className="px-2 bg-light position-relative overlap-20">Or continue with</span> */}
                  <div className="border-bottom border-2 w-full position-absolute"></div>
                </div>
              </div>
            </Form>
          </div>
          <div className="max-w-screen-md mx-auto"></div>
        </Container>
      </div>
    </div>
  );
}
