import { ToastContainer } from 'react-toastify';
import './App.css';
import { AllRoutes } from './routes';
import 'react-toastify/dist/ReactToastify.css';
import { Chatbot } from './pages/Chatbot/Chatbot';
import LaunchSoon from './component/LaunchSoon/LaunchSoon';
import { REACT_APP_STAGE } from './utilities/envConstants';

function App() {
  // if (REACT_APP_STAGE === 'prod') {
  //   return (
  //     <>
  //       <LaunchSoon />
  //       <ToastContainer
  //         style={{ zIndex: 10000 }}
  //         position="top-right"
  //         autoClose={3000}
  //         hideProgressBar={false}
  //         newestOnTop={true}
  //         closeOnClick
  //         rtl={false}
  //         pauseOnFocusLoss
  //         pauseOnHover
  //         theme={'colored'}
  //       />
  //     </>
  //   );
  // }

  return (
    <>
      <AllRoutes />
      <ToastContainer
        style={{ zIndex: 10000 }}
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme={'colored'}
      />
      <Chatbot />
    </>
  );
}

export default App;
