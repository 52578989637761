import { useEffect, useState } from 'react';
// @ts-ignore: Suppress TypeScript error for default import
import { Veriff } from '@veriff/js-sdk';
import { createVeriffFrame } from '@veriff/incontext-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/type';
import CryptoJS from 'crypto-js';
import { updateUserService } from '../../../services/user/updateUserService';
import { MdVerified } from 'react-icons/md';
import { currentUserService } from '../../../services/user/currentUserService';
import { setUserReducer } from '../../../redux/slices/auth/authSlice';

const VERIFF_API_KEY = 'e42db41b-599d-4c57-9e69-939574741eb1';
const VERIFF_SHARED_SECRET_KEY = 'ea9aab83-fb45-432f-90e5-d9f2cbe24af6'; // This is Test API key - move this to
const VERIFF_BASE_URL = 'https://stationapi.veriff.com';
const VERIFF_ROOT_DIV_ID = 'veriff-root';

// Define KYCStatus enum
enum KYCStatus {
  APPROVED = 'approved',
  RESUBMISSION_REQUESTED = 'resubmission_requested',
  REVIEW = 'review', // Intentionally we are not handling REVIEW at this moment.
  DECLINED = 'declined',
  EXPIRED = 'expired',
  ABANDONED = 'abandoned',
}

// [TODO]
// 1. Handle case when user data is not available
// 2. When data is not fetched, it should show processing instead of Start verification button.
// 3. Instead of updating kycReferenceId, update API repo to keep track of all the efforts.
// 4. Move users messages to en.ts file.
// 5. Think about when propxchange wants to ask user to redo KYC. Introduce new field in database to
// with flag and information on re-kyc requested.

// To encrypt sessionId
function generateHmacSignature(secretKey, payload) {
  const hash = CryptoJS.HmacSHA256(payload, secretKey);
  return CryptoJS.enc.Hex.stringify(hash);
}

export const KYCComponent = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const [kycStatus, setKycStatus] = useState(user?.kycStatus); // Initialize state with user's kycStatus
  const [sessionId, setSessionId] = useState<string | undefined>(
    user?.kycReferenceId,
  );

  useEffect(() => {
    // If it's already approved or under review - we want should not allow
    // users to create new KYC request
    if (kycStatus === KYCStatus.APPROVED) return;

    const createVerificationSession = async () => {
      const veriffClient = Veriff({
        host: VERIFF_BASE_URL, // Host for the Veriff API
        parentId: VERIFF_ROOT_DIV_ID,
        apiKey: VERIFF_API_KEY,
        onSession: async function (err, response) {
          if (err) {
            console.error('Error creating session:', err);
            return;
          }

          // Update user database as soon as user attempts new KYC
          await updateUserService({
            kycReferenceId: response.verification.id,
            kycStatus: undefined,
          });

          setSessionId(response.verification.id); // Save session ID for later use
          setKycStatus(undefined); // Save session ID for later use

          const url = response.verification.url;
          createVeriffFrame({ url });
        },
      });

      veriffClient.setParams({
        person: {
          givenName: user?.firstName,
          lastName: user?.lastName,
        },
        vendorData: user?.email,
      });

      veriffClient.mount({
        submitBtnText: 'Start Verification',
      });
    };

    void createVerificationSession();
  }, [sessionId, kycStatus]);

  // We want to store latest status of kyc in our database as well, that we poll status
  // and update our database in interval.
  useEffect(() => {
    const pollVerificationStatus = async () => {
      if (!sessionId || user?.kycStatus === KYCStatus.APPROVED) return;

      try {
        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-HMAC-SIGNATURE': generateHmacSignature(
              VERIFF_SHARED_SECRET_KEY,
              sessionId,
            ),
            'X-AUTH-CLIENT': VERIFF_API_KEY,
          },
        };

        const response = await fetch(
          `${VERIFF_BASE_URL}/v1/sessions/${sessionId}/decision`,
          options,
        );
        const data = await response.json();

        // Set kyc Status
        if (kycStatus !== data.verification?.status) {
          setKycStatus(data.verification?.status);

          // To reduce the number of API calls we can use below.
          //  && user?.kycStatus !== data.verification?.status
          if (data.verification?.status) {
            await updateUserService({
              kycReferenceId: sessionId,
              kycStatus: data.verification?.status,
            });
            currentUserService().then((result: any) => {
              if (result) {
                dispatch(setUserReducer({ user: result }));
                window.location.reload();
              }
            });
          }
        }
      } catch (error) {
        console.error('Error polling verification status:', error);
      }
    };

    const interval = setInterval(pollVerificationStatus, 5000); // Poll every 5 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [sessionId, kycStatus]);

  if (kycStatus === KYCStatus.APPROVED) {
    return (
      <div style={{ color: '#578F56', display: 'flex', alignItems: 'center' }}>
        <MdVerified /> Verified
      </div>
    );
  }

  return (
    <>
      {kycStatus === KYCStatus.RESUBMISSION_REQUESTED && (
        <>Resubmission requested</>
      )}
      {kycStatus === KYCStatus.DECLINED && (
        <>Last Kyc was declined. Please retry.</>
      )}
      {kycStatus === KYCStatus.EXPIRED && (
        <>Last Kyc attempt is expired. Please try again.</>
      )}
      <div id={VERIFF_ROOT_DIV_ID}></div>
    </>
  );
};
